<!-- /components/TabLayout.vue -->
<template>
    <slot :active-tab="_activeTab" />
</template>

<script lang="ts" setup>
import { computed, PropType, provide, ref, toValue, watch } from 'vue'
import { Tab } from '@/shared/types/layout'
import {
    tabLayoutActiveTabInjection,
    tabLayoutTabsInjection,
} from '@/shared/types/injection'
import { useRoute, useRouter } from 'vue-router'

const emit = defineEmits(['update:activeTab'])

const props = defineProps({
    is: {
        type: String,
        default: 'div',
    },
    tabs: {
        type: Array as PropType<Tab[]>,
        required: true,
    },
    activeTab: {
        type: String,
        default: () => undefined,
    },
})

const route = useRoute()
const router = useRouter()

const _activeTab = ref(
    props.tabs?.filter((tab) => toValue(tab.enabled))[0]?.template
)
const comp = ref<HTMLDivElement | undefined>(undefined)

provide(
    tabLayoutTabsInjection,
    computed(() => props.tabs)
)
provide(tabLayoutActiveTabInjection, _activeTab)

watch(
    () => props.tabs,
    (newValue) => {
        const enabledTabs = newValue.filter((tab) => toValue(tab.enabled))
        // if the active tab is not in the new tabs, set the first tab as active
        if (!enabledTabs.find((tab) => tab.template === _activeTab.value)) {
            _activeTab.value = enabledTabs[0]?.template
        }
    },
    { deep: true }
)

watch(
    () => _activeTab.value,
    (newValue) => {
        if (newValue) {
            if (newValue !== props.activeTab) {
                emit('update:activeTab', newValue)
            }
        }
    },
    { immediate: true }
)

watch(
    () => props.activeTab,
    (newValue) => {
        if (newValue && newValue !== _activeTab.value)
            _activeTab.value = newValue
    }
)

watch(
    () => route.query.tab,
    (newTab) => {
        if (newTab && typeof newTab === 'string') {
            _activeTab.value = newTab
        }
    }
)
</script>

<style scoped></style>
