<template>
    <modal-popup
        @close="emit('close')"
        @primary="emit('close')"
        @secondary="
            () => {
                setShowDevelopmentInsights(false)
                emit('close')
            }
        "
    >
        <div class="grow">
            <development-insights
                :narrative-element-id="narrativeElementId"
                :project-id="projectId"
            />
        </div>
    </modal-popup>
</template>

<script lang="ts" setup>
import DevelopmentInsights from '@/shared/components/DevelopmentInsights.vue'
import ModalPopup from '@/shared/components/modals/ModalPopup.vue'
import { useInsightClient } from '@/shared/stores/developmentInsightsStore'
import { onMounted } from 'vue'

const emit = defineEmits(['close'])

const props = defineProps({
    projectId: {
        type: String,
        default: () => undefined,
    },
    narrativeElementId: {
        type: String,
        default: () => undefined,
    },
})

const insightClient = useInsightClient()

function setShowDevelopmentInsights(value: boolean) {
    insightClient.showDevelopmentInsightsMobile.value = false
}

onMounted(() => {
    console.log(props)
})
</script>

<style lang="css" scoped></style>
