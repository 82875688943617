import { ComputedRef, InjectionKey, Ref } from 'vue'
import { Tab } from '@/shared/types/layout'

export const tabLayoutTabsInjection = Symbol('tab-layout-tabs') as InjectionKey<
    ComputedRef<Tab[]>
>
export const tabLayoutActiveTabInjection = Symbol(
    'tab-layout-active-tab'
) as InjectionKey<Ref<string>>

export const editModeInjection = Symbol('edit-mode') as InjectionKey<
    Ref<{
        active: boolean
        dummy: boolean
    }>
>

export const developmentInsightsInjection = Symbol(
    'development-insights'
) as InjectionKey<{
    projectSlug: Ref<string | undefined>
    narrativeElementSlug: Ref<string | undefined>
    isProcessing: Ref<boolean>
}>
