import { createApp } from 'vue'
import App from './App.vue'
import router from '@/app/router'
import axios, { isAxiosError } from 'axios'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import '@/shared/assets/app.css'
import { createLogger } from 'vue-logger-plugin'
import modalPlugin from '@/shared/plugins/modalPlugin'
import { VueMountable } from 'vue-mountable'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { handleAuthError } from '@/shared/utils/authHelpers'
import 'vue3-toastify/dist/index.css'
import Vue3Toastify, { toast } from 'vue3-toastify'
import * as Sentry from '@sentry/vue'

// Enable CSRF token
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
const accessToken = localStorage.getItem('authToken') || ''
axios.defaults.headers.common['Authorization'] = accessToken
    ? `Bearer ${accessToken}`
    : undefined

const pinia = createPinia().use(piniaPluginPersistedState)

const logger = createLogger({
    enabled: true,
    level: import.meta.env.MODE === 'development' ? 'debug' : 'error',
})

const app = createApp(App)
    .use(router)
    .use(VueQueryPlugin)
    .use(pinia)
    .use(logger)
    .use(Vue3Toastify, {
        dangerouslyHTMLString: true,
    })
    .use(modalPlugin)
    .use(VueMountable())

// app.config.errorHandler = (err, vm, info) => {
//     console.error('Global error:', err);
//     console.error('Vue component where error occurred:', vm);
//     console.error('Vue info:', info);
//     // You could also log this to an error tracking service
// };

axios.interceptors.response.use(
    (response) => {
        logger.debug('Response:', {
            url: response.config.url,
            method: response.config.method,
            status: response.status,
            data: response.data,
            headers: response.headers,
        })
        return response
    },
    async (error) => {
        if (isAxiosError(error)) {
            const route = router.currentRoute.value // Use router's current route

            logger.warn('🚔 interceptor Request:', {
                url: error.config?.url,
                method: error.config?.method,
                headers: error.config?.headers,
                data: error.config?.data,
            })

            // Log error response details
            logger.error('Error Response:', {
                status: error.response?.status,
                data: error.response?.data,
                headers: error.response?.headers,
            })

            switch (error.response?.status) {
                case 419:
                    logger.error('CSRF token mismatch')
                    break
                case 401:
                    logger.error('Unauthorized')
                    localStorage.removeItem('user')
                    localStorage.removeItem('token')
                    delete axios.defaults.headers.common['Authorization']
                    break
                case 403:
                    router.push({ name: 'home' }).then(() => {
                        toast('This action is forbidden', { type: 'error' })
                    })
                    logger.error('Forbidden')
                    break
                case 404:
                    logger.error('Not found')
                    break
                case 500:
                    logger.error('Server error')
                    break
                default:
                    if (!error.response) {
                        logger.error('Network error')
                    } else {
                        logger.error('An error occurred')
                    }
            }

            if (
                error.response?.status === 419 ||
                error.response?.status === 401
            ) {
                if (!route.path.startsWith('/auth'))
                    await handleAuthError(router, route)
            }
        } else {
            // Log non-Axios errors
            logger.error('Non-Axios Error:', error)
        }
        return Promise.reject(error)
    }
)

app.mount('body')

// Sentry SDK
if (import.meta.env.MODE === 'production') {
    logger.debug('Initializing Sentry')
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })
}
