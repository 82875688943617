<template>
    <modal-backdrop disable-backdrop-close>
        <modal-layout>
            <modal-header>
                <h1
                    class="w-full select-none font-main text-lg font-medium text-parchment-950"
                >
                    Finish setting up your account
                </h1>
            </modal-header>
            <modal-content class="gap-6">
                <label
                    class="w-full text-xs font-semibold uppercase text-parchment-300"
                    for="first_name"
                >
                    First Name
                    <input
                        id="first_name"
                        v-model="data.first_name"
                        autocomplete="given-name"
                        class="block w-full border-b border-parchment-400 bg-white py-3 text-base font-normal text-parchment-950 placeholder:text-parchment-400 focus:outline-none"
                        name="first_name"
                        placeholder="Enter your first name"
                        required
                        type="text"
                    />
                </label>
                <label
                    class="w-full text-xs font-semibold uppercase text-parchment-300"
                    for="last_name"
                >
                    Last Name
                    <input
                        id="last_name"
                        v-model="data.last_name"
                        autocomplete="family-name"
                        class="block w-full border-b border-parchment-400 bg-white py-3 text-base font-normal text-parchment-950 placeholder:text-parchment-400 focus:outline-none"
                        name="last_name"
                        placeholder="Enter your last name"
                        type="text"
                    />
                </label>
                <label
                    class="flex items-center"
                    for="receive_emails"
                >
                    <input
                        id="receive_emails"
                        v-model="data.receive_emails"
                        class="mr-2"
                        name="receive_emails"
                        type="checkbox"
                    />
                    <span class="text-xs text-parchment-400">
                        Receive emails about new features and updates
                    </span>
                </label>
            </modal-content>
            <modal-footer class="justify-end">
                <v-button @click="save">Save</v-button>
            </modal-footer>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import VButton from '@/shared/components/VButton.vue'
import { updateName } from '@/shared/utils/endpoints'
import { useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import ModalFooter from '@/shared/components/modals/layouts/ModalFooter.vue'

const emit = defineEmits(['close'])
const router = useRouter()
const data = reactive({
    first_name: '',
    last_name: '',
    receive_emails: true,
})

const queryClient = useQueryClient()

function save(promise: (promise: () => Promise<void>) => void) {
    promise(async () => {
        const response = await updateName({
            first_name: data.first_name,
            last_name: data.last_name,
            receive_emails: data.receive_emails,
        })
        void queryClient.invalidateQueries({
            queryKey: ['user'],
        })

        if (response.data.onboarded) {
            void router.push({ name: 'home' })
        } else {
            void router.push({ name: 'home' })
        }
        emit('close')
    })
}
</script>

<style scoped></style>
