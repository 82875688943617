<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'
import { cn } from '@/shared/lib/utils'
import { useFormField } from './useFormField'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()

const { formDescriptionId } = useFormField()
</script>

<template>
  <p
    :id="formDescriptionId"
    :class="cn('text-sm text-parchment-500 dark:text-parchment-400', props.class)"
  >
    <slot />
  </p>
</template>
