<!-- ConversationControls.vue -->
<template>
    <div
        class="conversationEngineUIControls flex h-full w-full max-w-prose flex-col items-center justify-end pb-6 md:pb-10 lg:pb-24"
    >
        <template v-if="shouldShowVoiceButton || freeTrialEnded">
            <div class="flex w-full flex-col gap-4">
                <processing-component
                    v-if="waitingForNewQuestion || isProcessing"
                    :isActive="waitingForNewQuestion || isProcessing"
                    class="flex h-full w-full items-center justify-center"
                />
                <template v-else-if="freeTrialEnded">
                    <router-link
                        :to="{
                            name: 'profile',
                            hash: 'membership',
                        }"
                    >
                        <v-button>Subscribe</v-button>
                    </router-link>
                </template>
                <voice-button
                    v-else
                    :disabled="isDisabled"
                    :loading="isProcessing"
                    class="mx-auto"
                    @click="handleVoiceButtonClick"
                    @recorded="extractData"
                    @recordingStateChange="updateRecordingState"
                />
            </div>
        </template>
        <div
            v-if="!waitingForNewQuestion && !isProcessing && !freeTrialEnded"
            class="flex w-full flex-col items-center justify-center gap-6"
        >
            <button
                v-if="!isSpeakingMode"
                :class="[
                    selectedOptions.length || textInput.length
                        ? 'bg-parchment-700 text-parchment-50 hover:bg-parchment-900'
                        : 'border-parchment-200 text-parchment-400',
                    isDisabled ? 'cursor-not-allowed opacity-50' : '',
                ]"
                :disabled="isDisabled || textInput.length === 0"
                class="select-none rounded-full border-2 border-parchment-900 px-14 py-3 font-bold transition-colors duration-75 hover:bg-parchment-800 hover:text-parchment-50"
                @click="
                    extractData(
                        selectedOptions.length ? selectedOptions : textInput
                    )
                "
            >
                Submit
            </button>
            <button
                v-if="!isRecording"
                :disabled="isDisabled"
                class="select-none text-sm font-normal text-parchment-500"
                @click="toggleSpeakingMode"
            >
                Switch to
                <span class="font-bold text-parchment-700 hover:text-parchment-900">
                    {{ isSpeakingMode ? 'typing' : 'speaking' }} mode
                </span>
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import ProcessingComponent from '@/shared/components/ProcessingComponent.vue'
import VoiceButton from '@/shared/components/VoiceButton.vue'
import TriangleAlertIcon from '@/shared/components/icons/TriangleAlertIcon.vue'
import VButton from '@/shared/components/VButton.vue'
import { uaInjectKey } from '@/shared/types/inject'

const emit = defineEmits([
    'toggle-speaking-mode',
    'extract-data',
    'voice-button-click',
    'recording-state-change',
])

const props = defineProps({
    freeTrialEnded: {
        type: Boolean,
        default: false,
    },
    waitingForNewQuestion: {
        type: Boolean,
        required: true,
    },
    isSpeakingMode: {
        type: Boolean,
        required: true,
    },
    isRecording: {
        type: Boolean,
        required: true,
    },
    selectedOptions: {
        type: Array as () => string[],
        required: true,
    },
    textInput: {
        type: String,
        required: true,
    },
    systemMessage: {
        type: Object as () => SystemMessage | null,
        default: null,
    },
    isProcessing: {
        type: Boolean,
        required: true,
    },
})

const shouldShowVoiceButton = computed(() => {
    return (
        props.isSpeakingMode ||
        props.isProcessing ||
        props.isRecording ||
        props.waitingForNewQuestion
    )
})

const isDisabled = computed(() => {
    return (
        props.isProcessing ||
        props.waitingForNewQuestion ||
        props.freeTrialEnded
    )
})

function toggleSpeakingMode() {
    emit('toggle-speaking-mode')
}

function extractData(data: string | string[]) {
    emit('extract-data', data)
}

function handleVoiceButtonClick() {
    emit('voice-button-click')
}

function updateRecordingState(state: boolean) {
    emit('recording-state-change', state)
}

interface SystemMessage {
    type: 'high_demand' | 'alert'
    title: string
    content: string
    show: (() => void) | undefined
}
</script>
