<template>
    <modal-backdrop>
        <modal-layout class="m-10 h-full w-full">
            <modal-content class="p-0 relative">
                <div class="absolute bottom-0 left-0 text-xs text-stone-600 p-2">
                    <p>
                        Double click any connection to delete it.
                    </p>
                    <p>
                        Double click any node to edit it.
                    </p>
                </div>
                <VueFlow
                    :edges="edges"
                    :nodes="nodes"
                    class="w-full"
                    @edge-double-click="deleteEdge"
                    @node-double-click="e => onEdit(e.node)"
                >
                    <Background
                        pattern-color="#000"
                        variant="dots"
                    />
                    <template #node-default="props">
                        <NodeTreeToolbar
                            :id="props.id"
                            :data="props.data"
                            @clean="() => onCleanup(props)"
                            @create="() => onCreate(props)"
                            @edit="() => onEdit(props)"
                            @load="
                                () =>
                                    load(
                                        props.id.split('-')[0],
                                        props.id.split('-')[1]
                                    )
                            "
                            @link="(event) => onLink(props, event)"
                        />
                    </template>
                </VueFlow>
            </modal-content>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import api from '@/shared/utils/api'
import { onMounted, ref } from 'vue'
import { useLayout } from '@/shared/composables/useLayout'
import { useVueFlow, VueFlow } from '@vue-flow/core'
import AdminAchievementModal from '@/shared/components/modals/admin/AdminAchievementModal.vue'
import AdminDataPointModal from '@/shared/components/modals/admin/AdminDataPointModal.vue'
import useModal from '@/shared/composables/useModal'
import NodeTreeToolbar from '@/shared/components/NodeTreeToolbar.vue'
import SearchSelectModal from '@/shared/components/modals/SearchSelectModal.vue'
import AdminSummaryModal from '@/shared/components/modals/admin/AdminSummaryModal.vue'
import AdminCategoryModal from '@/shared/components/modals/admin/AdminCategoryModal.vue'
import { Background } from '@vue-flow/background'
import AdminImageTypeModal from '@/shared/components/modals/admin/AdminImageTypeModal.vue'
import { useToast } from '@/shared/components/ui/toast'

const props = defineProps({
    nodeType: {
        type: String,
        required: true,
    },
    nodeId: {
        type: Number,
        required: true,
    },
})

const vf = useVueFlow()
const toast = useToast()

const { layout } = useLayout()
const modal = useModal()

const nodes = ref([])
const edges = ref([])

function processTree(data) {
    const nodesObject = {}
    const tempEdges = []

    const getCategoryId = (node) => {
        if (node.type === 'Category') {
            return node.id
        }
        if (node.parents?.length) {
            return getCategoryId(node.parents[0])
        }
        return null
    }

    const traverseRecursive = (node) => {
        const id = node.type + '-' + node.id
        const type = node.type.includes('Achievement')
            ? 'achievement'
            : node.type.includes('DataPoint')
              ? 'data-point'
              : node.type.includes('Category')
                ? 'category'
                : node.type.includes('Summary')
                  ? 'summary'
                  : 'unknown'
        nodesObject[id] = {
            id: id,
            // type: 'custom',
            position: {
                x: 0,
                y: 0,
            },
            class: 'node-' + type,
            data: {
                category_id: getCategoryId(node),
                label: node.name,
                id: node.id,
                type: node.type,
                // toolbarVisible: node.type === "Summary\\Summary" ? false : undefined,
            },
        }

        if (node.children?.length) {
            node.children.forEach((child) => {
                const childId = child.type + '-' + child.id
                tempEdges.push({
                    id: id + '-' + childId,
                    source: id,
                    class: child.highlight ? 'edge-highlight' : '',
                    target: childId,
                })
                traverseRecursive(child)
            })
        }

        if (node.parents?.length) {
            node.parents.forEach((parent) => {
                const parentId = parent.type + '-' + parent.id
                tempEdges.push({
                    id: parentId + '-' + id,
                    source: parentId,
                    class: parent.highlight ? 'edge-highlight' : '',
                    target: id,
                })
                traverseRecursive(parent)
            })
        }
    }

    traverseRecursive(data)

    // attach existing nodes to the nodes object
    for (const node of nodes.value) {
        nodesObject[node.id] = node
    }
    let nodesArray = Object.values(nodesObject)
    nodes.value = nodesArray

    requestAnimationFrame(() => {
        edges.value = [...edges.value, ...tempEdges]
        setTimeout(() => {
            nodesArray = layout(nodesArray, edges.value, 'LR')

            nodes.value = nodesArray

            requestAnimationFrame(() => {
                const selected = vf.getSelectedNodes.value?.[0] ?? null
                if (selected) {
                    vf.fitBounds(
                        {
                            x: selected.position.x - 500,
                            y: selected.position.y - 500,
                            width: 1000,
                            height: 1000,
                        },
                        {
                            padding: 0.1,
                        }
                    )
                }
            })
        }, 100)
    })
}

function onEdit(node) {
    const { id } = node
    const [type, nodeId] = id.split('-')

    let modalType = null
    switch (type) {
        case 'Achievement':
            modalType = AdminAchievementModal
            break
        case 'DataPoint':
            modalType = AdminDataPointModal
            break
        case 'Summary':
            modalType = AdminSummaryModal
            break
        case 'Category':
            modalType = AdminCategoryModal
            break
        case 'ImageType':
            modalType = AdminImageTypeModal
            break
        default:
            console.log('Unknown type:', type)
            break
    }

    if (modalType) {
        modal.show(
            modalType,
            {
                achievementId: nodeId,
                dataPointId: nodeId,
                summaryId: nodeId,
                categoryId: nodeId,
                imageTypeId: nodeId,
            },
            {
                onSaved: () => {
                    load(type, nodeId)
                },
            }
        )
    }
}

async function attachNode(parent, child, props) {
    switch (parent.type) {
        case 'Category':
            await api.patch(`/api/admin/v2/achievements/${child.id}`, {
                category_id: parent.id,
            })
            break
        case 'Achievement':
            await api.patch(`/api/admin/v2/data-points/${child.id}`, {
                achievement_id: parent.id,
            })
            break
        case 'DataPoint':
            await api.post(`/api/admin/v2/summaries/${child.id}/schemas`, {
                schemaable_id: parent.id,
                schemaable_type: parent.type,
            })
            break
        case 'ImageType':
            switch (props.type) {
                case 'Summary':
                    await api.post(`/api/admin/v2/image-types/${parent.id}/summaries`, {
                        summary_id: child.id,
                    })
                    break
                case 'DataPoint':
                    await api.post(`/api/admin/v2/image-types/${parent.id}/data-points`, {
                        data_point_id: child.id,
                    })
                    break
                default:
                    toast.error('Unknown link type for ImageType: ' + child.type)
                    break
            }
            break
        case 'Summary':
            await api.post(`/api/admin/v2/summaries/${parent.id}/schemas`, {
                schemaable_id: child.id,
                schemaable_type: props.type,
            })
            break
        default:
            toast.error('Cannot attach to this type: ' + parent.type)
            console.log('Unknown parent type:', parent.type)
            break
    }
    void load(parent.type, parent.id)
}

function onLink(node, buttonProps) {
    const id = node.id.split('-')[1]
    let url = null
    let placeholder = null
    switch (node.data.type) {
        case 'Category':
            url = '/api/admin/v2/achievements'
            placeholder = 'Search for achievements'
            break
        case 'Achievement':
            url = '/api/admin/v2/data-points'
            placeholder = 'Search for data points'
            break
        case 'DataPoint':
            url = '/api/admin/v2/summary-schemas'
            placeholder = 'Search for summaries'
            break
        case 'ImageType':
            switch (buttonProps.type) {
                case 'Summary':
                    url = '/api/admin/v2/summaries'
                    placeholder = 'Search for summaries'
                    break
                case 'DataPoint':
                    url = '/api/admin/v2/data-points'
                    placeholder = 'Search for data points'
                    break
                default:
                    toast.error('Unknown link type for ImageType: ' + buttonProps.type)
                    break
            }
            break
        case 'Summary':
            switch (buttonProps.type) {
                case 'DataPoint':
                    url = '/api/admin/v2/data-points'
                    placeholder = 'Search for data points'
                    break
                case 'Achievement':
                    url = '/api/admin/v2/achievements'
                    placeholder = 'Search for achievements'
                    break
                default:
                    toast.error('Unknown link type for Summary: ' + buttonProps.type)
                    break
            }
            break
        default:
            toast.error('Cannot link for this type: ' + node.data.type)
            break
    }

    if (url) {
        modal.show(
            SearchSelectModal,
            {
                url,
                placeholder,
                filters: {
                    category_id: node.data.category_id,
                },
            },
            {
                onSelect: async (payload, close, modal) => {
                    const [element] = payload
                    await attachNode(
                        {
                            id,
                            type: node.data.type,
                        },
                        element,
                        buttonProps
                    )
                    close()
                },
            }
        )
    }
}

function onCreate(node, isParent = false) {
    const id = node.id.split('-')[1]
    let modalType = null
    switch (node.data.type) {
        case 'Category':
            modalType = AdminAchievementModal
            break
        case 'Achievement':
            modalType = AdminDataPointModal
            break
        case 'DataPoint':
            modalType = AdminSummaryModal
            break
        case 'ImageType':
            modalType = AdminImageTypeModal
            break
        default:
            toast.error('Cannot create child for this type')
            console.log('Unknown type:', node.data.type)
            break
    }

    if (modalType) {
        modal.show(
            modalType,
            {
                prefill: {
                    category_id: node.data.category_id,
                    achievement_id: +id,
                },
            },
            {
                onCreate: async (payload, close) => {
                    const [element] = payload
                    await attachNode(
                        {
                            id,
                            type: node.data.type,
                        },
                        element
                    )
                    close()
                },
            }
        )
    }
}

async function load(type, id) {
    const { data } = await api.get(`/api/admin/v2/tree/${type}/${id}`)

    try {
        processTree(data)
    } catch (e) {
        console.error(e)
    }
}

function onCleanup(node) {
    // show only parents of this node and parents of parents
    const id = node.id.split('-')[1]
    const type = node.data.type

    const tempNodes = [nodes.value.find((n) => n.id === node.id)]
    const tempEdges = []

    const cleanupRecursive = (node) => {
        const parentEdges = edges.value.filter(
            (edge) => edge.target === node.id
        )

        if (parentEdges.length) {
            parentEdges.forEach((edge) => {
                const parent = nodes.value.find((n) => n.id === edge.source)
                if (parent) {
                    console.log({ ...parent })
                    tempNodes.push(parent)
                    tempEdges.push(edge)
                    cleanupRecursive(parent)
                }
            })
        }
    }

    cleanupRecursive(node)

    console.log(tempNodes, tempEdges)

    nodes.value = []
    edges.value = []

    requestAnimationFrame(() => {
        nodes.value = tempNodes

        requestAnimationFrame(() => {
            edges.value = tempEdges
            // nodes.value = layout(nodes.value, edges.value, 'TD')
        })
    })
}

function deleteEdge({ event, edge }) {
    modal.show(
        'confirm',
        {
            title: 'Delete connection?',
            message: `Are you sure you want to delete the connection between ${edge.sourceNode.data.label} and ${edge.targetNode.data.label}?`,
        },
        {
            onConfirm: async () => {
                await api.delete(
                    `/api/admin/v2/connections/${edge.sourceNode.data.type}/${edge.sourceNode.data.id}/${edge.targetNode.data.type}/${edge.targetNode.data.id}`
                )
                nodes.value = []
                edges.value = []
                void load(props.nodeType, props.nodeId)
            },
        }
    )
}

onMounted(async () => {
    void load(props.nodeType, props.nodeId)
})
</script>
