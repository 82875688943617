<template>
    <component
        :is="component"
        :class="{
            // '-translate-y-8 scale-90': type !== 'full' && order > 1,
            // 'h-full w-full': type === 'full',
            // '': type === 'modal',
        }"
        v-bind="$attrs"
        @close="close"
    />
</template>

<script lang="ts" setup>
import { onMounted, provide, ref, watch } from 'vue'

const emit = defineEmits(['close', 'closing'])

defineOptions({
    inheritAttrs: false,
})

const props = defineProps({
    open: {
        type: Boolean,
        required: true,
    },
    component: {
        type: Object,
        required: true,
    },
    order: {
        type: Number,
        default: 0,
    },
    type: {
        type: String,
        default: 'popup',
    },
    backdrop: {
        type: Boolean,
        default: true,
    },
})

provide('close-current-modal', close)
provide('backdrop-enabled', props.backdrop)

const backdropOpen = ref(false)
const componentOpen = ref(false)

onMounted(() => {
    backdropOpen.value = true
})

function close() {
    emit('close')
}

watch(
    () => props.open,
    (open) => {
        if (!open) {
            close()
        }
    },
    { immediate: true }
)
</script>
