// /shared/stores/developmentInsightsStore.ts
import { defineStore } from 'pinia'
import { computed, MaybeRefOrGetter, ref, toRaw, toRef, toValue } from 'vue'
import api from '@/shared/utils/api'
import { DevelopmentInsight } from '@/shared/types/developmentInsight'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { useLocalStorage } from '@vueuse/core'
import useModal from '@/shared/composables/useModal'
import DevelopmentInsightsPopup from '@/shared/components/DevelopmentInsightsPopup.vue'

export const useDevelopmentInsightsStore = defineStore(
    'developmentInsights',
    () => {
        const currentInsights = ref<DevelopmentInsight[]>([])
        const savedInsights = ref<DevelopmentInsight[]>([])
        const historyInsights = ref<DevelopmentInsight[]>([])
        const historyPage = ref(1)
        const historyTotalPages = ref(1)

        const hasInsights = computed(
            () =>
                currentInsights.value.length > 0 ||
                savedInsights.value.length > 0 ||
                historyInsights.value.length > 0
        )

        function pushInsight(insight: DevelopmentInsight) {}

        async function loadCurrentInsights(
            projectId: string,
            narrativeElementId: string
        ) {
            try {
                const response = await api.get(
                    `/api/v1/development-insights/current/${projectId}/${narrativeElementId}`
                )
                currentInsights.value = response.data.data
            } catch (error) {
                console.error('Failed to load current insights:', error)
            }
        }

        async function loadSavedInsights(
            projectId: string,
            narrativeElementId: string
        ) {
            try {
                const response = await api.get(
                    `/api/v1/development-insights/saved/${projectId}/${narrativeElementId}`
                )
                savedInsights.value = response.data.data
            } catch (error) {
                console.error('Failed to load saved insights:', error)
            }
        }

        async function loadHistoryInsights(
            projectId: string,
            narrativeElementId: string,
            page = 1
        ) {
            try {
                const response = await api.get(
                    `/api/v1/development-insights/history/${projectId}/${narrativeElementId}?page=${page}`
                )
                historyInsights.value = response.data.data
                historyPage.value = page
                historyTotalPages.value = response.data.meta.last_page
            } catch (error) {
                console.error('Failed to load history insights:', error)
            }
        }

        async function saveInsight(insight: DevelopmentInsight) {
            try {
                if (!insight.actions.save) {
                    throw new Error(
                        'Save action not available for this insight'
                    )
                }

                const { method, path } = insight.actions.save
                await api.request({
                    method,
                    url: path,
                })

                const insightToMove =
                    currentInsights.value.find((i) => i.id === insight.id) ||
                    historyInsights.value.find((i) => i.id === insight.id)
                if (insightToMove) {
                    savedInsights.value.push({
                        ...insightToMove,
                        saved_at: new Date().toISOString(),
                    })
                    currentInsights.value = currentInsights.value.filter(
                        (i) => i.id !== insight.id
                    )
                    historyInsights.value = historyInsights.value.filter(
                        (i) => i.id !== insight.id
                    )
                }
            } catch (error) {
                console.error('Failed to save insight:', error)
            }
        }

        async function unsaveInsight(insight: DevelopmentInsight) {
            try {
                if (!insight.actions.unsave) {
                    throw new Error(
                        'Unsave action not available for this insight'
                    )
                }

                const { method, path } = insight.actions.unsave
                await api.request({
                    method,
                    url: path,
                })

                const insightToMove = savedInsights.value.find(
                    (i) => i.id === insight.id
                )
                if (insightToMove) {
                    historyInsights.value.unshift({
                        ...insightToMove,
                        saved_at: null,
                    })
                    savedInsights.value = savedInsights.value.filter(
                        (i) => i.id !== insight.id
                    )
                }
            } catch (error) {
                console.error('Failed to unsave insight:', error)
            }
        }

        function addNewInsight(insight: DevelopmentInsight) {
            currentInsights.value.unshift(insight)
        }

        return {
            currentInsights,
            savedInsights,
            historyInsights,
            historyPage,
            historyTotalPages,
            hasInsights,
            loadCurrentInsights,
            loadSavedInsights,
            loadHistoryInsights,
            saveInsight,
            unsaveInsight,
            addNewInsight,
        }
    }
)

type StateType = 'current' | 'saved' | 'history'

type InsightStore = {
    title: string | undefined
    caption: string | undefined
    insights: DevelopmentInsight[]
}

const showDevelopmentInsightsMobile = useLocalStorage(
    'show_development_insights_mobile',
    true
)

export function useInsightClient() {
    const client = useQueryClient()
    const { show } = useModal()

    function getKey(
        projectId: MaybeRefOrGetter<string | number>,
        narrativeElementId: MaybeRefOrGetter<string | number>,
        state: MaybeRefOrGetter<StateType>
    ) {
        return [
            'insights',
            toValue(projectId),
            toValue(narrativeElementId),
            toValue(state),
        ]
    }

    return {
        showDevelopmentInsightsMobile,
        openPopup(
            projectId: MaybeRefOrGetter<string | number>,
            narrativeElementId: MaybeRefOrGetter<string | number>,
            force = false
        ) {
            if (force || showDevelopmentInsightsMobile.value) {
                show(DevelopmentInsightsPopup, {
                    isFullWidth: true,
                    projectId: projectId,
                    narrativeElementId: narrativeElementId,
                })
            }
        },
        updateInsight(
            projectId: MaybeRefOrGetter<string | number>,
            narrativeElementId: MaybeRefOrGetter<string | number>,
            state: MaybeRefOrGetter<StateType>,
            insight: DevelopmentInsight
        ) {
            const key = getKey(projectId, narrativeElementId, state)
            const existingInsights = {
                ...(client.getQueryData<InsightStore>(key) ?? { insights: [] }),
            } as InsightStore
            // just some random value to trigger reactivity
            existingInsights.test = Date.now()
            if (existingInsights.insights?.length) {
                if (toValue(state) === 'saved' && !insight.saved_at) {
                    client.setQueryData(
                        key,
                        existingInsights.insights.filter(
                            (i) => i.id !== insight.id
                        )
                    )
                } else {
                    const index = existingInsights.insights.findIndex(
                        (i) => i.id === insight.id
                    )
                    if (index > -1) {
                        existingInsights.insights[index] = insight
                        client.setQueryData(key, existingInsights)
                    }
                }
            }
        },
        setData(
            projectId: MaybeRefOrGetter<string | number>,
            narrativeElementId: MaybeRefOrGetter<string | number>,
            state: MaybeRefOrGetter<StateType>,
            data: InsightStore,
        ) {
            // just some random value to trigger reactivity
            data.test = Date.now()
            console.log('new data', data)
            client.setQueryData(
                getKey(projectId, narrativeElementId, state),
                { ...data }
            )
        },
        toggleSaveInsight(
            projectId: MaybeRefOrGetter<string | number>,
            narrativeElementId: MaybeRefOrGetter<string | number>,
            state: MaybeRefOrGetter<StateType>,
            insight: DevelopmentInsight
        ) {
            if (insight.saved_at) {
                insight = { ...insight, saved_at: null }
                void api.post(
                    `/api/v1/development-insights/${insight.id}/unsave`
                )
            } else {
                insight = { ...insight, saved_at: new Date().toISOString() }
                void api.post(`/api/v1/development-insights/${insight.id}/save`)
            }
            this.updateInsight(projectId, narrativeElementId, state, insight)
        },
    }
}

export function useInsightQuery(
    projectId: MaybeRefOrGetter<string | number>,
    narrativeElementId: MaybeRefOrGetter<string | number>,
    state: MaybeRefOrGetter<StateType> = 'current'
) {
    const projectIdRef = toRef(projectId)
    const narrativeElementIdRef = toRef(narrativeElementId)
    const stateRef = toRef(state)

    return useQuery({
        queryKey: ['insights', projectIdRef, narrativeElementIdRef, stateRef],
        enabled: computed(
            () => !!projectIdRef.value && !!narrativeElementIdRef.value
        ),
        refetchOnMount: false,
        queryFn: async () => {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            const response = await api.get<{
                title: string
                caption: string
                insights: DevelopmentInsight[]
            }>(
                `/api/v1/development-insights/projects/${projectIdRef.value}/narrative-elements/${narrativeElementIdRef.value}`,
                {
                    params: {
                        state: stateRef.value,
                    },
                }
            )
            return response.data
        },
    })
}
