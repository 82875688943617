<!-- /resources/shared/components/DevelopmentInsights.vue -->
<template>
    <header
        class="sticky top-0 z-10 flex w-full select-none flex-col items-center gap-1 bg-white px-2 pb-2 pt-4 text-parchment-900 md:px-6"
    >
        <h1
            class="whitespace-nowrap text-xs font-semibold capitalize md:text-sm"
        >
            Creative Insights
        </h1>
        <nav
            class="flex w-full items-start justify-center gap-2 whitespace-nowrap bg-white text-sm font-medium leading-snug text-parchment-700"
        >
            <button
                v-for="tab in tabs"
                :key="tab.value"
                :class="[
                    activeTab === tab.value
                        ? 'border-b-parchment-900'
                        : 'cursor-pointer border-transparent text-parchment-500 duration-75 hover:text-parchment-600',
                ]"
                class="select-none border-b-2 px-2 py-1"
                @click="activeTab = tab.value"
            >
                {{ tab.title }}
            </button>
        </nav>
    </header>

    <div
        v-if="insightsData?.insights?.length"
        class="flex w-full flex-col gap-4 overflow-y-auto px-2 py-2 md:px-4"
    >
        <div
            v-if="
                insightsData &&
                !isLoading &&
                !isLoadingQuery &&
                activeTab === 'current'
            "
            class="flex flex-col gap-1 py-4"
        >
            <h1 class="text-base font-semibold text-parchment-900">
                {{ insightsData.title }}
            </h1>
            <p class="text-sm text-parchment-700">
                {{ insightsData.caption }}
            </p>
        </div>

        <div
            v-if="insightsData || isLoading || isLoadingQuery"
            class="flex w-full flex-col items-center gap-4 md:max-h-full md:gap-5"
        >
            <div
                v-if="isLoading || isLoadingQuery"
                class="flex w-full flex-col gap-4 py-5"
            >
                <div
                    v-for="i in 20"
                    :key="i"
                    :style="{
                        width: Math.random() * 50 + 50 + '%',
                    }"
                    class="animate-skeleton h-10 w-full"
                ></div>
            </div>
            <template v-else>
                <dynamic-insight-card
                    v-for="insight in insightsData.insights"
                    :key="insight.id"
                    :insight="insight"
                    @update:save-insight="
                        insightClient.toggleSaveInsight(
                            projectId,
                            narrativeElementId,
                            activeTab,
                            insight
                        )
                    "
                />
            </template>
        </div>
    </div>
    <div
        v-if="!isLoading && !isLoadingQuery && !insightsData?.insights?.length"
        class="flex w-full flex-col overflow-y-auto px-2 py-4 md:px-4"
    >
        <div class="text-parchment-500">No insights found.</div>
    </div>
    <!--        <div-->
    <!--            v-if="activeTab === 'History' && historyTotalPages > 1"-->
    <!--            class="mt-4 flex w-full justify-between"-->
    <!--        >-->
    <!--            <button-->
    <!--                :disabled="historyPage === 1"-->
    <!--                class="rounded bg-gray-200 px-3 py-1"-->
    <!--                @click="loadPreviousPage"-->
    <!--            >-->
    <!--                Previous-->
    <!--            </button>-->
    <!--            <span>{{ historyPage }} / {{ historyTotalPages }}</span>-->
    <!--            <button-->
    <!--                :disabled="historyPage === historyTotalPages"-->
    <!--                class="rounded bg-gray-200 px-3 py-1"-->
    <!--                @click="loadNextPage"-->
    <!--            >-->
    <!--                Next-->
    <!--            </button>-->
    <!--        </div>-->
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import {
    useDevelopmentInsightsStore,
    useInsightClient,
    useInsightQuery,
} from '@/shared/stores/developmentInsightsStore'
import DynamicInsightCard from '@/shared/components/DynamicInsightCard.vue'

const props = defineProps({
    projectId: {
        type: String,
        default: () => undefined,
    },
    narrativeElementId: {
        type: String,
        default: () => undefined,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
})

const developmentInsightsStore = useDevelopmentInsightsStore()
const insightClient = useInsightClient()
const tabs = [
    { title: 'Current', value: 'current' },
    { title: 'Saved', value: 'saved' },
    { title: 'History', value: 'history' },
]
const activeTab = ref('current')
const { data: insightsData, isLoading: isLoadingQuery } = useInsightQuery(
    computed(() => props.projectId),
    computed(() => props.narrativeElementId),
    computed(() => activeTab.value.toLowerCase())
)

const historyPage = computed(() => developmentInsightsStore.historyPage)
const historyTotalPages = computed(
    () => developmentInsightsStore.historyTotalPages
)

async function loadPreviousPage() {
    if (historyPage.value > 1) {
        await developmentInsightsStore.loadHistoryInsights(
            props.projectId,
            props.narrativeElementId,
            historyPage.value - 1
        )
    }
}

async function loadNextPage() {
    if (historyPage.value < historyTotalPages.value) {
        await developmentInsightsStore.loadHistoryInsights(
            props.projectId,
            props.narrativeElementId,
            historyPage.value + 1
        )
    }
}
</script>
