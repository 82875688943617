import { ref } from 'vue'
import { Channel } from 'laravel-echo'
import { useEcho } from '@/shared/types/useEcho'

const channels = ref({} as Record<string, Channel>)

export default function useChannel() {
    const { echo } = useEcho()

    return {
        listen(type: 'public' | 'private' | 'presence', name: string) {
            if (!channels.value[name]) {
                if (!echo.value) {
                    return null
                }
                switch (type) {
                    case 'public':
                        channels.value[name] = echo.value.channel(name)
                        break
                    case 'private':
                        channels.value[name] = echo.value.private(name)
                        break
                    case 'presence':
                        channels.value[name] = echo.value.join(name)
                        break
                }
            }
            return channels.value[name]
        },
        leave(name: string) {
            echo.value?.leave(name)
            delete channels.value[name]
        },
        leaveAll() {
            echo.value?.leaveAllChannels()
            channels.value = {}
        },
    }
}
