<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <g clip-path="url(#clip0_2336_41879)">
            <path
                d="M3.33337 5.8335H16.6667"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.33337 9.1665V14.1665"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.6666 9.1665V14.1665"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.16663 5.8335L4.99996 15.8335C4.99996 16.2755 5.17555 16.6994 5.48811 17.012C5.80068 17.3246 6.2246 17.5002 6.66663 17.5002H13.3333C13.7753 17.5002 14.1992 17.3246 14.5118 17.012C14.8244 16.6994 15 16.2755 15 15.8335L15.8333 5.8335"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_2336_41879">
                <rect
                    width="20"
                    height="20"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
</template>
