<template>
    <div class="flex min-h-[168px] max-w-full flex-col gap-8 overflow-visible">
        <div class="abstract-circles-container">
            <div
                v-for="(circle, index) in circles"
                :key="index"
                :style="circleStyles(circle)"
                class="circle"
            ></div>
        </div>
        <div class="text-sm text-parchment-500">
            {{ conversationStore.getStatusMessage }}
        </div>
        <div
            v-if="remainingTime !== null"
            class="flex flex-col items-center gap-1"
        >
            <transition
                appear
                appear-active-class="duration-300"
                appear-to-class="opacity-100"
                enter-from-class="opacity-0"
            >
                <div class="h-1 w-32 overflow-hidden rounded-xl bg-parchment-100">
                    <div
                        :style="{
                            width: 100 - (remainingTime / 60) * 100 + '%',
                        }"
                        class="h-full bg-red-500 duration-300"
                    ></div>
                </div>
            </transition>
            <transition
                enter-active-class="duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
            >
                <p
                    v-if="remainingTime <= 1"
                    class="text-xs text-parchment-400"
                >
                    It's taking longer than expected. Please wait a moment.
                </p>
            </transition>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'

import { useConversationStore } from '@/shared/stores/conversationStore'

const conversationStore = useConversationStore()

const props = defineProps({
    isActive: {
        type: Boolean,
        default: true,
    },
})

const circles = ref([
    { x: 0, y: 0, scale: 1, opacity: 0.1, color: '#ef4444', zIndex: 0 },
    { x: 0, y: 0, scale: 1, opacity: 0.15, color: '#ef4444', zIndex: 1 },
    { x: 0, y: 0, scale: 1, opacity: 0.2, color: '#ef4444', zIndex: 2 },
])

const targetColors = ['#FFAB07', '#076AFF', '#DC07FF']
const animationDuration = 10000 // 10 seconds
let startTime: number | null = null
let timeInterval: number | null = null
let timerStartTime: number | null = null
const remainingTime = ref<number | null>(null)

// animate between the three colors randomly
const randomizeColors = () => {
    targetColors.sort(() => Math.random() - 0.5)
}

const circleStyles = (circle: {
    x: number
    y: number
    scale: number
    opacity: number
    color: string
    zIndex: number
}) => {
    return {
        transform: `translate(${circle.x}px, ${circle.y}px) scale(${circle.scale})`,
        opacity: props.isActive ? circle.opacity : 0,
        backgroundColor: circle.color,
        zIndex: circle.zIndex,
    }
}

let animationFrame: number | null = null

const animate = (timestamp: number) => {
    if (!startTime) startTime = timestamp
    const elapsedTime = timestamp - startTime
    const progress = Math.min(elapsedTime / animationDuration, 1)

    circles.value.forEach((circle, index) => {
        const time = Date.now() / 1000
        const angle = time + (index * 2 * Math.PI) / 3
        const radius = 10

        circle.x = radius * Math.sin(angle * 1.5) * Math.cos(angle * 0.8)
        circle.y = radius * Math.cos(angle * 1.2) * Math.sin(angle * 0.6)
        circle.scale = 1 + 0.1 * Math.sin(angle * 2)

        // Color transition
        if (progress < 0.8) {
            const startColor = hexToRgb('#ef4444')
            const endColor = hexToRgb(targetColors[index])
            circle.color = rgbToHex(
                interpolateColor(startColor!, endColor!, progress / 0.8)
            )
        } else {
            const startColor = hexToRgb(targetColors[index])
            const endColor = hexToRgb('#ef4444')
            circle.color = rgbToHex(
                interpolateColor(startColor!, endColor!, (progress - 0.8) / 0.2)
            )
        }

        // Opacity and z-index changes
        circle.opacity = 0.1 + ((Math.sin(time + index) + 1) / 2) * 0.1
        circle.zIndex = Math.floor(((Math.sin(time + index) + 1) / 2) * 2)
    })

    if (progress < 1) {
        animationFrame = requestAnimationFrame(animate)
    } else {
        startTime = null
        animationFrame = requestAnimationFrame(animate)
    }
}

const startAnimation = () => {
    if (!animationFrame) {
        startTime = null
        animate(performance.now())
        timerStartTime = Date.now()
        timeInterval = setInterval(() => {
            // 1 minute minus the time it took to animate
            remainingTime.value = Math.max(
                0,
                Math.floor(60 - (Date.now() - timerStartTime) / 1000)
            )
        }, 1000)
    }
}

const stopAnimation = () => {
    if (animationFrame) {
        cancelAnimationFrame(animationFrame)
        animationFrame = null
        clearInterval(timeInterval)
    }
}

watch(
    () => props.isActive,
    (newValue) => {
        if (newValue) {
            startAnimation()
        } else {
            stopAnimation()
        }
    },
    { immediate: true }
)

onMounted(() => {
    if (props.isActive) {
        startAnimation()
    }
})

onUnmounted(() => {
    stopAnimation()
})

// Helper functions for color interpolation
function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

function rgbToHex(rgb: { r: number; g: number; b: number }) {
    return (
        '#' +
        ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)
    )
}

function interpolateColor(
    color1: { r: number; g: number; b: number },
    color2: { r: number; g: number; b: number },
    factor: number
) {
    return {
        r: Math.round(color1.r + factor * (color2.r - color1.r)),
        g: Math.round(color1.g + factor * (color2.g - color1.g)),
        b: Math.round(color1.b + factor * (color2.b - color1.b)),
    }
}
</script>

<style scoped>
.abstract-circles-container {
    width: 96px;
    height: 96px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .abstract-circles-container {
        width: 56px;
        height: 56px;
    }
}

.circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    will-change: transform, opacity, background-color, z-index;
    transition:
        opacity 0.3s ease,
        background-color 0.3s ease;
}
</style>
