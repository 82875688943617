<!-- DynamicTextarea.vue -->
<template>
    <div class="relative w-full">
        <div
            v-if="isLimitReached"
            class="sticky left-0 right-0 top-0 bg-red-100 px-4 py-2 text-sm text-red-700"
        >
            Character limit reached of {{ maxLength }} characters
        </div>
        <textarea
            ref="textareaRef"
            :value="modelValue"
            :class="[
                'mx-auto my-auto w-full text-left !outline-0 ring-0',
                isLimitReached ? 'shadow-xl ring-1 ring-red-600' : '',
                customClass ||
                    'resize-none bg-transparent text-xl font-normal text-black',
            ]"
            class="w-full"
            :placeholder="placeholder"
            :enterkeyhint="enterKeyHint"
            :inputmode="inputMode"
            :maxlength="maxLength"
            @input="onInput"
            @keydown.enter="onEnter"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, nextTick, computed } from 'vue'
const emit = defineEmits(['update:modelValue', 'extract-data', 'limit-reached'])

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: 'Enter your answer',
    },
    enterKeyHint: {
        type: String,
        default: 'done',
    },
    inputMode: {
        type: String,
        default: 'text',
    },
    customClass: {
        type: String,
        default: '',
    },
    maxLength: {
        type: Number,
        default: Infinity,
    },
})

const textareaRef = ref<HTMLTextAreaElement | null>(null)

const isLimitReached = computed(() => {
    return props.modelValue.length >= props.maxLength
})

const adjustHeight = () => {
    if (textareaRef.value) {
        textareaRef.value.style.height = 'auto' // Reset height to auto to get the correct scroll height
        textareaRef.value.style.height = `${textareaRef.value.scrollHeight}px` // Set height based on scroll height
    }
}

const onInput = (event: Event) => {
    const target = event.target as HTMLTextAreaElement
    const newValue = target.value

    if (newValue.length >= props.maxLength) {
        emit('limit-reached')
    }

    emit('update:modelValue', newValue.slice(0, props.maxLength))
    adjustHeight()
}

const onEnter = () => {
    emit('extract-data', props.modelValue)
}

onMounted(() => {
    // Ensure that height is adjusted after DOM updates
    nextTick(() => {
        adjustHeight()
    })
})

watch(
    () => props.modelValue,
    () => {
        nextTick(() => {
            adjustHeight()
        })
    }
)
</script>
