// Usage: import { useUser } from '@/shared/composables/query/user'
import { getAuthenticatedUser } from '@/shared/utils/endpoints'
import { useQuery } from '@tanstack/vue-query'
import { useAuthStore } from '@/shared/stores/auth'
import { useReconciliationStore } from '@/shared/stores/reconciliations'
import { setUser } from "@sentry/vue"
import { useLogger } from 'vue-logger-plugin'

export function useUser() {
    const { isLoggedIn } = useAuthStore()
    const reconciliationStore = useReconciliationStore()

    return useQuery({
        queryKey: ['user'],
        enabled: isLoggedIn,
        queryFn: async () => {
            const response = await getAuthenticatedUser()

            if (response.data.reconciliations) {
                reconciliationStore.setReconciliations(response.data.reconciliations)
            }

            reconciliationStore.setAvailableReconciliations(response.data.available_reconciliations)
            reconciliationStore.setNextResetTime(response.data.next_reconciliation_reset)

            return response
        },
        select: (data) => {
            return data.data
        },
    })
}
