<template>
	<button
		key="pause-button"
		class="group grid grid-rows-[1fr,auto,1fr] gap-1 items-center"
		@click="() => emit(isRecording ? 'pause' : 'resume')"
	>
		<span
			class="row-start-2 w-12 h-12 flex items-center justify-center gap-1 bg-black rounded-full select-none group-active:scale-95 transition-transform duration-75"
		>
			<template v-if="isRecording">
				<span class="w-2 h-4 bg-pure-white rounded-sm" />
				<span class="w-2 h-4 bg-pure-white rounded-sm" />
			</template>
			<template v-else>
				<play-icon class="text-white h-4 w-4" />
			</template>
		</span>
		<span class="row-start-3 text-black text-xs font-bold">
			{{ isRecording ? 'Pause' : 'Resume' }}
		</span>
	</button>
</template>

<script lang="ts" setup>
import PlayIcon from '@/shared/components/icons/PlayIcon.vue'

const emit = defineEmits(['resume', 'pause'])

defineProps({
	isRecording: Boolean,
})
</script>

<style scoped></style>
