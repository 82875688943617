<template>
    <component
        :is="is"
        :class="{
            caret: interval,
        }"
    >
        {{ _modelValue }}
    </component>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'

const emit = defineEmits(['transitionend'])

const props = defineProps({
    is: {
        type: String,
        required: true,
    },
    modelValue: {
        type: String,
        default: () => undefined,
    },
    speed: {
        type: Number,
        default: 20,
    },
    speedVariationRange: {
        type: Array as () => [number, number],
        default: () => [0.5, 2],
    },
    charactersPerSpeedChange: {
        type: Array as () => [number, number],
        default: () => [5, 7],
    },
})

const _modelValue = ref('')
const interval = ref<number | null>(null)

watch(
    () => props.modelValue,
    (value) => {
        _modelValue.value = ''
        if (interval.value) {
            clearInterval(interval.value)
            interval.value = null
        }
        animateText()
    }
)

function getRandomSpeed(): number {
    const [min, max] = props.speedVariationRange
    return props.speed * (Math.random() * (max - min) + min)
}

function getRandomCharacterCount(): number {
    const [min, max] = props.charactersPerSpeedChange
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function animateText() {
    if (props.speed === 0) {
        // If speed is 0, directly set the model value without animation
        _modelValue.value = props.modelValue;
        emit('transitionend');
        return;
    }

    const chars = props.modelValue.split('');
    let i = 0;
    let charactersUntilSpeedChange = getRandomCharacterCount();
    let currentSpeed = getRandomSpeed();

    function typeNextChar() {
        if (i < chars.length) {
            _modelValue.value += chars[i];
            i++;

            charactersUntilSpeedChange--;
            if (charactersUntilSpeedChange === 0) {
                currentSpeed = getRandomSpeed();
                charactersUntilSpeedChange = getRandomCharacterCount();
            }

            interval.value = setTimeout(typeNextChar, currentSpeed);
        } else {
            if (interval.value) {
                clearTimeout(interval.value);
                interval.value = null;
                emit('transitionend');
            }
        }
    }

    typeNextChar();
}

onMounted(() => {
    if (props.modelValue) {
        animateText()
    }
})
</script>

<style scoped>
.caret::after {
    content: '';
    width: 4px;
    height: 0.9em;
    margin-left: 1px;
    margin-bottom: -2px;
    background: black;
    display: inline-block;
    animation: caret 1s steps(1) infinite;
}

/*
@keyframes caret {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
*/
</style>
