// /resources/app/router/index.ts
import {
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
} from 'vue-router'
import { useAuthStore } from '@/shared/stores/auth'
import { useLogger } from 'vue-logger-plugin'

const checkAuth = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const logger = useLogger()
    const auth = useAuthStore()

    if (!auth.isLoggedIn) {
        logger.info('User is not logged in')
        return next({ name: 'login' })
    }
    return next()
}

const checkGuest = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const logger = useLogger()
    const auth = useAuthStore()
    if (auth.isLoggedIn) {
        logger.info('User is already logged in')
        return next({
            name: 'onboarding',
            query: { ...to.query },
        })
    }
    return next()
}

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/onboard',
            name: 'onboarding',
            beforeEnter: [checkAuth],
            component: () => import('@/app/views/Onboarding.vue'),
            meta: {
                showNav: false,
                showBanner: false,
            },
        },
        {
            path: '/elements/:path(.*)',
            name: 'elements.index',
            component: () =>
                import('@/shared/components/dynamic/DynamicListView.vue'),
            beforeEnter: [checkAuth],
            props: (route) => ({
                path: route.params.path,
                developmentDate: route.query.development_date,
            }),
            meta: {
                transition: 'none',
                title: 'Story Elements',
            },
        },
        {
            path: '/',
            beforeEnter: [checkAuth],
            component: () => import('@/app/layouts/AppLayout.vue'),
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: () => import('@/app/views/HomeView.vue'),
                    beforeEnter: [checkAuth],
                    meta: {
                        transition: 'none',
                        title: 'Home',
                    },
                },
                // {
                //     path: '/profile',
                //     name: 'profile',
                //     component: () => import('@/app/views/UserProfile.vue'),
                //     meta: {
                //         transition: 'none',
                //         back: 'home',
                //         title: 'Profile',
                //     },
                //     props: (route) => ({ tab: route.query.tab }),
                // },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () =>
                        import('@/app/views/DynamicProfileView.vue'),
                    meta: {
                        transition: 'none',
                        back: 'home',
                        title: 'Profile',
                    },
                    props: true,
                },
                {
                    path: 'conversation/user-achievements/:userAchievementId',
                    name: 'achievement-dynamic-conversation',
                    component: () =>
                        import('@/app/views/DynamicEngineView.vue'),
                    props: true,
                    meta: {
                        requiresAuth: true,
                        development_insights: true,
                    },
                },
                {
                    path: 'projects/:project_slug',
                    props: true,
                    children: [
                        {
                            path: '',
                            component: () =>
                                import('@/app/layouts/ProjectLayout.vue'),
                            props: true,
                            children: [
                                {
                                    path: '',
                                    name: 'project',
                                    component: () =>
                                        import(
                                            '@/app/views/DynamicProjectView.vue'
                                        ),
                                    props: true,
                                    meta: {
                                        transition: 'none',
                                        title: 'Project Detail Page',
                                        model_plural: 'projects',
                                        side_menu: 'develop',
                                        back: 'home',
                                    },
                                },
                                {
                                    path: 'outline',
                                    name: 'project-outline',
                                    component: () =>
                                        import(
                                            '@/app/views/ProjectOutlineView.vue'
                                        ),
                                    props: true,
                                    meta: {
                                        transition: 'none',
                                        title: 'Project Outline',
                                        back: 'project',
                                        side_menu: 'develop',
                                    },
                                },
                                {
                                    path: 'moodboard',
                                    name: 'project-moodboard',
                                    component: () =>
                                        import(
                                            '@/app/views/ProjectMoodboardView.vue'
                                        ),
                                    props: true,
                                    meta: {
                                        transition: 'none',
                                        title: 'Project Outline',
                                        back: 'project',
                                        side_menu: 'develop',
                                    },
                                },
                                {
                                    path: 'notes',
                                    name: 'project-notes',
                                    component: () =>
                                        import(
                                            '@/app/views/ProjectNotesView.vue'
                                        ),
                                    props: true,
                                    meta: {
                                        transition: 'none',
                                        title: 'Project Outline',
                                        back: 'project',
                                        side_menu: 'develop',
                                    },
                                },
                                {
                                    path: ':model_plural',
                                    name: 'project-elements',
                                    component: () =>
                                        import(
                                            '@/app/views/DynamicProjectListView.vue'
                                        ),
                                    props: true,
                                    meta: {
                                        transition: 'none',
                                        title: 'Project Element',
                                        back: 'project',
                                        side_menu: 'develop',
                                    },
                                },
                                {
                                    path: ':model_plural/:model_instance_slug',
                                    name: 'dynamic-page',
                                    component: () =>
                                        import(
                                            '@/app/views/DynamicProjectView.vue'
                                        ),
                                    props: true,
                                    meta: {
                                        transition: 'none',
                                        title: 'Project Element Detail Page',
                                        back: 'project-elements',
                                        side_menu: 'develop',
                                    },
                                },
                                {
                                    path: ':model_plural/:model_instance_slug/:related_model_plural',
                                    name: 'narrative-element-related-elements',
                                    component: () =>
                                        import(
                                            '@/app/views/DynamicProjectListView.vue'
                                        ),
                                    props: true,
                                    meta: {
                                        transition: 'none',
                                        title: 'Related Elements',
                                        back: 'dynamic-page',
                                        side_menu: 'develop',
                                    },
                                },
                            ],
                        },
                        {
                            path: 'notes/:note_id',
                            name: 'project-note-edit',
                            component: () =>
                                import('@/app/views/NoteEditView.vue'),
                            props: true,
                            meta: {
                                hide_project_header: true,
                            },
                        },
                        {
                            path: 'write',
                            name: 'story-write',
                            component: () =>
                                import('@/app/views/StoryWriteView.vue'),
                            props: true,
                        },
                        {
                            path: 'conversation',
                            name: 'conversation',
                            component: () =>
                                import('@/app/views/DynamicEngineView.vue'),
                            props: (route) => ({
                                projectSlug:
                                    route.params.project_slug ??
                                    route.query.projectSlug,
                                narrativeElementSlug:
                                    route.query.narrative_element_slug,
                                userAchievementId:
                                    route.query.user_achievement_id,
                                achievementSlug: route.query.achievement_slug,
                            }),
                            meta: {
                                hide_sidebar: true,
                                requiresAuth: true,
                                development_insights: true,
                            },
                        },
                        {
                            path: 'conversation/narrative-elements/:narrativeElementSlug',
                            name: 'narrative-element-dynamic-conversation',
                            component: () =>
                                import('@/app/views/DynamicEngineView.vue'),
                            props: true,
                            meta: {
                                hide_sidebar: true,
                                requiresAuth: true,
                                development_insights: true,
                            },
                        },
                    ],
                },
                {
                    path: '/conversation/:endpoint?/:element?',
                    name: 'dynamic-conversation',
                    component: () =>
                        import('@/app/views/DynamicEngineView.vue'),
                    props: {
                        isConversationActive: true,
                    },
                    meta: {
                        development_insights: true,
                    },
                },
                {
                    path: '/profile/achievements/:achievement/develop',
                    name: 'user-achievement-develop',
                    component: () =>
                        import('@/app/views/story/AchievementDevelopment.vue'),
                    meta: {
                        transition: 'slide',
                        title: 'Achievement',
                        back: 'profile',
                    },
                    props: {
                        endpoint: 'users',
                        element: 'achievement',
                    },
                },
                {
                    path: '/profile/develop',
                    name: 'profile-develop',
                    component: () =>
                        import('@/app/views/ConversationEngineView.vue'),
                    meta: {
                        transition: 'slide',
                        title: 'Achievement',
                        back: 'profile',
                        development_insights: true,
                    },
                    props: {
                        initialEndpoint: 'writer',
                    },
                },
                {
                    path: '/profile/edit',
                    name: 'ProfileEdit',
                    component: () =>
                        import(
                            '@/app/views/user/profile/UserEditProfileView.vue'
                        ),
                    meta: {
                        transition: 'none',
                        back: 'home',
                        title: 'Edit Profile',
                    },
                },
                {
                    path: '/summary/:summary',
                    name: 'summary-develop',
                    component: () =>
                        import('@/app/views/ConversationEngineView.vue'),
                    meta: {
                        transition: 'slide',
                        title: 'Summary',
                        back: 'home',
                        development_insights: true,
                    },
                    props: {
                        initialEndpoint: 'summaries',
                        identifierRouteKey: 'summary',
                    },
                },
                {
                    path: 'stories',
                    children: [
                        {
                            path: '',
                            name: 'stories',
                            component: () =>
                                import('@/app/views/StoriesListPage.vue'),
                            meta: {
                                transition: 'slide',
                                title: 'Stories',
                                back: 'home',
                            },
                        },
                        {
                            path: 'new',
                            name: 'new-story',
                            component: () =>
                                import(
                                    '@/app/views/ConversationEngineView.vue'
                                ),
                            meta: {
                                transition: 'slide',
                                title: 'New Story',
                                back: 'stories',
                                development_insights: true,
                            },
                            props: {
                                initialEndpoint: 'stories',
                            },
                        },
                        {
                            path: ':story',
                            children: [
                                {
                                    path: '',
                                    name: 'story',
                                    component: () =>
                                        import('@/app/views/ProjectView.vue'),
                                    meta: {
                                        transition: 'slide',
                                        title: 'Story',
                                        back: 'home',
                                    },
                                },
                                {
                                    path: 'develop',
                                    name: 'story-develop',
                                    component: () =>
                                        import(
                                            '@/app/views/ConversationEngineView.vue'
                                        ),
                                    meta: {
                                        transition: 'slide',
                                        title: 'Story Development',
                                        back: 'story',
                                        development_insights: true,
                                    },
                                    props: {
                                        initialEndpoint: 'stories',
                                        identifierRouteKey: 'story',
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            path: '/auth',
            component: () => import('../views/AuthView.vue'),
            beforeEnter: checkGuest,
            redirect: { name: 'login' },
            children: [
                {
                    path: 'login',
                    name: 'login',
                    component: () => import('@/app/views/LoginView.vue'),
                    meta: {
                        transition: 'none',
                        title: 'Login',
                        showNav: false,
                        showBanner: false,
                    },
                    props: (route) => ({ query: route.query }),
                },
                {
                    path: 'magic-link',
                    name: 'magic-link',
                    component: () => import('@/app/views/MagicLinkView.vue'),
                    meta: {
                        transition: 'none',
                        title: 'Magic Link',
                        showNav: false,
                        showBanner: false,
                    },
                },
            ],
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            redirect: { name: 'home' },
        },
    ],
})

router.beforeEach((to, from, next) => {
    if (
        to.query?.referral ||
        to.query?.referral_code ||
        from.query?.referral ||
        from.query?.referral_code
    ) {
        localStorage.setItem(
            'referral',
            (to.query.referral ||
                to.query.referral_code ||
                from.query.referral ||
                from.query.referral_code) as string
        )
    }
    next()
})

router.beforeEach((to, from, next) => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
    next()
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()
    if (to.meta.requiresAuth && !authStore.isLoggedIn) {
        next({ name: 'login' })
    } else {
        next()
    }
})

router.onError((error) => {
    console.error('Router error:', error)
})

export default router
