<template>
    <NodeToolbar
        :is-visible="data.toolbarVisible"
        :node-id="id"
        :position="Position.Right"
    >
        <template
            v-for="button in buttons"
            :key="button.action"
        >
            <button
                class="flex w-full items-center justify-start gap-1 text-xs"
                @click="emit(button.action, button.props ?? null)"
            >
                <edit-icon
                    v-if="button.icon === 'edit'"
                    class="h-3 w-3"
                />
                <plus-icon
                    v-else-if="button.icon === 'plus'"
                    class="h-3 w-3"
                />
                <link-icon
                    v-else-if="button.icon === 'link'"
                    class="h-3 w-3"
                />
                {{ button.label }}
            </button>
        </template>
    </NodeToolbar>

    <div>
        {{ data.label }}
    </div>

    <Handle
        :position="Position.Left"
        type="target"
    />
    <Handle
        :position="Position.Right"
        type="source"
    />
</template>

<script lang="ts" setup>
import { NodeToolbar } from '@vue-flow/node-toolbar'
import { Handle, Position } from '@vue-flow/core'
import PlusIcon from '@/shared/components/icons/PlusIcon.vue'
import LinkIcon from '@/shared/components/icons/LinkIcon.vue'
import EditIcon from '@/shared/components/icons/EditIcon.vue'
import { computed } from 'vue'
import { ModelType } from '@/shared/types/modelTypes'

const emit = defineEmits(['create', 'link', 'edit', 'load', 'clean'])

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    data: {
        type: Object,
        required: true,
    },
})

const buttons = computed(() => {
    const arr = [
        { label: 'Load children', icon: 'load', action: 'load' },
        { label: 'Cleanup view', icon: 'clean', action: 'clean' },
        { label: 'Edit', icon: 'edit', action: 'edit' },
        { label: 'Delete', icon: 'delete', action: 'delete' },
    ] as {
        label: string
        icon: string
        action: string
        props?: {
            type: string
        }
    }[]

    if (['ImageType', 'Summary'].includes(props.data.type)) {
        arr.splice(arr.length - 1, 0, {
            label: 'Attach data point',
            icon: 'link',
            action: 'link',
            props: { type: 'DataPoint' },
        })
        if (props.data.type === 'ImageType') {
            arr.splice(arr.length - 1, 0, {
                label: 'Attach summary',
                icon: 'link',
                action: 'link',
                props: { type: 'Summary' },
            })
        } else {
            arr.splice(arr.length - 1, 0, {
                label: 'Attach achievement',
                icon: 'link',
                action: 'link',
                props: { type: 'Achievement' },
            })
        }
    }

    if (!['ImageType', 'Summary'].includes(props.data.type)) {
        arr.splice(arr.length - 1, 0, {
            label: 'Create child',
            icon: 'plus',
            action: 'create',
        })
        arr.splice(arr.length - 1, 0, {
            label: 'Attach child',
            icon: 'link',
            action: 'link',
        })
    }

    return arr
})
</script>

<style scoped></style>
