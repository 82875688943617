import { defineStore } from 'pinia'
import {
    getProject,
    getProjectElements,
    getProjects,
} from '@/shared/utils/endpoints'
import { useQuery } from '@tanstack/vue-query'
import { computed, Ref, unref } from 'vue'

async function fetchProject(slug: string | undefined, isTest: boolean = false) {
    if (!slug) return null
    return await getProject(slug, isTest)
}

async function fetchMostRecentProject() {
    try {
        const projects = await getProjects()
        if (projects.length > 0) {
            const mostRecent = projects[0] // Assuming projects are sorted by recency
            return mostRecent
        }
        return null
    } catch (error) {
        console.error('Error fetching most recent project:', error)
        return null
    }
}

async function fetchProjectElements(
    slug: string | undefined,
    categoryModelPlural: string | null
) {
    if (!slug || !categoryModelPlural) return null
    return await getProjectElements(slug, categoryModelPlural)
}

export const useProjectStore = defineStore('project', () => {
    // You can add any store-specific logic here if needed
})

export function useProject(projectSlug: string | Ref<string | undefined>) {
    const unwrappedSlug = computed(() => unref(projectSlug))
    return useQuery({
        queryKey: ['project', unwrappedSlug],
        queryFn: async () => await fetchProject(unwrappedSlug.value),
        enabled: computed(() => !!unwrappedSlug.value),
    })
}

export function useProjectElements(
    projectSlug: string | Ref<string | undefined>,
    categoryModelPlural: string | Ref<string | null>
) {
    const unwrappedSlug = computed(() => unref(projectSlug))
    const unwrappedCategory = computed(() => unref(categoryModelPlural))
    return useQuery({
        queryKey: ['projectElements', unwrappedSlug, unwrappedCategory],
        queryFn: () =>
            fetchProjectElements(unwrappedSlug.value, unwrappedCategory.value),
        enabled: computed(
            () => !!unwrappedSlug.value && !!unwrappedCategory.value
        ),
    })
}
