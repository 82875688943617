<template>
    <modal-backdrop>
        <modal-layout class="h-full max-h-80 w-full max-w-prose">
            <modal-header class="p-0">
                <input
                    v-model="search"
                    class="w-full p-3"
                    :placeholder="placeholder"
                    type="text"
                />
            </modal-header>
            <modal-content class="px-0">
                <ul class="flex flex-col gap-2">
                    <li
                        v-for="item in items"
                        :key="item.id"
                        class="cursor-pointer hover:bg-parchment-100 px-4 duration-75"
                        @click="emit('select', item)"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </modal-content>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import { useQuery } from '@tanstack/vue-query'
import api from '@/shared/utils/api'
import { ref } from 'vue'
import { watchDebounced } from '@vueuse/core'

const emit = defineEmits(['select'])

const props = defineProps({
    url: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: 'Search...',
    },
    filters: {
        type: Object,
        default: () => ({}),
    },
})

const _search = ref('')
const search = ref('')

watchDebounced(
    search,
    () => {
        _search.value = search.value
    },
    {
        debounce: 300,
        maxWait: 1000,
    }
)

const { data: items } = useQuery({
    queryKey: [
        'searchSelectModal',
        props.url,
        {
            search: _search,
            filters: props.filters,
        },
    ],
    queryFn: async () => {
        return (
            await api.get(props.url, {
                params: {
                    ...props.filters,
                    search: _search.value,
                },
            })
        ).data
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
})
</script>

<style scoped></style>
