<template>
    <svg
        fill="none"
        viewBox="0 0 21 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clip-rule="evenodd"
            d="M7.5 4C7.5 3.20435 7.81607 2.44129 8.37868 1.87868C8.94129 1.31607 9.70435 1 10.5 1C11.2956 1 12.0587 1.31607 12.6213 1.87868C13.1839 2.44129 13.5 3.20435 13.5 4V8C13.5 8.79565 13.1839 9.55871 12.6213 10.1213C12.0587 10.6839 11.2956 11 10.5 11C9.70435 11 8.94129 10.6839 8.37868 10.1213C7.81607 9.55871 7.5 8.79565 7.5 8V4ZM11.5 14.93C13.1662 14.6893 14.6899 13.8562 15.7918 12.5835C16.8938 11.3107 17.5002 9.68351 17.5 8C17.5 7.73478 17.3946 7.48043 17.2071 7.29289C17.0196 7.10536 16.7652 7 16.5 7C16.2348 7 15.9804 7.10536 15.7929 7.29289C15.6054 7.48043 15.5 7.73478 15.5 8C15.5 9.32608 14.9732 10.5979 14.0355 11.5355C13.0979 12.4732 11.8261 13 10.5 13C9.17392 13 7.90215 12.4732 6.96447 11.5355C6.02678 10.5979 5.5 9.32608 5.5 8C5.5 7.73478 5.39464 7.48043 5.20711 7.29289C5.01957 7.10536 4.76522 7 4.5 7C4.23478 7 3.98043 7.10536 3.79289 7.29289C3.60536 7.48043 3.5 7.73478 3.5 8C3.49978 9.68351 4.1062 11.3107 5.20815 12.5835C6.31011 13.8562 7.83379 14.6893 9.5 14.93V17H6.5C6.23478 17 5.98043 17.1054 5.79289 17.2929C5.60536 17.4804 5.5 17.7348 5.5 18C5.5 18.2652 5.60536 18.5196 5.79289 18.7071C5.98043 18.8946 6.23478 19 6.5 19H14.5C14.7652 19 15.0196 18.8946 15.2071 18.7071C15.3946 18.5196 15.5 18.2652 15.5 18C15.5 17.7348 15.3946 17.4804 15.2071 17.2929C15.0196 17.1054 14.7652 17 14.5 17H11.5V14.93Z"
            fill="white"
            fill-rule="evenodd"
        />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
