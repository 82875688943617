export const modelTypeKeys = [
    'App\\Models\\Project\\Story',
    'App\\Models\\Project\\Project',
    'App\\Models\\Achievement',
    'App\\Models\\Narrative\\Character',
    'App\\Models\\Narrative\\Setting',
    'App\\Models\\Narrative\\Plot',
    'App\\Models\\Narrative\\Theme',
    'App\\Models\\Narrative\\Sequence',
    'App\\Models\\Meta\\ReferenceEvent',
    'App\\Models\\Meta\\ReferenceFigure',
    'App\\Models\\Meta\\ReferenceCharacter',
    'App\\Models\\Project\\Season',
    'App\\Models\\Narrative\\Scene',
    'App\\Models\\Narrative\\Moment',
    'App\\Models\\User',
    'App\\Models\\Narrative\\WorldRule',
    'App\\Models\\Narrative\\StoryObject',
    'App\\Models\\Narrative\\Arc',
    'App\\Models\\Narrative\\ArcSegment',
]

export type ModelType = typeof modelTypeKeys[number]
