<template>
    <modal-backdrop v-slot="{ close }">
        <modal-layout class="w-full max-w-prose">
            <modal-header>Category</modal-header>
            <modal-content>
                <AutoForm
                    :field-config="{
                        is_narrative_element: {
                            component: 'switch',
                            label: 'Is narrative element?',
                            description:
                                'Defines if this category should be extracted from user input.',
                        },
                        purpose: {
                            component: 'textarea',
                        },
                        definition: {
                            component: 'textarea',
                        },
                        creation_confidence_absolute: {
                            component: 'textarea',
                        },
                        creation_confidence_high: {
                            component: 'textarea',
                        },
                        creation_confidence_low: {
                            component: 'textarea',
                        },
                        creation_confidence_none: {
                            component: 'textarea',
                        },
                    }"
                    :form="form"
                    :schema="formSchema"
                    class="flex flex-col gap-2"
                    @submit="handleSubmit"
                />
            </modal-content>
            <modal-footer class="justify-end gap-2">
                <v-button @click="close">Cancel</v-button>
                <v-button
                    variant="light"
                    @click="
                        () => handleSubmit().then((valid) => valid && close())
                    "
                >
                    Save
                </v-button>
            </modal-footer>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import ModalFooter from '@/shared/components/modals/layouts/ModalFooter.vue'
import VButton from '@/shared/components/VButton.vue'
import { AutoForm } from '@/shared/components/ui/auto-form'
import { z } from 'zod'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { onMounted, ref } from 'vue'
import api from '@/shared/utils/api'
import useModal from '@/shared/composables/useModal'

const emit = defineEmits(['create', 'saved'])

const props = defineProps({
    categoryId: {
        type: Number,
        default: null,
    },
    prefill: {
        type: Object,
        default: () => undefined,
    },
})

const formSchema = z.object({
    name: z.string(),
    display_name: z.string(),
    model_plural: z.string(),
    model_type: z.string(),
    order: z.number().int().min(0).default(0),
    icon_component: z.string().nullable(),
    is_narrative_element: z.boolean().default(false),
    type: z.string().nullable(),
    definition: z.string().nullable(),
    purpose: z.string().nullable(),
    creation_confidence_absolute: z.string().nullable(),
    creation_confidence_high: z.string().nullable(),
    creation_confidence_low: z.string().nullable(),
    creation_confidence_none: z.string().nullable(),
    relatable_types: z.array(z.string()).nullable(),
})

const form = useForm({
    validationSchema: toTypedSchema(formSchema),
})

async function handleSubmit() {
    const valid = await form.validate()
    if (!valid.valid) return false

    if (props.categoryId) {
        await api.put(
            `/api/admin/v2/data-points/${props.categoryId}`,
            form.values
        )
    } else {
        const { data } = await api.post(
            '/api/admin/v2/data-points',
            form.values
        )
        emit('create', data)
    }
    emit('saved')
    return true
}

onMounted(async () => {
    if (props.categoryId) {
        const { data } = await api.get(
            `/api/admin/v2/categories/${props.categoryId}`
        )
        form.setValues(data)
    } else if (props.prefill) {
        form.setValues(props.prefill, false)
    }

    // await fetchAchievements()
})
</script>

<style scoped></style>
