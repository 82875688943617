<template>
<!--    <svg-->
<!--        xmlns="http://www.w3.org/2000/svg"-->
<!--        width="41"-->
<!--        height="40"-->
<!--        viewBox="0 0 41 40"-->
<!--        fill="none"-->
<!--    >-->
<!--        <path-->
<!--            fill-rule="evenodd"-->
<!--            clip-rule="evenodd"-->
<!--            d="M14.5 8C14.5 6.4087 15.1321 4.88258 16.2574 3.75736C17.3826 2.63214 18.9087 2 20.5 2C22.0913 2 23.6174 2.63214 24.7426 3.75736C25.8679 4.88258 26.5 6.4087 26.5 8V16C26.5 17.5913 25.8679 19.1174 24.7426 20.2426C23.6174 21.3679 22.0913 22 20.5 22C18.9087 22 17.3826 21.3679 16.2574 20.2426C15.1321 19.1174 14.5 17.5913 14.5 16V8ZM22.5 29.86C25.8324 29.3786 28.8798 27.7125 31.0837 25.167C33.2876 22.6215 34.5004 19.367 34.5 16C34.5 15.4696 34.2893 14.9609 33.9142 14.5858C33.5391 14.2107 33.0304 14 32.5 14C31.9696 14 31.4609 14.2107 31.0858 14.5858C30.7107 14.9609 30.5 15.4696 30.5 16C30.5 18.6522 29.4464 21.1957 27.5711 23.0711C25.6957 24.9464 23.1522 26 20.5 26C17.8478 26 15.3043 24.9464 13.4289 23.0711C11.5536 21.1957 10.5 18.6522 10.5 16C10.5 15.4696 10.2893 14.9609 9.91421 14.5858C9.53914 14.2107 9.03043 14 8.5 14C7.96957 14 7.46086 14.2107 7.08579 14.5858C6.71071 14.9609 6.5 15.4696 6.5 16C6.49955 19.367 7.7124 22.6215 9.91631 25.167C12.1202 27.7125 15.1676 29.3786 18.5 29.86V34H12.5C11.9696 34 11.4609 34.2107 11.0858 34.5858C10.7107 34.9609 10.5 35.4696 10.5 36C10.5 36.5304 10.7107 37.0391 11.0858 37.4142C11.4609 37.7893 11.9696 38 12.5 38H28.5C29.0304 38 29.5391 37.7893 29.9142 37.4142C30.2893 37.0391 30.5 36.5304 30.5 36C30.5 35.4696 30.2893 34.9609 29.9142 34.5858C29.5391 34.2107 29.0304 34 28.5 34H22.5V29.86Z"-->
<!--            fill="currentColor"-->
<!--        />-->
<!--    </svg>-->
    <svg width="54px" height="80px" viewBox="0 0 54 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>microphone</title>
            <g id="Page-1" stroke="none" stroke-width="2" fill="none" fill-rule="evenodd">
                <path
                    d="M51.66,38.039 C52.48812,38.039 53.16,38.71088 53.16,39.539 C53.16,53.457 42.23,64.86675 28.5,65.648 L28.5,65.648 L28.5,78.488 C28.5,79.31612 27.82812,79.988 27,79.988 C26.17188,79.988 25.5,79.31612 25.5,78.488 L25.5,78.488 L25.5,65.648 C11.77,64.867 0.84,53.461 0.84,39.539 C0.84,38.71088 1.51188,38.039 2.34,38.039 C3.16812,38.039 3.84,38.71088 3.84,39.539 C3.84,52.308 14.231,62.699 27,62.699 C39.769,62.699 50.16,52.309 50.16,39.539 C50.16,38.71088 50.83188,38.039 51.66,38.039 Z M27,7.10542736e-15 C36.3129,7.10542736e-15 43.891,7.5824 43.891,16.891 L43.891,16.891 L43.891,39.551 C43.891,48.8639 36.3086,56.442 27,56.442 C17.6871,56.442 10.109,48.8596 10.109,39.551 L10.109,39.551 L10.109,16.891 C10.109,7.5781 17.6914,7.10542736e-15 27,7.10542736e-15 Z M26.999,3 C19.3385,3 13.108,9.2308 13.108,16.891 L13.108,16.891 L13.108,39.551 C13.108,47.2115 19.3385,53.442 26.999,53.442 C34.6595,53.442 40.89,47.2112 40.89,39.551 L40.89,39.551 L40.89,16.891 C40.89,9.2305 34.6595,3 26.999,3 Z"
                    id="microphone"
                    fill="currentColor"
                    fill-rule="nonzero"
                />
            </g>
    </svg>
</template>

<script setup>
</script>
