<template>
    <transition
        name="v-modal-backdrop"
        @enter="slotOpen = true"
        @after-leave="closeHandle"
    >
        <dialog
            v-if="backdropOpen"
            :class="
                cn(
                    'fixed inset-0 z-50 flex h-full w-full transform-gpu items-center justify-center bg-black/50 py-2',
                    $props.class
                )
            "
            @mousedown.self="!$props.disableBackdropClose && (slotOpen = false)"
        >
            <transition
                class="transform-gpu"
                name="v-modal-full"
                @leave="backdropOpen = false"
            >
                <slot
                    v-if="slotOpen"
                    :close="() => (slotOpen = false)"
                />
            </transition>
        </dialog>
    </transition>
</template>

<script lang="ts" setup>
import { inject, onMounted, ref } from 'vue'
import { ClassValue } from 'clsx'
import { cn } from '@/shared/lib/utils'

defineProps<{
    class?: ClassValue
    disableBackdropClose?: boolean
}>()

const backdropOpen = ref(false)
const slotOpen = ref(false)

const closeHandle = inject('close-current-modal') as () => void

onMounted(() => {
    backdropOpen.value = true
})
</script>

<style scoped></style>
