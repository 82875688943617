// src/shared/composables/useFacebookPixel.ts

import { ref } from 'vue'

declare global {
    interface Window {
        fbq: any;
        _fbq: any;
    }
}

export function useFacebookPixel(pixelId: string | undefined) {
    const fbqLoaded = ref(false)
    const fbqQueue: any[][] = []

    function safeFbq(...args: any[]) {
        if (fbqLoaded.value && typeof window.fbq !== 'undefined') {
            console.log('Calling fbq with args:', args)
            window.fbq.apply(null, args)
        } else {
            console.log('Queueing fbq call:', args)
            fbqQueue.push(args)
        }
    }

    function loadFbPixelScript() {
        return new Promise<void>((resolve, reject) => {
            if (document.getElementById('facebook-pixel')) {
                fbqLoaded.value = true
                resolve()
                return
            }

            // Initialize fbq as a dummy function
            window.fbq = window._fbq = function() {
                (window.fbq as any).callMethod ?
                    (window.fbq as any).callMethod.apply(window.fbq, arguments) :
                    (window.fbq as any).queue.push(arguments)
            }
            ;(window.fbq as any).push = window.fbq
            ;(window.fbq as any).loaded = true
            ;(window.fbq as any).version = '2.0'
            ;(window.fbq as any).queue = []

            const script = document.createElement('script')
            script.id = 'facebook-pixel'
            script.src = 'https://connect.facebook.net/en_US/fbevents.js'
            script.async = true
            script.onload = () => {
                fbqLoaded.value = true
                // Process queued calls
                while (fbqQueue.length > 0) {
                    const args = fbqQueue.shift()
                    if (args) window.fbq.apply(null, args)
                }
                resolve()
            }
            script.onerror = () => reject(new Error('Failed to load Facebook Pixel script'))
            document.head.appendChild(script)
        })
    }

    async function initialize() {
        if (!pixelId) {
            console.warn('Facebook Pixel ID is not provided. Skipping initialization.')
            return
        }

        try {
            await loadFbPixelScript()
            console.log('Facebook Pixel script loaded successfully')
            safeFbq('init', pixelId)
            safeFbq('track', 'PageView')
        } catch (error) {
            console.error('Failed to initialize Facebook Pixel:', error)
        }
    }

    function trackCustomEvent(eventName: string, eventParams?: object) {
        if (!pixelId) {
            console.warn('Facebook Pixel ID is not set. Skipping event tracking.')
            return
        }
        safeFbq('trackCustom', eventName, eventParams)
    }

    return {
        initialize,
        trackCustomEvent,
        safeFbq
    }
}
