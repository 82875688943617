<template>
    <div>
        <div class="flex justify-between">
            <h3 class="text-md">Achievement Data Points</h3>
            <Button
                class="flex gap-2"
                @click="handleClick()"
            >
                <PlusIcon class="h-4" />
                Add Data Point
            </Button>
        </div>
        <table class="table w-full table-auto divide-y divide-parchment-300">
            <thead>
                <tr>
                    <th
                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-parchment-900 sm:pl-0"
                    >
                        ID
                    </th>
                    <th
                        class="px-3 py-3.5 text-left text-sm font-semibold text-parchment-900"
                    >
                        Name
                    </th>
<!--                    <th-->
<!--                        class="px-3 py-3.5 text-left text-sm font-semibold text-parchment-900"-->
<!--                    >-->
<!--                        Created-->
<!--                    </th>-->
                    <th
                        class="px-3 py-3.5 text-left text-sm font-semibold text-parchment-900"
                    >
                        Updated
                    </th>
                </tr>
            </thead>
            <tbody class="divide-y divide-parchment-200">
                <tr
                    v-for="dataPoint in dataPoints"
                    :key="dataPoint.id"
                    class="duration-75 hover:bg-parchment-100"
                    @click="handleClick(dataPoint)"
                >
                    <td
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-parchment-900 sm:pl-0"
                    >
                        {{ dataPoint.id }}
                    </td>
                    <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                        {{ dataPoint.name }}
                    </td>
<!--                    <td-->
<!--                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"-->
<!--                    >-->
<!--                        {{-->
<!--                            DateTime.fromISO(-->
<!--                                dataPoint.created_at-->
<!--                            ).toLocaleString(DateTime.DATETIME_MED)-->
<!--                        }}-->
<!--                    </td>-->
                    <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                        {{
                            DateTime.fromISO(
                                dataPoint.updated_at
                            ).toLocaleString(DateTime.DATETIME_MED)
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts" setup>
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import api from '@/shared/utils/api'
import { DateTime } from 'luxon'
import AdminDataPointModal from '@/shared/components/modals/admin/AdminDataPointModal.vue'
import useModal from '@/shared/composables/useModal'
import { Button } from '@/shared/components/ui/button'
import PlusIcon from '@/shared/components/icons/PlusIcon.vue'

const props = defineProps({
    achievementId: {
        type: Number,
        required: true,
    },
})

const modal = useModal()

const queryClient = useQueryClient()
const { data: dataPoints, isLoading } = useQuery({
    queryKey: ['achievementDataPoints', props.achievementId],
    queryFn: async () => {
        return (
            await api.get(
                `/api/admin/v2/achievements/${props.achievementId}/data-points`
            )
        ).data
    },
})

function handleClick(dataPoint = null) {
    modal.show(
        AdminDataPointModal,
        {
            dataPointId: dataPoint?.id,
        },
        {
            onClose: () => {
                queryClient.invalidateQueries({
                    queryKey: ['achievementDataPoints', props.achievementId],
                })
            },
        }
    )
}
</script>

<style scoped></style>
