<template>
    <button
        :class="{
            'rounded-full bg-parchment-700 px-4 py-2 text-base font-medium text-white hover:bg-parchment-900':
                variant === 'dark',
            'rounded-full bg-red-500 px-4 py-2 text-base font-medium text-white':
                variant === 'danger',
            'rounded-full bg-white px-4 py-2 text-base font-medium text-parchment-900 hover:bg-parchment-100 border-2 border-parchment-300':
                variant === 'light',
            '!text-transparent': loading || _loading,
        }"
        class="relative select-none transition-colors duration-100"
        @click="handleClick"
    >
        <transition
            enter-active-class="duration-100"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="loading || _loading"
                class="absolute left-0 top-0 flex h-full w-full items-center justify-center p-1"
            >
                <app-loader class="light h-full text-white" />
            </div>
        </transition>
        <slot />
    </button>
</template>

<script lang="ts" setup>
import AppLoader from '@/shared/components/AppLoader.vue'
import { ref } from 'vue'

const emit = defineEmits<{
    click: [(promise: () => Promise<void>) => void]
}>()

defineProps({
    variant: {
        type: String,
        default: 'dark',
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const _loading = ref(false)

function handleClick() {
    emit('click', (promise: () => Promise<void>) => {
        _loading.value = true
        promise?.()?.finally(() => {
            _loading.value = false
        })
    })
}
</script>

<style scoped></style>
