<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0244 3.17567C10.1369 3.28819 10.2001 3.44077 10.2001 3.59987C10.2001 3.75897 10.1369 3.91156 10.0244 4.02407L5.22444 8.82407C5.11193 8.93655 4.95934 8.99974 4.80024 8.99974C4.64115 8.99974 4.48856 8.93655 4.37604 8.82407L1.97604 6.42407C1.86675 6.31091 1.80627 6.15935 1.80764 6.00203C1.80901 5.84471 1.87211 5.69423 1.98335 5.58298C2.0946 5.47174 2.24509 5.40863 2.4024 5.40727C2.55972 5.4059 2.71128 5.46638 2.82444 5.57567L4.80024 7.55147L9.17604 3.17567C9.28856 3.06319 9.44115 3 9.60024 3C9.75934 3 9.91193 3.06319 10.0244 3.17567Z"
            fill="currentColor"
        />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
