<script lang="ts" setup>
import { buttonVariants } from '@/shared/components/ui/button'
import { cn } from '@/shared/lib/utils'
import { CalendarCellTrigger, type CalendarCellTriggerProps, useForwardProps } from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'

const props = defineProps<CalendarCellTriggerProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <CalendarCellTrigger
    :class="cn(
      buttonVariants({ variant: 'ghost' }),
      'h-8 w-8 p-0 font-normal',
      '[&[data-today]:not([data-selected])]:bg-parchment-100 [&[data-today]:not([data-selected])]:text-parchment-900 dark:[&[data-today]:not([data-selected])]:bg-parchment-800 dark:[&[data-today]:not([data-selected])]:text-parchment-50',
      // Selected
      'data-[selected]:bg-parchment-900 data-[selected]:text-parchment-50 data-[selected]:opacity-100 data-[selected]:hover:bg-parchment-900 data-[selected]:hover:text-parchment-50 data-[selected]:focus:bg-parchment-900 data-[selected]:focus:text-parchment-50 dark:data-[selected]:bg-parchment-50 dark:data-[selected]:text-parchment-900 dark:data-[selected]:hover:bg-parchment-50 dark:data-[selected]:hover:text-parchment-900 dark:data-[selected]:focus:bg-parchment-50 dark:data-[selected]:focus:text-parchment-900',
      // Disabled
      'data-[disabled]:text-parchment-500 data-[disabled]:opacity-50 dark:data-[disabled]:text-parchment-400',
      // Unavailable
      'data-[unavailable]:text-parchment-50 data-[unavailable]:line-through dark:data-[unavailable]:text-parchment-50',
      // Outside months
      'data-[outside-view]:text-parchment-500 data-[outside-view]:opacity-50 [&[data-outside-view][data-selected]]:bg-parchment-100/50 [&[data-outside-view][data-selected]]:text-parchment-500 [&[data-outside-view][data-selected]]:opacity-30 dark:data-[outside-view]:text-parchment-400 dark:[&[data-outside-view][data-selected]]:bg-parchment-800/50 dark:[&[data-outside-view][data-selected]]:text-parchment-400',
      props.class,
    )"
    v-bind="forwardedProps"
  >
    <slot />
  </CalendarCellTrigger>
</template>
