import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { ComputedRef, MaybeRefOrGetter } from 'vue'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export type ConditionalProps<T extends string> =
    | T
    | Array<T>
    | Record<T, MaybeRefOrGetter<Boolean> | ComputedRef<Boolean>>
    | null
