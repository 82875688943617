<template></template>

<script lang="ts" setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps<{
    title?: string
    description?: string
    image?: string
}>()

const route = useRoute()

const updateMetaTags = () => {
    document.title = props.title || 'StoryFlame'
    document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', props.description || '')
    document
        .querySelector('meta[property="og:title"]')
        ?.setAttribute('content', props.title || '')
    document
        .querySelector('meta[property="og:description"]')
        ?.setAttribute('content', props.description || '')
    document
        .querySelector('meta[property="og:image"]')
        ?.setAttribute('content', props.image || '')
    document
        .querySelector('meta[property="og:url"]')
        ?.setAttribute('content', window.location.href)
}

watch(() => route.meta, updateMetaTags, { immediate: true })
watch(() => props, updateMetaTags, { deep: true, immediate: true })
</script>
