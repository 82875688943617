<template>
    <svg
        viewBox="0 0 10 11"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 2.16679V8.83345M1.648 1.01145C1.58903 0.964856 1.51813 0.935814 1.44342 0.927657C1.36871 0.919499 1.29321 0.932555 1.22557 0.965328C1.15794 0.998101 1.10091 1.04927 1.06101 1.11296C1.02111 1.17665 0.99997 1.2503 1 1.32545V9.67479C1.0001 9.74989 1.02133 9.82344 1.06128 9.88704C1.10122 9.95063 1.15826 10.0017 1.22587 10.0344C1.29348 10.0671 1.36893 10.0801 1.44358 10.0719C1.51824 10.0637 1.58908 10.0347 1.648 9.98812L6.93533 5.81412C6.98278 5.77668 7.02112 5.72898 7.04748 5.67459C7.07384 5.62021 7.08754 5.56056 7.08754 5.50012C7.08754 5.43968 7.07384 5.38003 7.04748 5.32565C7.02112 5.27126 6.98278 5.22356 6.93533 5.18612L1.648 1.01145Z"
        />
    </svg>
</template>

<script setup lang="ts">
</script>

<style scoped></style>
