<template>
    <modal-backdrop v-slot="{ close }">
        <modal-layout class="w-full max-w-prose">
            <modal-header>Data Point</modal-header>
            <modal-content>
                <AutoForm
                    :field-config="{
                        is_core_data_point: {
                            component: 'switch',
                            label: 'Is Core Data Point',
                        },
                        purpose: {
                            component: 'textarea',
                        },
                        extraction_description: {
                            component: 'textarea',
                        },
                        example: {
                            component: 'textarea',
                        },
                    }"
                    :form="form"
                    :schema="formSchema"
                    class="flex flex-col gap-2"
                    @submit="handleSubmit"
                >
                    <template #achievement_id="slotProps">
                        <AutoFormFieldEnum v-bind="slotProps">
                            <div class="flex w-full gap-1">
                                <Select
                                    :model-value="
                                        form.values.achievement_id?.toString()
                                    "
                                    @update:model-value="
                                        form.setFieldValue(
                                            'achievement_id',
                                            +$event
                                        )
                                    "
                                >
                                    <SelectTrigger class="w-full">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem
                                            v-for="option in achievements"
                                            :key="option.id"
                                            :value="option.id.toString()"
                                        >
                                            {{ option.name }}
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                                <Button
                                    variant="ghost"
                                    @click="
                                        openAchievementModal(
                                            form.values.achievement_id
                                        )
                                    "
                                >
                                    Open
                                </Button>
                            </div>
                        </AutoFormFieldEnum>
                        <p>
                            <Button
                                variant="link"
                                @click="openAchievementModal()"
                            >
                                Create New Achievement
                            </Button>
                        </p>
                    </template>
                </AutoForm>
            </modal-content>
            <modal-footer class="justify-end gap-2">
                <v-button @click="close">Cancel</v-button>
                <v-button
                    variant="light"
                    @click="
                        () => handleSubmit().then((valid) => valid && close())
                    "
                >
                    Save
                </v-button>
            </modal-footer>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import ModalFooter from '@/shared/components/modals/layouts/ModalFooter.vue'
import VButton from '@/shared/components/VButton.vue'
import { AutoForm, AutoFormFieldEnum } from '@/shared/components/ui/auto-form'
import { z } from 'zod'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { onMounted, ref } from 'vue'
import api from '@/shared/utils/api'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/shared/components/ui/select'
import { Button } from '@/shared/components/ui/button'
import useModal from '@/shared/composables/useModal'
import AdminAchievementModal from '@/shared/components/modals/admin/AdminAchievementModal.vue'

const emit = defineEmits(['create', 'saved'])

const props = defineProps({
    dataPointId: {
        type: Number,
        default: null,
    },
    prefill: {
        type: Object,
        default: () => undefined,
    },
})
const modal = useModal()

const achievements = ref([])

const formSchema = z.object({
    name: z.string(),
    achievement_id: z.number(),
    type: z
        .enum([
            'string',
            'array',
            'filterable_tag',
            'array.filterable_tags',
            'name',
        ])
        .nullable(),
    is_core_data_point: z.boolean().default(false),
    purpose: z.string(),
    extraction_description: z.string(),
    example: z.string().optional(),
    development_order: z.number().int().min(1).default(1),
    impact_score: z.number().int().min(1).default(1),
    estimated_seconds: z.number().int().min(1).default(1),
})

const form = useForm({
    validationSchema: toTypedSchema(formSchema),
})

async function handleSubmit() {
    const valid = await form.validate()
    if (!valid.valid) return false

    if (props.dataPointId) {
        await api.put(
            `/api/admin/v2/data-points/${props.dataPointId}`,
            form.values
        )
    } else {
        const { data } = await api.post(
            '/api/admin/v2/data-points',
            form.values
        )
        emit('create', data)
    }
    emit('saved')
    return true
}

async function openAchievementModal(id = null) {
    modal.show(
        AdminAchievementModal,
        {
            achievementId: id,
        },
        {
            onClose: () => fetchAchievements(),
        }
    )
}

async function fetchAchievements() {
    const achievementOptions = await api.get('/api/admin/v2/achievements')
    achievements.value = achievementOptions.data
}

onMounted(async () => {
    if (props.dataPointId) {
        const { data } = await api.get(
            `/api/admin/v2/data-points/${props.dataPointId}`
        )
        form.setValues(data)
    } else if (props.prefill) {
        form.setValues(props.prefill, false)
    }

    await fetchAchievements()
})
</script>

<style scoped></style>
