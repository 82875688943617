// stores/conversationStore.ts
import { defineStore } from 'pinia'
import { computed, inject, isRef, ref } from 'vue'
import {
    finishConversation as finishConversationAPI,
    initiateConversation,
    retryProcessing as retryProcessingAPI,
    sendAnswer,
    skipQuestion as skipQuestionAPI,
} from '@/shared/utils/endpoints'
import { ChatMessage } from '@/shared/types/chatMessage'
import { useLogger } from 'vue-logger-plugin'
import { developmentInsightsInjection } from '@/shared/types/injection'
import { DevelopmentInsight } from '@/shared/types/developmentInsight'
import { useInsightClient } from '@/shared/stores/developmentInsightsStore'
import { useWindowSize } from '@vueuse/core'
import api from '@/shared/utils/api'
import { useRoute, useRouter } from 'vue-router'

export function useConversationStore()  {
    const logger = useLogger()
    const insightClient = useInsightClient()
    const { width } = useWindowSize()
    const route = useRoute()
    const router = useRouter()
    const developmentInsightsConfig = inject(developmentInsightsInjection)
    console.log('--- injection', developmentInsightsConfig)
    const chatMessage = ref<ChatMessage | null>(null)
    const isProcessing = ref(false)
    const isPendingInsights = ref(false)
    const error = ref<string | null>(null)
    const progress = ref(0)
    const data = ref<any>({})
    const actions = ref<any>({})
    const freeTrialEnded = ref(false)

    console.log('----useConversationStore----')

    if (developmentInsightsConfig) {
        developmentInsightsConfig.isProcessing = isPendingInsights
    }
    const isAnswered = computed(() => chatMessage.value?.answer != null)

    let questionFetchInterval: number | null = null
    let latestChatMessageId: number | null = null
    const startQuestionFetch = () => {
        logger.debug('Starting question fetch interval')
        console.log('chatmessage', chatMessage.value)
        latestChatMessageId = chatMessage.value?.id ?? null
        const sessionChatId = chatMessage.value?.sc_id
        if (questionFetchInterval) {
            logger.warn('Clearing existing question fetch interval')
            clearInterval(questionFetchInterval)
        }
        if (!sessionChatId || !latestChatMessageId) {
            logger.warn('Missing session chat id or latest chat message id')
            return
        }

        questionFetchInterval = setInterval(async () => {
            api.get(
                `/api/v1/conversation/session-chats/${sessionChatId}/chat-messages`,
                {
                    params: {
                        after: latestChatMessageId,
                    },
                }
            ).then((response) => {
                if (response.data) {
                    if (questionFetchInterval)
                        clearInterval(questionFetchInterval)

                    isProcessing.value = false

                    updateFromPushNotification(response.data)
                }
            })
        }, 5000)
    }

    const getStatusMessage = computed(() => {
        if (chatMessage.value?.status) {
            switch (chatMessage.value.status) {
                case 'pending':
                    return 'Preparing to process your message...'
                case 'transcribing':
                    return 'Transcribing your audio...'
                case 'processing':
                    return 'Processing your message...'
                case 'failed':
                    return 'An error occurred while processing your message.'
                case 'generating_question':
                    return 'Generating a question...'
                default:
                    logger.warn('Unknown status', chatMessage.value.status)
                    return 'Processing your answer...'
            }
        }
        return 'Processing your answer...'
    })

    async function initConversation(params: any) {
        isProcessing.value = true
        error.value = null
        try {
            const response = await initiateConversation(params)
            updateFromResponse(response)
            return response
        } catch (err: any) {
            if (err.response?.data?.type === 'trial_exceeded_error') {
                freeTrialEnded.value = true
            } else {
                error.value = 'Failed to initiate conversation'
            }
            throw err
        } finally {
            isProcessing.value = false
        }
    }

    async function submitAnswer(formData: FormData) {
        if (!actions.value.answer) {
            throw new Error('No answer action available')
        }
        isProcessing.value = true
        isPendingInsights.value = true
        error.value = null
        try {
            const response = await sendAnswer(
                actions.value.answer.url,
                formData
            )

            updateFromResponse(response)
            startQuestionFetch()
            return response
        } catch (err) {
            error.value = 'Failed to submit answer'
            throw err
        } finally {
        }
    }

    async function skipQuestion() {
        if (!actions.value.skip) {
            throw new Error('No skip action available')
        }
        isProcessing.value = true
        error.value = null
        try {
            const response = await skipQuestionAPI(actions.value.skip.url)
            updateFromResponse(response)
            startQuestionFetch()
            return response
        } catch (err) {
            error.value = 'Failed to skip question'
            throw err
        } finally {
        }
    }

    async function retryProcessing() {
        if (!actions.value.retry) {
            throw new Error('No retry action available')
        }
        isProcessing.value = true
        isPendingInsights.value = true
        error.value = null
        try {
            const response = await retryProcessingAPI(actions.value.retry.url)
            updateFromResponse(response)
            startQuestionFetch()
            return response
        } catch (err) {
            error.value = 'Failed to retry processing'
            throw err
        } finally {
        }
    }

    async function finishConversation() {
        if (!actions.value.finish) {
            throw new Error('No finish action available')
        }
        isProcessing.value = true
        isPendingInsights.value = true
        error.value = null
        try {
            const response = await finishConversationAPI(
                actions.value.finish.url
            )
            return response
        } catch (err) {
            error.value = 'Failed to finish conversation'
            throw err
        } finally {
        }
    }

    function updateFromResponse(response: any) {
        logger.debug('updateFromResponse', response)
        if (!response) return

        if (response.path) {
            const newPath = router.resolve({
                query: {
                    ...route.query,
                    conversation_path: response.path,
                },
            }).fullPath
            logger.debug('replaceState', newPath)
            history.replaceState(history.state, '', newPath)
            // history.pushState(history.state, '', response.path)
        }
        if (response.chat_message) {
            if (latestChatMessageId && questionFetchInterval) {
                if (latestChatMessageId !== response.chat_message.id) {
                    // New chat message
                    clearInterval(questionFetchInterval)
                }
            }

            chatMessage.value = response.chat_message
            logger.debug('status', response.chat_message.status)
            if (chatMessage.value && developmentInsightsConfig) {
                if (chatMessage.value.status === 'processing') {
                    isPendingInsights.value = true
                } else if (chatMessage.value.status === 'completed') {
                    isPendingInsights.value = false
                }
                if (chatMessage.value.project) {
                    console.log('set project slug')
                    if (isRef(developmentInsightsConfig.projectSlug)) {
                        developmentInsightsConfig.projectSlug.value =
                            chatMessage.value.project.project_slug
                    } else {
                        developmentInsightsConfig.projectSlug =
                            chatMessage.value.project.project_slug
                    }
                }
                if (chatMessage.value.narrative_element) {
                    console.log('set narrative element slug')
                    if (isRef(developmentInsightsConfig.narrativeElementSlug)) {
                        developmentInsightsConfig.narrativeElementSlug.value =
                            chatMessage.value.narrative_element.slug
                    } else {
                        developmentInsightsConfig.narrativeElementSlug =
                            chatMessage.value.narrative_element.slug
                    }
                } else {
                    console.log('reset narrative element slug')
                    if (isRef(developmentInsightsConfig.narrativeElementSlug)) {
                        developmentInsightsConfig.narrativeElementSlug.value =
                            undefined
                    } else {
                        developmentInsightsConfig.narrativeElementSlug =
                            undefined
                    }
                }
            }
        }
        if (response.progress) {
            progress.value = response.progress
        }
        if (response.data) {
            logger.debug('update data', response.data)
            data.value = {
                ...data.value,
                ...response.data,
            }
        }
        if (response.actions) {
            Object.entries(response.actions ?? {}).forEach(([key, value]) => {
                if (value) actions.value[key] = value
            })
        }
        if (response.development_insights) {
            pushInsights(response.development_insights)
        }
        console.log('development-insights-config', developmentInsightsConfig)
    }

    function pushInsights(newInsights: {
        projectSlug: string
        narrativeElementSlug: string
        title: string
        caption: string
        insights: DevelopmentInsight[]
    }) {
        console.log(developmentInsightsConfig)
        console.log('push insights', newInsights)
        if (developmentInsightsConfig) {
            insightClient.setData(
                newInsights.projectSlug,
                newInsights.narrativeElementSlug,
                'current',
                {
                    title: newInsights.title,
                    caption: newInsights.caption,
                    insights: newInsights.insights,
                }
            )
            developmentInsightsConfig.projectSlug.value =
                newInsights.projectSlug
            developmentInsightsConfig.narrativeElementSlug.value =
                newInsights.narrativeElementSlug
            if (width.value < 768) {
                insightClient.openPopup(
                    developmentInsightsConfig.projectSlug.value,
                    developmentInsightsConfig.narrativeElementSlug.value
                )
            }
        }
    }

    function updateFromPushNotification(data: any) {
        updateFromResponse(data)
    }

    function updateChatMessage(newChatMessage: ChatMessage) {
        chatMessage.value = newChatMessage
    }

    return {
        chatMessage,
        isProcessing,
        isPendingInsights,
        error,
        freeTrialEnded,
        progress,
        data,
        actions,
        isAnswered,
        getStatusMessage,
        initConversation,
        submitAnswer,
        skipQuestion,
        retryProcessing,
        finishConversation,
        updateFromPushNotification,
        updateChatMessage,
        pushInsights,
    }
}
