<template>
    <!--    <svg-->
    <!--        xmlns="http://www.w3.org/2000/svg"-->
    <!--        width="20"-->
    <!--        height="21"-->
    <!--        viewBox="0 0 20 21"-->
    <!--        fill="none"-->
    <!--    >-->
    <!--        <path-->
    <!--            d="M5 16.7497V5.17967C5 4.79579 5.12861 4.47551 5.38583 4.21884C5.64306 3.96217 5.96333 3.83356 6.34667 3.83301H13.6542C14.0375 3.83301 14.3578 3.96162 14.615 4.21884C14.8722 4.47606 15.0006 4.79634 15 5.17967V16.7497L10 14.6022L5 16.7497ZM5.83333 15.458L10 13.6663L14.1667 15.458V5.17967C14.1667 5.05134 14.1133 4.93356 14.0067 4.82634C13.9 4.71912 13.7822 4.66579 13.6533 4.66634H6.34667C6.21833 4.66634 6.10056 4.71967 5.99333 4.82634C5.88611 4.93301 5.83278 5.05079 5.83333 5.17967V15.458Z"-->
    <!--            fill="currentColor"-->
    <!--        />-->
    <!--    </svg>-->
    <svg
        class="lucide lucide-bookmark"
        fill="none"
        height="24"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z" />
    </svg>
</template>
