import { type VariantProps, cva } from 'class-variance-authority'

export { default as Button } from './Button.vue'

export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-parchment-950 disabled:pointer-events-none disabled:opacity-50 dark:focus-visible:ring-parchment-300',
  {
    variants: {
      variant: {
        default: 'bg-parchment-900 text-parchment-50 shadow hover:bg-parchment-900/90 dark:bg-parchment-50 dark:text-parchment-900 dark:hover:bg-parchment-50/90',
        destructive:
          'bg-red-500 text-parchment-50 shadow-sm hover:bg-red-500/90 dark:bg-red-900 dark:text-parchment-50 dark:hover:bg-red-900/90',
        outline:
          'border border-parchment-200 bg-white shadow-sm hover:bg-parchment-100 hover:text-parchment-900 dark:border-parchment-800 dark:bg-parchment-950 dark:hover:bg-parchment-800 dark:hover:text-parchment-50',
        secondary:
          'bg-parchment-100 text-parchment-900 shadow-sm hover:bg-parchment-100/80 dark:bg-parchment-800 dark:text-parchment-50 dark:hover:bg-parchment-800/80',
        ghost: 'hover:bg-parchment-100 hover:text-parchment-900 dark:hover:bg-parchment-800 dark:hover:text-parchment-50',
        link: 'text-parchment-900 underline-offset-4 hover:underline dark:text-parchment-50',
      },
      size: {
        default: 'h-9 px-4 py-2',
        xs: 'h-7 rounded px-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export type ButtonVariants = VariantProps<typeof buttonVariants>
