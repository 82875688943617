<template>
    <modal-backdrop>
        <modal-layout class="min-h-72 w-full max-w-prose">
            <detached-tab-layout
                v-slot="{ activeTab }"
                :tabs="[
                    { title: 'Current', template: 'current', enabled: true },
                    {
                        title: 'Previous Versions',
                        template: 'previous',
                        enabled: true,
                    },
                    {
                        title: 'Summary',
                        template: 'summary',
                        enabled: computed(() => !!user.edit_enabled),
                    },
                    {
                        title: 'Data Points',
                        template: 'data-points',
                        enabled: computed(() => !!user.edit_enabled),
                    },
                ]"
            >
                <modal-header>
                    <tab-layout-tabs
                        v-slot="{ tab, select, active }"
                        class="flex justify-start gap-10"
                    >
                        <button
                            :class="{
                                'font-bold': active,
                                'cursor-not-allowed':
                                    tab.template === 'previous' &&
                                    !hasPreviousVersions,
                            }"
                            :disabled="
                                tab.template === 'previous' &&
                                !hasPreviousVersions
                            "
                            @click="select"
                        >
                            {{ tab.title }}
                            <span v-if="tab.template === 'previous'">
                                ({{ previousVersionsCount }})
                            </span>
                        </button>
                    </tab-layout-tabs>
                </modal-header>

                <modal-content
                    :class="{
                        'overflow-x-auto': activeTab === 'data-points',
                    }"
                >
                    <tab-layout-view>
                        <template #current>
                            <p class="text-base">
                                <summary-block-mentions-renderer
                                    :content-with-slugs="contentWithSlugs"
                                    :mentions="mentions"
                                    :user-summary-content="userSummaryContent"
                                />
                            </p>
                        </template>
                        <template #previous>
                            <div class="max-h-96 overflow-y-auto">
                                <div
                                    v-for="version in previousVersions"
                                    :key="version.id"
                                    class="mb-4 border-b pb-4"
                                >
                                    <p class="mb-2">{{ version.summary }}</p>
                                    <p class="text-sm text-gray-500">
                                        {{ formatDate(version.created_at) }}
                                    </p>
                                </div>
                            </div>
                        </template>
                        <template #data-points>
                            <summary-data-points-list
                                v-if="summaryId"
                                :summary-id="summaryId"
                            />
                        </template>
                        <template #summary>
                            <summary-form
                                v-if="summaryId"
                                :summary-id="summaryId"
                            />
                        </template>
                    </tab-layout-view>
                </modal-content>
                <modal-footer
                    v-if="
                        projectSlug &&
                        ['current', 'previous'].includes(activeTab)
                    "
                    class="flex-col items-center justify-center"
                >
                    <app-loader
                        v-if="loadingUserSummary"
                        class="h-10 w-10"
                    />
                    <template v-else-if="!reconciliationUpdate">
                        <div v-if="isSpeakingMode || loading">
                            <voice-button
                                :loading="loading"
                                variant="small"
                                @recorded="processUpdate"
                            />
                        </div>
                        <div v-else>
                            <textarea
                                v-model="updateInput"
                                class="w-full rounded border p-2"
                                placeholder="Enter your update..."
                            ></textarea>
                            <v-button @click="processUpdate(updateInput)">
                                Submit
                            </v-button>
                        </div>
                        <button
                            class="mt-2 text-sm text-gray-500"
                            @click="isSpeakingMode = !isSpeakingMode"
                        >
                            Switch to
                            {{ isSpeakingMode ? 'typing' : 'speaking' }} mode
                        </button>
                    </template>
                    <template v-else>
                        <check-icon
                            class="h-10 w-10 rounded-full bg-green-500 text-white"
                        ></check-icon>
                        <small class="mt-5 max-w-[300px] text-center text-sm">
                            Your update is saved, you can find it in the
                            reconciliations list.
                        </small>
                    </template>
                </modal-footer>
            </detached-tab-layout>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import {
    getSummaryHistory,
    processSummaryUpdate,
} from '@/shared/utils/endpoints'
import VButton from '@/shared/components/VButton.vue'
import VoiceButton from '@/shared/components/VoiceButton.vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useChannel from '@/shared/composables/useChannel'
import { useAuthStore } from '@/shared/stores/auth'
import CheckIcon from '@/shared/components/icons/CheckIcon.vue'
import AppLoader from '@/shared/components/AppLoader.vue'
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import ModalFooter from '@/shared/components/modals/layouts/ModalFooter.vue'
import TabLayoutTabs from '@/shared/components/ui/TabLayoutTabs.vue'
import TabLayoutView from '@/shared/components/ui/TabLayoutView.vue'
import DetachedTabLayout from '@/shared/components/DetachedTabLayout.vue'
import SummaryDataPointsList from '@/shared/components/SummaryDataPointsList.vue'
import SummaryForm from '@/shared/components/forms/SummaryForm.vue'
import SummaryBlockMentionsRenderer from '@/shared/components/SummaryBlockMentionsRenderer.vue'

const props = defineProps({
    userSummaryId: {
        type: Number,
        required: true,
    },
    userSummaryContent: {
        type: String,
        required: true,
    },
    projectSlug: {
        type: [Number, String],
        default: () => undefined,
    },
    contentWithSlugs: {
        type: String,
        default: null,
    },
    mentions: {
        type: Array,
        default: () => [],
    },
})

dayjs.extend(relativeTime)

const previousVersions = ref([])
const isUpdating = ref(false)
const isSpeakingMode = ref(true)
const summaryId = ref(undefined)
const loading = ref(false)
const updateInput = ref('')
const { user } = useAuthStore()

const previousVersionsCount = computed(() => previousVersions.value.length)
const hasPreviousVersions = computed(() => previousVersions.value.length > 0)
const loadingUserSummary = ref(true)
const reconciliationUpdate = ref(false)

async function fetchSummaryHistory() {
    try {
        const response = await getSummaryHistory(props.userSummaryId)
        previousVersions.value = response.history
        reconciliationUpdate.value = !!response.reconciliation_update
        loadingUserSummary.value = false
        summaryId.value = response.summary_id ?? undefined
    } catch (error) {
        console.error('Failed to fetch summary history:', error)
    }
}

onMounted(fetchSummaryHistory)

async function processUpdate(input: string | Blob) {
    if (!props.projectSlug) return
    loading.value = true
    try {
        await processSummaryUpdate(
            props.projectSlug,
            props.userSummaryId,
            input
        )
        // loading.value = false
        // isUpdating.value = false
        // fetchSummaryHistory() // Re-fetch the history after update
    } catch (error) {
        console.error('Failed to process summary update:', error)
        loading.value = false
    }
}

function formatDate(date) {
    const now = dayjs()
    const createdAt = dayjs(date)
    return now.diff(createdAt, 'day') <= 1
        ? createdAt.fromNow()
        : createdAt.format('MMM D, YYYY')
}

const { listen } = useChannel()

onMounted(() => {
    listen('private', `App.Models.User.${user!.id}`)?.listen(
        '.reconciliation-update.processed',
        () => {
            console.log('completed')
            loading.value = false
            isUpdating.value = false
            loadingUserSummary.value = false
            reconciliationUpdate.value = true
        }
    )
})
</script>

<style scoped></style>
