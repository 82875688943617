<template>
	<svg
		fill="none"
		viewBox="0 0 25 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.4999 3C14.623 3.00003 16.6777 3.7506 18.3009 5.11905C19.924 6.48749 21.0112 8.3857 21.3702 10.4782C21.7292 12.5707 21.3369 14.7227 20.2626 16.5539C19.1884 18.3851 17.5013 19.7776 15.4997 20.4853C13.4981 21.193 11.3107 21.1702 9.32418 20.4211C7.33768 19.672 5.67995 18.2448 4.64398 16.3916C3.60801 14.5385 3.26052 12.3788 3.6629 10.2942C4.06529 8.20964 5.19165 6.33442 6.84292 5"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2.5"
		/>
		<path
			d="M3.5 4.5H7.5V8.5"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2.5"
		/>
	</svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
