<template>
    <div class="flex flex-col items-end gap-10">
        <Button
            @click="
                modal.show(NodeTree, {
                    nodeType: 'summary',
                    nodeId: props.summaryId,
                })
            "
        >
            Open node tree
        </Button>
        <AutoForm
            :field-config="{
                purpose: {
                    component: 'textarea',
                },
                creation_prompt: {
                    component: 'textarea',
                },
                example_summary: {
                    component: 'textarea',
                },
            }"
            :form="form"
            :schema="formSchema"
            class="w-full"
        >
            <template #category_id="slotProps">
                <AutoFormFieldEnum v-bind="slotProps">
                    <Select
                        :model-value="form.values.category_id?.toString()"
                        @update:model-value="
                            form.setFieldValue('category_id', +$event)
                        "
                    >
                        <SelectTrigger class="w-full">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem
                                v-for="option in categories"
                                :key="option.id"
                                :value="option.id.toString()"
                            >
                                {{ option.name }}
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </AutoFormFieldEnum>
            </template>
        </AutoForm>

        <v-button
            variant="light"
            @click="() => handleSubmit().then((valid) => valid && emit('close'))"
        >
            Save
        </v-button>
    </div>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import { AutoForm, AutoFormFieldEnum } from '@/shared/components/ui/auto-form'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { onMounted, ref } from 'vue'
import api from '@/shared/utils/api'
import VButton from '@/shared/components/VButton.vue'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/shared/components/ui/select'
import { Button } from '@/shared/components/ui/button'
import useModal from '@/shared/composables/useModal'
import NodeTree from '@/shared/components/modals/admin/NodeTree.vue'

const emit = defineEmits(['create', 'saved','close'])

const props = defineProps({
    summaryId: {
        type: Number,
        default: null,
    },
    prefill: {
        type: Object,
        default: () => undefined,
    },
})

const modal = useModal()

const categories = ref([])

const formSchema = z.object({
    category_id: z.number(),
    name: z.string(),
    length: z.string(),
    purpose: z.string(),
    creation_prompt: z.string().nullable(),
    example_summary: z.string().nullable(),
})

const form = useForm({
    validationSchema: toTypedSchema(formSchema),
})

async function handleSubmit() {
    const valid = await form.validate()
    if (!valid.valid) return false

    if (props.summaryId) {
        await api.put(`/api/admin/v2/summaries/${props.summaryId}`, form.values)
    } else {
        const { data } = await api.post('/api/admin/v2/summaries', form.values)
        emit('create', data)
    }
    emit('saved')

    return true
}

async function fetchCategories() {
    const { data } = await api.get('/api/admin/v2/categories')
    categories.value = data
}

onMounted(async () => {
    if (props.summaryId) {
        const { data } = await api.get(
            `/api/admin/v2/summaries/${props.summaryId}`
        )
        form.setValues(data)
    } else if (props.prefill) {
        form.setValues(props.prefill, false)
    }

    await fetchCategories()
})
</script>

<style scoped></style>
