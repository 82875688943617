<template>
    <div>
        <div class="flex justify-between">
            <h3 class="text-md">Summary Data Points</h3>
            <DropdownMenu v-if="summary">
                <DropdownMenuTrigger>
                    <plus-icon class="h-4" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuSub>
                        <DropdownMenuSubTrigger>
                            Data Point
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                                <DropdownMenuItem
                                    class="gap-1"
                                    @click="
                                        handleClick(
                                            {
                                                schemaable_type: 'DataPoint',
                                                prefill: {
                                                    category_id: summary.category_id,
                                                }
                                            },
                                            {
                                                onCreate: (payload) => {
                                                    const [dataPoint] = payload

                                                    attach(
                                                        'DataPoint',
                                                        dataPoint.id
                                                    )
                                                },
                                            }
                                        )
                                    "
                                >
                                    <plus-icon class="h-4" />
                                    Create New
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    class="gap-1"
                                    @click="
                                        modal.show(
                                            SearchSelectModal,
                                            {
                                                url: '/api/admin/v2/data-points',
                                                filters: {
                                                    category_id: summary.category_id,
                                                }
                                            },
                                            {
                                                onSelect: (payload, close) => {
                                                    const [dataPoint] = payload

                                                    attach(
                                                        'DataPoint',
                                                        dataPoint.id
                                                    ).then(() => {
                                                        close()
                                                    })
                                                },
                                            }
                                        )
                                    "
                                >
                                    <link-icon class="h-4" />
                                    Attach Existing
                                </DropdownMenuItem>
                            </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                    </DropdownMenuSub>
                    <DropdownMenuSub>
                        <DropdownMenuSubTrigger>
                            Achievement
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                                <DropdownMenuItem
                                    class="gap-1"
                                    @click="
                                        handleClick(
                                            {
                                                schemaable_type: 'Achievement',
                                                prefill: {
                                                    category_id: summary.category_id,
                                                }
                                            },
                                            {
                                                onCreate: (payload) => {
                                                    const [achievement] =
                                                        payload

                                                    attach(
                                                        'Achievement',
                                                        achievement.id
                                                    )
                                                },
                                            }
                                        )
                                    "
                                >
                                    <plus-icon class="h-4" />
                                    Create New
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    class="gap-1"
                                    @click="
                                        modal.show(
                                            SearchSelectModal,
                                            {
                                                url: '/api/admin/v2/achievements',
                                                filters: {
                                                    category_id: summary.category_id,
                                                }
                                            },
                                            {
                                                onSelect: (payload, close) => {
                                                    const [dataPoint] = payload

                                                    attach(
                                                        'Achievement',
                                                        dataPoint.id
                                                    ).then(() => {
                                                        close()
                                                    })
                                                },
                                            }
                                        )
                                    "
                                >
                                    <link-icon class="h-4" />
                                    Attach Existing
                                </DropdownMenuItem>
                            </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                    </DropdownMenuSub>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
        <table class="table w-full table-auto divide-y divide-parchment-300">
            <thead>
                <tr>
                    <th
                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-parchment-900 sm:pl-0"
                    >
                        ID
                    </th>
                    <th
                        class="px-3 py-3.5 text-left text-sm font-semibold text-parchment-900"
                    >
                        Name
                    </th>
                    <th
                        class="px-3 py-3.5 text-left text-sm font-semibold text-parchment-900"
                    >
                        Type
                    </th>
                    <th
                        class="px-3 py-3.5 text-left text-sm font-semibold text-parchment-900"
                    >
                        Required
                    </th>
                    <!--                    <th-->
                    <!--                        class="px-3 py-3.5 text-left text-sm font-semibold text-parchment-900"-->
                    <!--                    >-->
                    <!--                        Created-->
                    <!--                    </th>-->
                    <th
                        class="px-3 py-3.5 text-left text-sm font-semibold text-parchment-900"
                    >
                        Updated
                    </th>
                    <th
                        class="px-3 py-3.5 text-left text-sm font-semibold text-parchment-900"
                    >
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody class="divide-y divide-parchment-200">
                <tr
                    v-for="schema in schemas"
                    :key="schema.id"
                    class="duration-75 hover:bg-parchment-100"
                    @click="handleClick(schema)"
                >
                    <td
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-parchment-900 sm:pl-0"
                    >
                        {{ schema.id }}
                    </td>
                    <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                        {{ schema.schemaable.name }}
                    </td>
                    <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                        {{ schema.schemaable_type }}
                    </td>
                    <td
                        class="whitespace-nowrap px-3 py-2 text-sm text-gray-500"
                    >
                        <Button
                            class="py-0"
                            variant="outline"
                            @click.stop="
                                api.put(
                                    `/api/admin/v2/summaries/${props.summaryId}/schemas/${schema.id}`,
                                    {
                                        is_required: !schema.is_required,
                                    }
                                ).then(() => {
                                    queryClient.invalidateQueries({
                                        queryKey: [
                                            'summaryDataPoints',
                                            props.summaryId,
                                        ],
                                    })
                                })
                            "
                        >
                            {{ schema.is_required ? 'Yes' : 'No' }}
                        </Button>
                    </td>
                    <!--                    <td-->
                    <!--                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"-->
                    <!--                    >-->
                    <!--                        {{-->
                    <!--                            DateTime.fromISO(schema.created_at).toLocaleString(-->
                    <!--                                DateTime.DATETIME_MED-->
                    <!--                            )-->
                    <!--                        }}-->
                    <!--                    </td>-->
                    <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                        {{
                            DateTime.fromISO(schema.updated_at).toLocaleString(
                                DateTime.DATETIME_MED
                            )
                        }}
                    </td>
                    <td>
                        <Button
                            variant="ghost"
                            @click.stop="
                                api.delete(
                                    `/api/admin/v2/summaries/${props.summaryId}/schemas/${schema.id}`
                                ).then(() => {
                                    queryClient.invalidateQueries({
                                        queryKey: [
                                            'summaryDataPoints',
                                            props.summaryId,
                                        ],
                                    })
                                })
                            "
                        >
                            <unlink-icon class="h-4" />
                        </Button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts" setup>
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import api from '@/shared/utils/api'
import { DateTime } from 'luxon'
import AdminDataPointModal from '@/shared/components/modals/admin/AdminDataPointModal.vue'
import useModal from '@/shared/composables/useModal'
import AdminAchievementModal from '@/shared/components/modals/admin/AdminAchievementModal.vue'
import PlusIcon from '@/shared/components/icons/PlusIcon.vue'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from '@/shared/components/ui/dropdown-menu'
import { DropdownMenuPortal } from 'radix-vue'
import LinkIcon from '@/shared/components/icons/LinkIcon.vue'
import { Button } from '@/shared/components/ui/button'
import SearchSelectModal from '@/shared/components/modals/SearchSelectModal.vue'
import UnlinkIcon from '@/shared/components/icons/UnlinkIcon.vue'

const props = defineProps({
    summaryId: {
        type: Number,
        required: true,
    },
})

const modal = useModal()

const queryClient = useQueryClient()
const { data: summary } = useQuery({
    queryKey: ['summaries', props.summaryId],
    queryFn: async () => {
        return (await api.get(`/api/admin/v2/summaries/${props.summaryId}`))
            .data
    },
})

const { data: schemas, isLoading } = useQuery({
    queryKey: ['summaryDataPoints', props.summaryId],
    queryFn: async () => {
        return (
            await api.get(`/api/admin/v2/summaries/${props.summaryId}/schemas`)
        ).data
    },
})

function handleClick(schema, events = {}) {
    const modalType =
        schema.schemaable_type === 'DataPoint'
            ? AdminDataPointModal
            : AdminAchievementModal

    console.log('modalType', modalType)

    if (modalType) {
        modal.show(
            modalType,
            {
                dataPointId: schema.schemaable_id,
                achievementId: schema.schemaable_id,
                prefill: schema.prefill ?? null,
            },
            {
                onClose: () => {
                    queryClient.invalidateQueries({
                        queryKey: ['summaryDataPoints', props.summaryId],
                    })
                },
                ...events,
            }
        )
    }
}

function attach(type: string, id: number) {
    return api
        .post(`/api/admin/v2/summaries/${props.summaryId}/schemas`, {
            schemaable_id: id,
            schemaable_type: type,
        })
        .then(() => {
            queryClient.invalidateQueries({
                queryKey: ['summaryDataPoints', props.summaryId],
            })
        })
}
</script>

<style scoped></style>
