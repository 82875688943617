<template>
    <template
        v-for="(text, index) in props.texts"
        :key="text.modelValue"
    >
        <transition
            v-if="text.template"
            enter-active-class="transition-opacity duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            @ended="visibleIndex++"
        >
            <slot
                v-if="index <= visibleIndex"
                :name="text.template"
            />
        </transition>

        <animated-text
            :is="text.is"
            v-if="index <= visibleIndex && !text.template"
            :class="text.class"
            :model-value="text.modelValue"
            :speed="text.speed"
            class="overflow-visible"
            @transitionend="visibleIndex++"
        />
    </template>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import AnimatedText from '@/shared/components/AnimatedText.vue'

const props = withDefaults(
    defineProps<{
        texts: {
            template: string | undefined
            modelValue: string
            class: string
            is: string
            speed?: number
        }[]
    }>(),
    {}
)

const visibleIndex = ref(0)

watch(
    () => props.texts,
    (value, oldValue) => {
        // detect what line is changed and move the visible index to that line
        const changedIndex = value.findIndex(
            (text, index) => text.modelValue !== oldValue[index].modelValue
        )
        if (changedIndex !== -1) {
            visibleIndex.value = changedIndex
        } else {
            visibleIndex.value = -1
            requestAnimationFrame(() => {
                visibleIndex.value = 0
            })
        }
    }
)

onMounted(() => {
    requestAnimationFrame(() => {})
})
</script>

<style scoped></style>
