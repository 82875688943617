<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_203_1876)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.99999 10.0605L13.2427 14.3032C13.3842 14.4399 13.5736 14.5155 13.7703 14.5137C13.9669 14.512 14.155 14.4332 14.2941 14.2941C14.4332 14.155 14.512 13.9669 14.5137 13.7703C14.5155 13.5736 14.4399 13.3842 14.3032 13.2427L10.0605 8.99999L14.3032 4.75724C14.4399 4.61579 14.5155 4.42634 14.5137 4.22969C14.512 4.03304 14.4332 3.84493 14.2941 3.70588C14.155 3.56682 13.9669 3.48794 13.7703 3.48623C13.5736 3.48453 13.3842 3.56012 13.2427 3.69674L8.99999 7.93949L4.75724 3.69674C4.61515 3.5635 4.4268 3.49076 4.23204 3.49393C4.03728 3.49709 3.85138 3.5759 3.7137 3.71369C3.57601 3.85147 3.49733 4.03742 3.4943 4.23218C3.49128 4.42695 3.56415 4.61525 3.69749 4.75724L7.93949 8.99999L3.69674 13.2427C3.62511 13.3119 3.56797 13.3947 3.52866 13.4862C3.48936 13.5777 3.46867 13.6761 3.4678 13.7757C3.46694 13.8753 3.48591 13.974 3.52362 14.0662C3.56133 14.1584 3.61702 14.2421 3.68744 14.3125C3.75786 14.383 3.8416 14.4386 3.93377 14.4764C4.02595 14.5141 4.12471 14.533 4.22429 14.5322C4.32387 14.5313 4.42229 14.5106 4.51379 14.4713C4.6053 14.432 4.68805 14.3749 4.75724 14.3032L8.99999 10.0605Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_203_1876">
        <rect
          width="18"
          height="18"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
