<template>
    <modal-backdrop
        class="flex flex-col items-center justify-center gap-5 backdrop-blur-sm"
        @click.self="emit('close')"
    >
        <modal-layout
            class="flex w-full max-w-[300px] flex-col gap-2 rounded-xl border bg-white text-lg shadow-xl"
        >
            <modal-content class="px-2 md:px-4 md:py-4 lg:px-10 lg:py-8">
                <div
                    v-for="field in fields"
                    :key="field.name"
                    ref="formFields"
                    :data-name="field.name"
                >
                    <label :for="field.name">
                        {{ field.label }}

                        <select
                            v-if="field.type === 'summary'"
                            v-model="form[field.name]"
                            class="w-full rounded-xl border-2"
                        >
                            <option
                                v-for="option in field.options"
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.label }}
                            </option>
                        </select>

                        <select
                            v-else-if="field.type === 'select'"
                            v-model="form[field.name]"
                            class="w-full rounded-xl border-2"
                        >
                            <option
                                v-for="option in field.options"
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.label }}
                            </option>
                        </select>

                        <input
                            v-else
                            v-model="form[field.name]"
                            :placeholder="field.placeholder"
                            :required="field.required"
                            :type="field.type"
                            class="w-full rounded-xl border-2"
                        />
                    </label>
                </div>
            </modal-content>
            <modal-footer class="flex justify-between gap-3">
                <v-button @click="emit('close')">Cancel</v-button>
                <v-button
                    variant="danger"
                    @click="
                        () => {
                            emit('confirm', form)
                            emit('close')
                        }
                    "
                >
                    Confirm
                </v-button>
            </modal-footer>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import VButton from '@/shared/components/VButton.vue'
import { onMounted, PropType, reactive, ref } from 'vue'
import api from '@/shared/utils/api'
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import ModalFooter from '@/shared/components/modals/layouts/ModalFooter.vue'

const emit = defineEmits(['close', 'confirm'])

const props = defineProps({
    fields: {
        type: Object as PropType<
            Array<{
                name: string
                type: string
                label: string
                placeholder?: string
                options?: Array<{ value: string; label: string }>
                required?: boolean
                default?: string
            }>
        >,
        required: true,
    },
})

const form = reactive<Record<string, string | number>>({})
const formFields = ref<Array<HTMLDivElement> | null>(null)

onMounted(() => {
    // focus on first input field
    formFields.value?.[0].querySelector('input')?.focus()

    for (const field of props.fields) {
        if (field.default) {
            form[field.name] = field.default
        } else {
            form[field.name] = ''
        }

        if (field.type === 'summary') {
            api.get('/api/v1/autocomplete/summaries').then((response) => {
                field.options = response.data.map((summary: any) => ({
                    value: summary.id,
                    label: summary.name,
                }))
            })
        }
    }
})
</script>

<style scoped></style>
