<template>
    <header class="flex items-center w-full justify-between gap-2 border-b border-solid border-parchment-200 p-2 md:p-4 sticky">
        <div class="flex items-center flex-grow min-w-0 gap-2">
            <image-component
                v-if="image"
                class="h-11 w-11 flex-shrink-0 rounded-lg bg-parchment-200"
                :image="image"
                size="thumbnail"
                disable-click
            />
            <div class="flex flex-col min-w-0 max-w-full text-left">
                <div class="element-title font-fjalla text-base md:text-xl line-clamp-1 text-parchment-900">
                    {{ loading ? '' : title }}
                </div>
                <small class="text-xs w-full line-clamp-1 text-parchment-700">
                    {{ loading ? '' : subtitle }}
                </small>
            </div>
        </div>
        <div class="conversation-header-buttons flex flex-row gap-2 flex-shrink-0">
            <div
                v-if="actions.finish"
                class="flex items-start justify-center cursor-pointer px-3 md:px-3.5 py-1.5 rounded-full text-sm md:text-base text-parchment-500 hover:text-white border border-solid border-parchment-500 hover:border-parchment-900 bg-white hover:bg-parchment-900"
                @click="emit('finish')"
            >
                Finish
            </div>
        </div>
    </header>
</template>

<script lang="ts" setup>
import VButton from '@/shared/components/VButton.vue'
import ImageComponent from "@/shared/components/ImageComponent.vue";

const emit = defineEmits(['skip', 'finish'])

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    image: {
        type: Object,
        default: null,
    },
    subtitle: {
        type: String,
        default: '',
    },
    loading: {
        type: Boolean,
        default: false,
    },
    actions: {
        type: Object,
        default: () => ({}),
    }
})
</script>
