<!-- /resources/shared/components/modals/ConversationEngineModal.vue -->
<template>
    <modal-backdrop class="modalBackdrop bg-gray-200/85 backdrop-blur-md">
        <div
            class="conversationEngineModal flex h-full max-h-[896px] w-full items-center justify-center gap-10 px-2 py-14 md:px-16 2xl:px-64"
        >
            <conversation-engine
                :achievement-slug="
                    pathParams.searchParams.get('achievement_slug')
                "
                :model-name="
                    pathParams.searchParams.get('model_name') ??
                    pathParams.searchParams.get('modelName')
                "
                :narrative-element-slug="
                    pathParams.searchParams.get('narrative_element_slug')
                "
                :parent-slug="pathParams.searchParams.get('parent_slug')"
                :project-slug="projectSlug"
                :session-chat-id="
                    pathParams.searchParams.get('session_chat_id')
                "
                :user-achievement-id="
                    pathParams.searchParams.get('user_achievement_id')
                "
                class="h-full w-full max-w-[560px] rounded-2xl border shadow"
                @finish="$emit('close')"
            />
            <aside
                class="hidden h-full w-full max-w-[380px] flex-col overflow-hidden rounded-2xl border bg-white shadow md:flex"
            >
                <development-insights
                    :is-loading="developmentInsights?.isProcessing ?? false"
                    :narrative-element-id="
                        developmentInsights?.narrativeElementSlug
                    "
                    :project-id="developmentInsights?.projectSlug"
                />
            </aside>
        </div>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import ConversationEngine from '@/app/views/ConversationEngine.vue'
import DevelopmentInsights from '@/shared/components/DevelopmentInsights.vue'
import { computed, provide, reactive, ref } from 'vue'
import { useInsightQuery } from '@/shared/stores/developmentInsightsStore'
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import { developmentInsightsInjection } from '@/shared/types/injection'

const props = defineProps({
    path: {
        type: String,
        default: '',
    },
    // developmentInsights: {
    //     type: Object as PropType<{
    //         projectSlug: Ref<string>
    //         narrativeElementSlug: Ref<string>
    //         isProcessing: Ref<string>
    //     }>,
    //     required: true,
    // },
})

const developmentInsights = reactive({
    projectSlug: ref(undefined),
    narrativeElementSlug: ref(undefined),
    isProcessing: ref(false),
})

provide(developmentInsightsInjection, developmentInsights)

const pathParams = computed(() => {
    return new URL(props.path, window.location.origin)
})

const { data: insightsData, isLoading: isLoadingQuery } = useInsightQuery(
    computed(() => developmentInsights?.projectSlug),
    computed(() => developmentInsights?.narrativeElementSlug),
    'current'
)

const projectSlug = computed(() => {
    if (props.path.startsWith('/projects')) {
        return pathParams.value.pathname.split('/')[2]
    }
    return pathParams.value.searchParams.get('project_slug') ?? null
})
</script>

<style scoped></style>
