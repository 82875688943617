<template>
    <modal-backdrop v-slot="{ close }">
        <modal-layout class="w-full max-w-prose">
            <detached-tab-layout
                v-slot="{ activeTab }"
                :tabs="[
                    {
                        title: 'ImageType',
                        template: 'image',
                        enabled: true,
                    },
                    {
                        title: 'Schema',
                        template: 'schema',
                        enabled: computed(() => !!props.achievementId),
                    },
                ]"
                @update:active-tab="
                    $event === 'achievement' &&
                    Object.keys(originalValues).length
                        ? form.setValues(originalValues)
                        : null
                "
            >
                <modal-header>
                    <tab-layout-tabs
                        v-slot="{ tab, select, active }"
                        always-show
                        class="flex justify-start gap-10"
                    >
                        <button
                            :class="[active ? 'font-bold' : 'font-medium']"
                            @click="select"
                        >
                            {{ tab.title }}
                        </button>
                    </tab-layout-tabs>
                </modal-header>
                <modal-content>
                    <tab-layout-view>
                        <template #image>
                            <AutoForm
                                :field-config="{
                                    example_prompt: {
                                        component: 'textarea',
                                    },
                                    purpose: {
                                        component: 'textarea',
                                    },
                                    creation_prompt: {
                                        component: 'textarea',
                                    },
                                    prompt_settings: {
                                        component: 'textarea',
                                    },
                                    is_main_image: {
                                        component: 'switch',
                                    },
                                }"
                                :form="form"
                                :schema="formSchema"
                                class="flex flex-col gap-2"
                                @submit="handleSubmit"
                            >
                                <template #ratio>
                                    <span class="text-stone-500 text-xs font-bold">
                                        Aspect Ratio: {{ratio}}
                                    </span>
                                </template>
                            </AutoForm>
                        </template>
                        <template #data-points>
                            <achievement-data-points-table
                                :achievement-id="props.achievementId"
                            />
                        </template>
                    </tab-layout-view>
                </modal-content>
            </detached-tab-layout>
            <modal-footer class="justify-end gap-2">
                <v-button @click="close">Cancel</v-button>
                <v-button
                    variant="light"
                    @click="
                        () => handleSubmit().then((valid) => valid && close())
                    "
                >
                    Save
                </v-button>
            </modal-footer>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import {
    AutoForm,
    AutoFormField,
    AutoFormFieldEnum,
} from '@/shared/components/ui/auto-form'
import { z } from 'zod'
import { ErrorMessage, useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import ModalFooter from '@/shared/components/modals/layouts/ModalFooter.vue'
import VButton from '@/shared/components/VButton.vue'
import { computed, onMounted, ref } from 'vue'
import api from '@/shared/utils/api'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/shared/components/ui/select'
import DetachedTabLayout from '@/shared/components/DetachedTabLayout.vue'
import TabLayoutTabs from '@/shared/components/ui/TabLayoutTabs.vue'
import TabLayoutView from '@/shared/components/ui/TabLayoutView.vue'
import AchievementDataPointsTable from '@/shared/components/AchievementDataPointsTable.vue'
import { Button } from '@/shared/components/ui/button'
import useModal from '@/shared/composables/useModal'
import { Input } from '@/shared/components/ui/input'
import AdminImageLibrary from '@/shared/components/modals/admin/AdminImageLibrary.vue'
import { modelTypeKeys } from '@/shared/types/modelTypes'

const emit = defineEmits(['create', 'saved'])

const props = defineProps({
    imageTypeId: {
        type: Number,
        default: null,
    },
    prefill: {
        type: Object,
        default: () => undefined,
    },
})

const modal = useModal()
const imageUrl = ref<string | null>(null)
const originalValues = ref({})
const categories = ref([])

const formSchema = z.object({
    model_type: z.enum(modelTypeKeys),
    is_main_image: z.boolean(),
    name: z.string(),
    width: z.number().min(1),
    height: z.number().min(1),
    ratio: z.number().readonly().optional(),
    purpose: z.string(),
    creation_prompt: z.string(),
    prompt_settings: z.string(),
    example_prompt: z.string(),
})

const form = useForm({
    validationSchema: toTypedSchema(formSchema),
})

const ratio = computed(() => {
    const { width, height } = form.values
    if (!width || !height) return 'N/A'

    const gcd = (a: number, b: number): number => (b ? gcd(b, a % b) : a)
    const divisor = gcd(width, height)
    return `${width / divisor}:${height / divisor}`
})

async function handleSubmit() {
    const valid = await form.validate()
    console.log('valid', valid)
    if (!valid.valid) return false

    console.log('submitting', form.values)
    if (props.imageTypeId)
        await api.put(
            `/api/admin/v2/image-types/${props.imageTypeId}`,
            form.values
        )
    else {
        const { data } = await api.post(
            '/api/admin/v2/image-types',
            form.values
        )
        emit('create', data)
    }
    emit('saved')
    return true
}

onMounted(async () => {
    if (props.imageTypeId) {
        const { data } = await api.get(
            `/api/admin/v2/image-types/${props.imageTypeId}`
        )
        originalValues.value = data
        form.setValues(data)
    } else if (props.prefill) {
        form.setValues(props.prefill, false)
    }
})
</script>

<style scoped></style>
