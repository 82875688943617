<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="43"
        viewBox="0 0 44 43"
        fill="none"
    >
        <circle
            cx="21.7188"
            cy="21.3398"
            r="21.3398"
            fill="url(#paint0_linear_3037_72441)"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.3278 16.3757C29.7169 19.1059 30.8414 22.2913 30.3867 25.3335C29.7067 29.8693 26.0128 32.7121 21.83 32.8183C14.7672 32.9977 11.3477 27.0082 13.2401 20.6633C13.7429 18.8425 14.9404 17.1192 16.2332 15.706C16.8314 15.0596 17.1667 14.5558 17.2389 15.9931C17.2577 16.6845 17.5093 17.3467 17.7215 17.7521C17.8858 18.0719 17.9159 18.0017 18.1508 17.7521C19.9392 15.8016 20.8033 13.0115 21.3583 10.4856C21.6388 9.20862 21.6213 9.17524 22.6511 9.76697C24.8399 11.0247 27.2202 14.2041 28.3278 16.3757Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_3037_72441"
                x1="0.378906"
                y1="0"
                x2="21.7187"
                y2="42.6797"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF9202" />
                <stop
                    offset="1"
                    stop-color="#FD2D24"
                />
            </linearGradient>
        </defs>
    </svg>
</template>
