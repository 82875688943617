<template>
    <modal-backdrop v-slot="{ close }">
        <modal-layout class="w-full max-w-prose">
            <detached-tab-layout
                v-slot="{ activeTab }"
                :tabs="[
                    {
                        title: 'Achievement',
                        template: 'achievement',
                        enabled: true,
                    },
                    {
                        title: 'Data Points',
                        template: 'data-points',
                        enabled: computed(() => !!props.achievementId),
                    },
                ]"
                @update:active-tab="
                    $event === 'achievement' &&
                    Object.keys(originalValues).length
                        ? form.setValues(originalValues)
                        : null
                "
            >
                <modal-header>
                    <tab-layout-tabs
                        v-slot="{ tab, select, active }"
                        always-show
                        class="flex justify-start gap-10"
                    >
                        <button
                            :class="[active ? 'font-bold' : 'font-medium']"
                            @click="select"
                        >
                            {{ tab.title }}
                        </button>
                    </tab-layout-tabs>
                </modal-header>
                <modal-content>
                    <tab-layout-view>
                        <template #achievement>
                            <AutoForm
                                :field-config="{
                                    extraction_description: {
                                        component: 'textarea',
                                    },
                                    purpose: {
                                        component: 'textarea',
                                    },
                                    example_paragraph_of_narrative_impact: {
                                        component: 'textarea',
                                    },
                                    example_one_line_of_narrative_impact: {
                                        component: 'textarea',
                                    },
                                    color: {
                                        inputProps: {
                                            type: 'color',
                                        },
                                    },
                                }"
                                :form="form"
                                :schema="formSchema"
                                class="flex flex-col gap-2"
                                @submit="handleSubmit"
                            >
                                <template #image_id>
                                    <div class="flex justify-between">
                                        <div
                                            :style="{
                                                backgroundImage: `url(${imageUrl})`,
                                            }"
                                            class="flex h-32 w-32 items-center justify-center rounded-lg border bg-contain bg-center bg-no-repeat"
                                        >
                                            <span v-if="!imageUrl">
                                                No Image
                                            </span>
                                        </div>
                                        <div
                                            class="flex flex-col justify-between gap-2"
                                        >
                                            <input
                                                ref="input"
                                                class="hidden"
                                                type="file"
                                            />
                                            <Button
                                                @click="
                                                    () => $refs.input.click()
                                                "
                                            >
                                                Change Image
                                            </Button>
                                            <Button
                                                @click="
                                                    () => {
                                                        imageUrl = null
                                                        form.setFieldValue(
                                                            'image_id',
                                                            null,
                                                            false
                                                        )
                                                    }
                                                "
                                            >
                                                Remove Image
                                            </Button>
                                            <Button
                                                @click="
                                                    modal.show(
                                                        AdminImageLibrary,
                                                        {
                                                            filters: {
                                                                type: 'achievement',
                                                            },
                                                        },
                                                        {
                                                            onSelect: (
                                                                payload,
                                                                close,
                                                                modal
                                                            ) => {
                                                                const [image] =
                                                                    payload
                                                                imageUrl =
                                                                    image.medium
                                                                form.setFieldValue(
                                                                    'image_id',
                                                                    image.id,
                                                                    false
                                                                )
                                                                close()
                                                            },
                                                        }
                                                    )
                                                "
                                            >
                                                Choose from Library
                                            </Button>
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        class="text-[0.8rem] font-medium text-red-500 dark:text-red-900"
                                        name="image_id"
                                        message="test"
                                    />
                                </template>
                                <template #category_id="slotProps">
                                    <AutoFormFieldEnum v-bind="slotProps">
                                        <Select
                                            :model-value="
                                                form.values.category_id?.toString()
                                            "
                                            @update:model-value="
                                                form.setFieldValue(
                                                    'category_id',
                                                    +$event,
                                                    false
                                                )
                                            "
                                        >
                                            <SelectTrigger class="w-full">
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem
                                                    v-for="option in categories"
                                                    :key="option.id"
                                                    :value="
                                                        option.id.toString()
                                                    "
                                                >
                                                    {{ option.name }}
                                                </SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </AutoFormFieldEnum>
                                </template>
                                <template #color="slotProps">
                                    <AutoFormField v-bind="slotProps" />
                                    <Input
                                        :model-value="form.values.color"
                                        @update:model-value="
                                            form.setFieldValue('color', $event, false)
                                        "
                                    />
                                </template>
                            </AutoForm>
                        </template>
                        <template #data-points>
                            <achievement-data-points-table
                                :achievement-id="props.achievementId"
                            />
                        </template>
                    </tab-layout-view>
                </modal-content>
            </detached-tab-layout>
            <modal-footer class="justify-end gap-2">
                <v-button @click="close">Cancel</v-button>
                <v-button
                    variant="light"
                    @click="
                        () => handleSubmit().then((valid) => valid && close())
                    "
                >
                    Save
                </v-button>
            </modal-footer>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import {
    AutoForm,
    AutoFormField,
    AutoFormFieldEnum,
} from '@/shared/components/ui/auto-form'
import { z } from 'zod'
import { ErrorMessage, useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import ModalFooter from '@/shared/components/modals/layouts/ModalFooter.vue'
import VButton from '@/shared/components/VButton.vue'
import { computed, onMounted, ref } from 'vue'
import api from '@/shared/utils/api'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/shared/components/ui/select'
import DetachedTabLayout from '@/shared/components/DetachedTabLayout.vue'
import TabLayoutTabs from '@/shared/components/ui/TabLayoutTabs.vue'
import TabLayoutView from '@/shared/components/ui/TabLayoutView.vue'
import AchievementDataPointsTable from '@/shared/components/AchievementDataPointsTable.vue'
import { Button } from '@/shared/components/ui/button'
import useModal from '@/shared/composables/useModal'
import { Input } from '@/shared/components/ui/input'
import AdminImageLibrary from '@/shared/components/modals/admin/AdminImageLibrary.vue'

const emit = defineEmits(['create', 'saved'])

const props = defineProps({
    achievementId: {
        type: Number,
        default: null,
    },
    prefill: {
        type: Object,
        default: () => undefined,
    },
})

const modal = useModal()
const imageUrl = ref<string | null>(null)
const originalValues = ref({})
const categories = ref([])

const formSchema = z.object({
    image_id: z.number().nullable(),
    color: z
        .string()
        .regex(
            /^#[0-9a-fA-F]{6}$/,
            'Invalid hex color, must be in the format #RRGGBB'
        ),
    category_id: z.number(),
    name: z.string(),
    subtitle: z.string(),
    extraction_description: z.string(),
    purpose: z.string(),
    example_open_ended_questions: z.array(z.string()).nullable(),
    example_one_line_of_narrative_impact: z.string().nullable().optional(),
    example_paragraph_of_narrative_impact: z.string().nullable().optional(),
    // estimated_seconds: z.number().int().min(1).default(1),
    // dev_order: z.number().int().min(1).default(1),
    // total_impact: z.number().int().min(1).default(1),
    // model_type: z.enum()
})

const form = useForm({
    validationSchema: toTypedSchema(formSchema),
})

async function handleSubmit() {
    const valid = await form.validate()
    console.log('valid', valid)
    if (!valid.valid) return false

    console.log('submitting', form.values)
    if (props.achievementId)
        await api.put(
            `/api/admin/v2/achievements/${props.achievementId}`,
            form.values
        )
    else {
        const { data } = await api.post(
            '/api/admin/v2/achievements',
            form.values
        )
        emit('create', data)
    }
    emit('saved')
    return true
}

async function fetchCategories() {
    const { data } = await api.get('/api/admin/v2/categories')
    categories.value = data

    console.log(props.prefill)

    if (props.prefill.model_plural && !form.values.category_id) {
        const category = data.find(
            (category) => category.model_plural === props.prefill.model_plural
        )
        console.log(category)
        if (category) {
            form.setFieldValue('category_id', category.id, false)
        }
    }
}

onMounted(async () => {
    if (props.achievementId) {
        const { data } = await api.get(
            `/api/admin/v2/achievements/${props.achievementId}`
        )
        originalValues.value = data
        if (data.image) {
            imageUrl.value = data.image.medium
        }
        form.setValues(data)
    } else if (props.prefill) {
        form.setValues(props.prefill, false)
    }

    await fetchCategories()
})
</script>

<style scoped></style>
