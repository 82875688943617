<template>
	<svg
		fill="none"
		viewBox="-1 0 18 19"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.1249 7.07354C16.5651 7.30767 16.9334 7.65717 17.1902 8.08461C17.447 8.51205 17.5827 9.0013 17.5827 9.49996C17.5827 9.99861 17.447 10.4879 17.1902 10.9153C16.9334 11.3427 16.5651 11.6922 16.1249 11.9264L4.38054 18.3128C2.48946 19.3422 0.166626 18.0039 0.166626 15.8873V3.11354C0.166626 0.99604 2.48946 -0.341377 4.38054 0.686206L16.1249 7.07354Z"
			fill="currentColor"
		/>
	</svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
