// Namespace: endpoints
import api from '@/shared/utils/api'
import User from '@/shared/types/user'
import Story from '@/shared/types/storyElements/story'
import Sequence from '@/shared/types/storyElements/sequence'
import { Project } from '@/shared/types/project'

export function getOnboardingSummary() {
    return api.get<User>('/api/v1/onboarding/summary')
}

export async function getAuthenticatedUser() {
    return await api.get<User>('/api/v1/auth/user')
}

export async function logout() {
    const response = await api.post('/api/v1/auth/logout')
    return response.response.data
}

export function getStories() {
    return api.get<Array<Story>>('/api/v1/stories')
}

export async function getUserAchievements() {
    const response = await api.get('/api/v1/user-achievements')
    return response.response.data
}

export function getProjects() {
    return api.get<Array<Story>>('/api/v1/projects')
}

export function getStory(id: number) {
    return api.get<Story>(`/api/v1/stories/${id}`)
}

export function getSequence(storyId: string, id: string) {
    return api.get<Sequence>(`/api/v1/stories/${storyId}/sequences/${id}`)
}

export const getSubscriptions = async () => {
    const response = await api.get('/api/v1/subscriptions')
    return response.response.data
}

export const changeSubscription = async (
    id: bigint,
    productPriceId: number
) => {
    const response = await api.put(`/api/v1/subscriptions/${id}`, {
        product_price_id: productPriceId,
    })
    return response.data
}

export const cancelSubscription = async (paddleId) => {
    const response = await api.delete(`/api/v1/subscriptions/${paddleId}`)
    return response.data
}

export const cancelSubscriptionChange = async (paddleId) => {
    const response = await api.delete(
        `/api/v1/subscriptions/${paddleId}/change`
    )
    return response.data
}

export const getInvoices = async () => {
    const response = await api.get('/api/v1/subscriptions/invoices')
    return response.response.data
}

export const getInvoiceLink = async (id) => {
    const response = await api.get(`/api/v1/subscriptions/invoices/${id}`)
    return response.response.data
}
export const createCustomer = async () => {
    const response = await api.post('/api/v1/subscriptions/customer')
    return response.response.data
}

export const updateName = async (data: {
    first_name: string
    last_name: string
    receive_emails: boolean | undefined
}) => {
    try {
        const response = await api.post<User>('/api/v1/user/name/update', data)
        return response.response.data
    } catch (error) {
        console.error('Error updating name:', error)
        throw error
    }
}

export const getProject = async (slug: string, isTest: boolean = false) => {
    try {
        // Add the test query parameter if isTest is true
        const { data } = await api.get<Project>(`/api/v1/projects/${slug}`, {
            params: {
                test: isTest ? 'true' : undefined,
            },
        })
        return data
    } catch (error) {
        console.error(`Error fetching project data for ${slug}:`, error)
        throw error
    }
}

export const getProjectElements = async (
    slug: string,
    categoryModelPlural: string
) => {
    try {
        const response = await api.get(
            `/api/v1/projects/${slug}/${categoryModelPlural}`
        )
        return response.response.data
    } catch (error) {
        console.error(
            `Error fetching project elements for ${slug} in category ${categoryModelPlural}:`,
            error
        )
        throw error
    }
}

export const getPageContent = async (
    modelType: string,
    slug: string,
    params: Record<string, any> = {}
) => {
    try {
        const response = await api.get(`/api/v1/pages/${modelType}/${slug}`, {
            params, // Pass the params object directly to the request
        })
        return response.data
    } catch (error) {
        console.error(
            `Error fetching content for ${modelType} with slug ${slug}:`,
            error
        )
        throw error
    }
}

export const getSessionChats = async () => {
    const response = await api.get('/api/v1/session-chats')
    return response.response.data
}

export const getSessionChat = async (id: number) => {
    const response = await api.get(`/api/v1/session-chats/${id}`)
    return response.response.data
}

export const getWeekSummary = async () => {
    const response = await api.get('/api/v1/session-chats/week-summary')
    return response.response.data.data
}

export const getDayDetails = async (date: string) => {
    const response = await api.get(`/api/v1/session-chats/day/${date}`)
    return response.response.data
}

export const getSessionChatElements = async (id: number) => {
    const response = await api.get(`/api/v1/session-chats/${id}/elements`)
    return response.response.data
}

export const getElement = async (modelPlural: string, modelSlug: number) => {
    try {
        const response = await api.get(
            `/api/v1/elements/${modelPlural}/${modelSlug}`
        )
        return response.response.data
    } catch (error) {
        console.error(
            `Error fetching element for ${path} with ID ${id}:`,
            error
        )
        throw error
    }
}

export const getElementList = async (
    path: string,
    params: Record<string, any> = {}
) => {
    try {
        const response = await api.get(`/api/v1/elements/${path}`, { params })
        return response.response.data
    } catch (error) {
        console.error(`Error fetching element list for ${path}:`, error)
        throw error
    }
}

export const uploadElementImage = async (
    projectSlug: string,
    modelSlug: string,
    formData: FormData
) => {
    try {
        const response = await api.post(
            `/api/v1/projects/${projectSlug}/elements/${modelSlug}/images`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error uploading project image:`, error)
        if (error.response && error.response.data) {
            throw new Error(
                error.response.data.message ||
                    'An error occurred during upload.'
            )
        }
        throw error
    }
}

export const initiateSummaryUpdate = async (userSummaryId: number) => {
    try {
        const response = await api.post(
            `/api/v1/user-summaries/${userSummaryId}/update/initiate`
        )
        return response.data
    } catch (error) {
        console.error('Error initiating summary update:', error)
        throw error
    }
}

export const processSummaryUpdate = async (
    projectSlug: number | string,
    userSummaryId: string | number,
    message: string | Blob
) => {
    try {
        const formData = new FormData()
        formData.append('user_summary_id', userSummaryId.toString())
        formData.append('project_slug', projectSlug.toString())
        if (message instanceof Blob) {
            formData.append('audio', message)
        } else {
            formData.append('message', message)
        }

        const response = await api.post(`/api/v1/reconciliations`, formData)
        return response.data
    } catch (error) {
        console.error('Error processing summary update:', error)
        throw error
    }
}

export const getSummaryHistory = async (userSummaryId: number) => {
    try {
        const response = await api.get(
            `/api/v1/user-summaries/${userSummaryId}/history`
        )
        console.log('getSummaryHistory response', response.response.data.data)
        return response.response.data.data
    } catch (error) {
        console.error('Error fetching summary history:', error)
        throw error
    }
}

export const getReconciliations = async () => {
    try {
        const response = await api.get('/api/v1/reconciliations')
        return response.response.data
    } catch (error) {
        console.error('Error fetching reconciliations:', error)
        throw error
    }
}

export const getReconciliation = async (reconciliationSlug: string) => {
    try {
        const response = await api.get(
            `/api/v1/reconciliations/${reconciliationSlug}`
        )
        return response.response.data
    } catch (error) {
        console.error(
            `Error fetching reconciliation with slug ${reconciliationSlug}:`,
            error
        )
        throw error
    }
}

export const updateReconciliation = async (
    reconciliation_slug: string,
    reconciliation_update_slug: string,
    data: { content: string }
) => {
    const response = await api.post(
        `/api/v1/reconciliations/${reconciliation_slug}/update_item/${reconciliation_update_slug}`,
        data
    )
    return response.data
}

export const deleteReconciliationUpdate = async (
    reconciliation_slug: String,
    reconciliation_update_slug: String
) => {
    const response = await api.delete(
        `/api/v1/reconciliations/${reconciliation_slug}/update_item/${reconciliation_update_slug}`
    )
    return response.response.data
}

export const initiateReconciliationProcessing = async (
    reconciliationSlug: string
) => {
    try {
        const response = await api.post(
            `/api/v1/reconciliations/${reconciliationSlug}/process`
        )
        return response.data
    } catch (error) {
        console.error(
            `Error initiating reconciliation processing for slug ${reconciliationSlug}:`,
            error
        )
        throw error
    }
}

export const initiateConversation = async (params: {
    project_slug?: string
    narrative_element_slug?: string
    user_achievement_id?: string | number
    achievement_slug?: string
    model_name?: string
}) => {
    try {
        const response = await api.get('/api/v1/conversation/next-question', {
            params,
        })
        return response.data
    } catch (error) {
        console.error('Error initiating conversation:', error)
        throw error
    }
}

export const getConversationStatus = async () => {
    try {
        const response = await api.get('/api/v1/conversation/status')
        return response.data
    } catch (error) {
        console.error('Error fetching conversation status:', error)
        throw error
    }
}

export const sendAnswer = async (url: string, formData: FormData) => {
    try {
        const response = await api.post(url, formData)
        return response.data
    } catch (error) {
        console.error('Error sending answer:', error)
        throw error
    }
}

export const skipQuestion = async (url: string) => {
    try {
        const response = await api.post(url)
        return response.data
    } catch (error) {
        console.error('Error skipping question:', error)
        throw error
    }
}

export const retryProcessing = async (url: string) => {
    try {
        const response = await api.post(url)
        return response.data
    } catch (error) {
        console.error('Error retrying processing:', error)
        throw error
    }
}

export const finishConversation = async (url: string) => {
    try {
        const response = await api.post(url)
        return response.data
    } catch (error) {
        console.error('Error finishing conversation:', error)
        throw error
    }
}
