import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { Reconciliation } from '@/shared/types/reconciliation'
import { ReconciliationUpdate } from '@/shared/types/reconciliationUpdate'
import {
    getReconciliations,
    getReconciliation,
    updateReconciliation,
    deleteReconciliationUpdate,
    initiateReconciliationProcessing
} from '@/shared/utils/endpoints'

export const useReconciliationStore = defineStore('reconciliation', () => {
    const reconciliations = ref<Reconciliation[]>([])
    const availableReconciliations = ref(0)
    const nextResetTime = ref<string | null>(null)
    const queryClient = useQueryClient()
    const activeReconciliations = ref([]);

    const hasActiveReconciliations = computed(() => activeReconciliations.value.length > 0);

    async function fetchActiveReconciliations() {
        try {
            // Filter reconciliations with status 'processing' directly from the store
            activeReconciliations.value = reconciliations.value.filter(reconciliation => reconciliation.status === 'processing');
        } catch (error) {
            console.error('Failed to fetch active reconciliations:', error);
        }
    }

    const setReconciliations = (newReconciliations: Reconciliation[]) => {
        reconciliations.value = newReconciliations
    }

    const setAvailableReconciliations = (count: number) => {
        availableReconciliations.value = count
    }

    const setNextResetTime = (time: string | null) => {
        nextResetTime.value = time
    }

    const addReconciliation = (reconciliation: Reconciliation) => {
        reconciliations.value.push(reconciliation)
    }

    const updateReconciliationInStore = (updatedReconciliation: Reconciliation) => {
        const index = reconciliations.value.findIndex(r => r.slug === updatedReconciliation.slug)
        if (index !== -1) {
            reconciliations.value[index] = updatedReconciliation
        }
    }

    const removeReconciliation = (slug: string) => {
        reconciliations.value = reconciliations.value.filter(r => r.slug !== slug)
    }

    const getReconciliationBySlug = computed(() => (slug: string) => {
        return reconciliations.value.find(r => r.slug === slug)
    })

    const updateReconciliationUpdateItem = (reconciliationSlug: string, updatedItem: Partial<ReconciliationUpdate>) => {
        const reconciliation = reconciliations.value.find(r => r.slug === reconciliationSlug);
        if (!reconciliation) return;

        if (!reconciliation.update_items) {
            reconciliation.update_items = [];
        }

        const updateItemIndex = reconciliation.update_items.findIndex(item => item.slug === updatedItem.slug);
        if (updateItemIndex !== -1) {
            // Update existing item
            reconciliation.update_items[updateItemIndex] = {
                ...reconciliation.update_items[updateItemIndex],
                ...updatedItem
            };
        } else {
            // Add new item if it doesn't exist
            reconciliation.update_items.push(updatedItem as ReconciliationUpdate);
        }

        // Trigger reactivity
        reconciliations.value = [...reconciliations.value];
    };

    return {
        reconciliations,
        availableReconciliations,
        nextResetTime,
        setReconciliations,
        setAvailableReconciliations,
        setNextResetTime,
        addReconciliation,
        updateReconciliationInStore,
        removeReconciliation,
        updateReconciliationUpdateItem,
        getReconciliationBySlug,
        activeReconciliations,
        hasActiveReconciliations,
        fetchActiveReconciliations,
    }
})

export function useReconciliationsQuery() {
    const store = useReconciliationStore()
    return useQuery({
        queryKey: ['reconciliations'],
        queryFn: getReconciliations,
        onSuccess: (data) => {
            store.setReconciliations(data)
        },
    })
}

export function useReconciliationQuery(reconciliationSlug: string) {
    const store = useReconciliationStore()
    return useQuery({
        queryKey: ['reconciliation', reconciliationSlug],
        queryFn: () => getReconciliation(reconciliationSlug),
        onSuccess: (data) => {
            store.updateReconciliationInStore(data)
        },
    })
}

export function useUpdateReconciliationMutation() {
    const queryClient = useQueryClient()
    const store = useReconciliationStore()

    return useMutation({
        mutationFn: ({ reconciliation_slug, reconciliation_update_slug, data }: {
            reconciliation_slug: string,
            reconciliation_update_slug: string,
            data: { content: string }
        }) => updateReconciliation(reconciliation_slug, reconciliation_update_slug, data),
        onSuccess: (response, variables) => {
            queryClient.invalidateQueries(['reconciliations'])
            queryClient.invalidateQueries(['reconciliation', variables.reconciliation_slug])
            if (response.data) {
                store.updateReconciliationUpdateItem(variables.reconciliation_slug, {
                    ...response.data,
                    slug: variables.reconciliation_update_slug
                })
            }
        },
    })
}

export function useDeleteReconciliationMutation() {
    const queryClient = useQueryClient()
    const store = useReconciliationStore()

    return useMutation({
        mutationFn: ({ reconciliation_slug, reconciliation_update_slug }: {
            reconciliation_slug: string,
            reconciliation_update_slug: string
        }) => deleteReconciliationUpdate(reconciliation_slug, reconciliation_update_slug),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries(['reconciliations'])
            queryClient.invalidateQueries(['reconciliation', variables.reconciliation_slug])

            // Update the store
            const reconciliation = store.getReconciliationBySlug(variables.reconciliation_slug)
            if (reconciliation) {
                reconciliation.update_items = reconciliation.update_items.filter(
                    item => item.slug !== variables.reconciliation_update_slug
                )
                store.updateReconciliationInStore(reconciliation)
            }
        },
    })
}

export function useInitiateReconciliationMutation() {
    const queryClient = useQueryClient()
    const store = useReconciliationStore()

    return useMutation({
        mutationFn: (reconciliationSlug: string) => initiateReconciliationProcessing(reconciliationSlug),
        onSuccess: () => {
            queryClient.invalidateQueries(['reconciliations'])
            queryClient.invalidateQueries(['user']) // Invalidate user query to refresh available reconciliations
        },
    })
}
