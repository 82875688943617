<template>
    <modal-backdrop
        :primary="primary"
        :secondary="secondary"
        @primary="emit('primary')"
        @secondary="emit('secondary')"
    >
        <modal-layout class="w-full max-w-sm p-2">
            <div class="flex flex-col items-center gap-3 text-center">
                <div
                    class="flex w-full flex-col items-center justify-center gap-2 rounded-lg py-4 text-parchment-700"
                >
                    <!-- Voice input silent error -->
                    <voice-silent-icon
                        v-if="type === 'voice-silent'"
                        class="h-10 w-auto"
                    />
                    <!-- Generic error -->
                    <triangle-alert-icon
                        v-else
                        class="h-10 w-auto"
                    />
                    <h3 class="text-xs font-semibold uppercase tracking-wider">
                        {{ title }}
                    </h3>
                </div>

                <div class="px-2">
                    <p class="text-sm leading-relaxed text-parchment-800">{{ message }}</p>
                </div>
                <small
                    v-if="details"
                    class="rounded bg-parchment-100 p-2 text-xs text-parchment-600"
                >
                    {{ details }}
                </small>
            </div>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import VoiceSilentIcon from '@/shared/components/icons/VoiceSilentIcon.vue'
import TriangleAlertIcon from '@/shared/components/icons/TriangleAlertIcon.vue'
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'

const emit = defineEmits(['primary', 'secondary'])

const props = defineProps({
    type: {
        type: String as PropType<'voice-silent' | 'error'>,
        default: 'error',
    },
    title: {
        type: String,
        default: "We couldn't hear you. Please try again.",
    },
    message: {
        type: String,
        default: 'Make sure your microphone is connected and working properly.',
    },
    details: {
        type: String,
        default: () => undefined,
    },
    primary: {
        type: String,
        default: 'Continue',
    },
    secondary: {
        type: String,
        default: () => undefined,
    },
})
</script>

<style scoped></style>
