<!-- /resources/app/views/ConversationEngine.vue -->
<template>
    <main
        class="relative flex h-full w-full grow flex-col items-center gap-0 bg-white text-center"
    >
        <development-conversation-header
            v-if="!hasActiveReconciliations"
            :actions="actions"
            :image="getHeaderImage(data)"
            :loading="initialLoading"
            :subtitle="getSubtitle(data)"
            :title="getHeaderTitle(data)"
            @finish="finishConversation"
            @skip="skipQuestion"
        />

        <reconciliation-message v-if="hasActiveReconciliations" />

        <template v-else>
            <div
                class="flex h-full w-full flex-col overflow-y-auto pt-8 md:pt-10"
            >
                <conversation-content
                    :actions="actions"
                    :chat-message="chatMessage"
                    :free-trial-ended="freeTrialEnded"
                    :is-processing="isProcessingComputed"
                    :is-speaking-mode="false"
                    :should-show-text-input="shouldShowTextInput"
                    :text-input="textInput"
                    :transcription="transcription"
                    :waiting-for-new-question="waitingForNewQuestion"
                    @skip="skipQuestion"
                    @update:text-input="updateTextInput"
                    @extract-data="extractData"
                />
                <div
                    class="conversation-footer flex h-full w-full flex-col items-center justify-center"
                >
                    <conversation-controls
                        v-if="!isProcessingComputed"
                        :free-trial-ended="freeTrialEnded"
                        :is-processing="isProcessing"
                        :is-recording="isRecording"
                        :is-speaking-mode="isSpeakingMode"
                        :selected-options="selectedOptions"
                        :system-message="systemMessage"
                        :text-input="textInput"
                        :waiting-for-new-question="waitingForNewQuestion"
                        @toggle-speaking-mode="toggleSpeakingMode"
                        @extract-data="extractData"
                        @voice-button-click="handleVoiceButtonClick"
                        @recording-state-change="updateRecordingState"
                    />

                    <processing-component
                        v-if="isProcessingComputed"
                        class="flex h-full w-full items-center justify-center"
                        isActive
                    />

                    <button
                        v-if="systemMessage"
                        class="flex shrink-0 items-center gap-2 px-4 py-2 text-xs text-neutral-500"
                        @click="systemMessage.show?.()"
                    >
                        <triangle-alert-icon class="h-5" />
                        {{ systemMessage.title }}
                    </button>
                </div>
            </div>
        </template>
        <error-display :error="error" />

        <!--        <pre>{{status}}-->
        <!--{{systemMessage}}-->
        <!--        </pre>-->

        <!--        <pre class="absolute bottom-0 right-0 bg-red-500 text-start text-black">-->
        <!--waiting for new: {{ waitingForNewQuestion }} <button-->
        <!--            @click="waitingForNewQuestion = !waitingForNewQuestion">toggle</button>-->
        <!--is procesing: {{ isProcessing }} <button @click="isProcessing = !isProcessing">toggle</button>-->
        <!--is processing computed: {{ isProcessingComputed }}-->
        <!--status: {{ chatMessage?.status }}-->
        <!--model {{ modelName }}-->
        <!--trial ended {{ freeTrialEnded }}</pre>-->
    </main>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, ref, watch, watchEffect } from 'vue'
import { useConversationStore } from '@/shared/stores/conversationStore'
import { useReconciliationStore } from '@/shared/stores/reconciliations'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/shared/stores/auth'
import useChannel from '@/shared/composables/useChannel'
import { useEcho } from '@/shared/types/useEcho'
import DevelopmentConversationHeader from '@/shared/components/headers/DevelopmentConversationHeader.vue'
import ConversationContent from '@/app/components/ConversationContent.vue'
import ConversationControls from '@/app/components/ConversationControls.vue'
import ReconciliationMessage from '@/app/views/ReconciliationMessage.vue'
import ProcessingComponent from '@/shared/components/ProcessingComponent.vue'
import ErrorDisplay from '@/shared/components/ErrorDisplay.vue'
import { useLogger } from 'vue-logger-plugin'
import useModal from '@/shared/composables/useModal'
import { developmentInsightsInjection } from '@/shared/types/injection'
import api from '@/shared/utils/api'
import TriangleAlertIcon from '@/shared/components/icons/TriangleAlertIcon.vue'

const emit = defineEmits(['finish'])

const props = defineProps({
    projectSlug: String,
    narrativeElementSlug: String,
    userAchievementId: [String, Number],
    achievementSlug: String,
    modelName: String,
    parentSlug: String,
    sessionChatId: String,
})

const route = useRoute()

const router = useRouter()
const authStore = useAuthStore()
const conversationStore = useConversationStore()
const {
    chatMessage,
    isProcessing,
    isPendingInsights,
    error,
    progress,
    data,
    actions,
    isAnswered,
    freeTrialEnded,
} = conversationStore
const reconciliationStore = useReconciliationStore()
const { show } = useModal()
const { user } = useAuthStore()

const logger = useLogger()
const { listen } = useChannel()
const echo = useEcho()

const hasActiveReconciliations = computed(() =>
    reconciliationStore.reconciliations.some((r) => r.status === 'processing')
)
const developmentInsightsConfig = inject(developmentInsightsInjection)
const isSpeakingMode = ref(true)
const isRecording = ref(false)
const textInput = ref('')
const transcription = ref('')
const waitingForNewQuestion = ref(false)
const initialLoading = ref(true)
const selectedOptions = ref<string[]>([])
const systemMessage = ref<any>(null)
const status = ref<any>(null)

const isProcessingComputed = computed(() => {
    return (
        isProcessing.value ||
        (chatMessage.value?.status &&
            [
                'processing',
                'transcribing',
                'generating_question',
                'completed',
            ].includes(chatMessage.value?.status)) ||
        (!freeTrialEnded && chatMessage.value?.question == null)
    )
})

const shouldShowTextInput = computed(() => {
    return (
        !waitingForNewQuestion.value &&
        !isSpeakingMode.value &&
        !isRecording.value &&
        !isProcessingComputed.value
    )
})

function openStatusPopup(title: string, message: string, details?: string) {
    show('error', {
        title,
        message,
        details,
    })
    console.log('Opening status popup')
}

function checkStatus() {
    api.get('/api/v1/conversation/status')
        .then(({ data }) => {
            status.value = data
            if (data.processing === 'down') {
                systemMessage.value = {
                    title: 'Service Unavailable',
                    show: () =>
                        openStatusPopup(
                            'Service Unavailable',
                            'The service is currently unavailable. Please try again later.'
                        ),
                }
            } else if (data.demand === 'high') {
                systemMessage.value = {
                    title: 'High Demand',
                    show: () =>
                        openStatusPopup(
                            'High Demand',
                            'The service is currently experiencing high demand. Answers may take longer to process.'
                        ),
                }
            }
        })
        .catch((error) => {
            console.error('Failed to get conversation status:', error)
        })
}

onMounted(async () => {
    initialLoading.value = true
    waitingForNewQuestion.value = false

    await reconciliationStore.fetchActiveReconciliations()

    if (!hasActiveReconciliations.value) {
        await conversationStore.initConversation({
            project_slug: props.projectSlug,
            narrative_element_slug: props.narrativeElementSlug,
            user_achievement_id: props.userAchievementId,
            achievement_slug: props.achievementSlug,
            model_name: props.modelName,
            session_chat_id: route.query.session_chat_id ?? props.sessionChatId,
            parent_slug: props.parentSlug,
        })
    }

    initializeListeners()

    checkStatus()

    // if (developmentInsightsConfig) {
    //     developmentInsightsConfig.isProcessing = isProcessingComputed
    // }

    initialLoading.value = false
})

function initializeListeners() {
    const channel = listen('private', `App.Models.User.${authStore.user!.id}`)

    if (channel) {
        channel
            .listen('.ChatMessageTranscribed', (e: any) => {
                transcription.value = e.transcription
            })
            .listen('.user.message.processed', (e: any) => {
                conversationStore.updateFromPushNotification(e.data)
                waitingForNewQuestion.value = false
            })
            .listen('.chatMessage.created', (e: any) => {
                conversationStore.updateFromPushNotification(e)
                waitingForNewQuestion.value = false
                isProcessing.value = false
            })
            .listen('.chatMessage.data.updated', (e: any) => {
                conversationStore.updateFromPushNotification(e)
                waitingForNewQuestion.value = false
            })
            .listen('.chatMessage.developmentInsightsCreated', (e: any) => {
                logger.info('Received development insights:', e)
                conversationStore.pushInsights(e)
                isPendingInsights.value = false
            })
    }

    watchEffect(() => {
        // Listen to specific chat message channel
        if (chatMessage.value?.id) {
            const chatMessageChannel = listen(
                'private',
                `chat.${chatMessage.value.id}`
            )
            if (chatMessageChannel) {
                chatMessageChannel.listen(
                    '.chatMessage.statusUpdated',
                    (e: any) => {
                        console.log(
                            'Received ChatMessageStatusUpdated event:',
                            e
                        )
                        if (e.chat_message) {
                            conversationStore.updateChatMessage(e.chat_message)
                            if (e.chat_message.status === 'failed') {
                                error.value =
                                    'Processing error: ' +
                                    (e.chat_message.error_message ??
                                        'An unknown error occurred')
                            } else if (e.chat_message.status === 'completed') {
                                waitingForNewQuestion.value = false
                            }
                        }
                    }
                )
            }
        }
    })
}

function getHeaderTitle(data: any) {
    return data?.element?.name ?? 'New Element'
}

function getHeaderImage(data: any) {
    return data?.element?.image
}

function getSubtitle(data: any) {
    return data?.achievement?.title
}

async function extractData(answer: string | string[] | Blob) {
    if (hasActiveReconciliations.value) {
        logger.warn('Reconciliation in progress, cannot send answer')
        return
    }

    const formData = new FormData()
    if (Array.isArray(answer)) {
        answer.forEach((option) => formData.append('options[]', option))
    } else {
        const key = typeof answer === 'string' ? 'message' : 'audio'
        formData.append(key, answer)
    }

    waitingForNewQuestion.value = true
    textInput.value = ''
    try {
        await conversationStore.submitAnswer(formData)
    } catch (error) {
        console.error('Failed to send answer:', error)
        waitingForNewQuestion.value = false
    } finally {
        /* empty */
    }
}

async function skipQuestion() {
    try {
        await conversationStore.skipQuestion()
    } catch (error) {
        logger.error('Failed to skip question:', error)
    }
}

async function finishConversation() {
    try {
        await conversationStore.finishConversation()
    } catch (error) {
        console.error('Failed to finish conversation:', error)
    } finally {
        emit('finish')
        router.push({ name: 'home' })
    }
}

function updateTextInput(value: string) {
    textInput.value = value
}

function toggleSpeakingMode() {
    isSpeakingMode.value = !isSpeakingMode.value
}

function handleVoiceButtonClick() {
    // Implement voice button click logic
}

function updateRecordingState(newState: boolean) {
    isRecording.value = newState
}

watch(error, (newError) => {
    if (newError) {
        console.error(newError)
        // You can also implement a toast or notification system here
    }
})
</script>
