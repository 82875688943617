<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M12 15.0001L20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16 5L19 8"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M9.0009 7.06982C7.24669 7.31977 5.65254 8.22567 4.54001 9.60481C3.42749 10.9839 2.87938 12.7337 3.00626 14.5011C3.13314 16.2684 3.92555 17.9219 5.22366 19.128C6.52177 20.3341 8.22898 21.003 10.0009 20.9998C11.6843 21 13.3114 20.3936 14.584 19.2916C15.8566 18.1896 16.6894 16.666 16.9299 14.9998"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
