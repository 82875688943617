<!-- components/DynamicInsightCard.vue -->
<template>
    <article
        :style="{
            backgroundColor: chroma(typeColor).alpha(0.05),
            borderColor: getBorderColor,
        }"
        class="flex w-full flex-col justify-center gap-2 rounded-lg border border-solid p-3"
    >
        <header>
            <div
                class="flex w-full items-center justify-between gap-10 text-center text-xs font-medium"
            >
                <div
                    :style="{
                        backgroundColor: chroma(typeColor).alpha(0.15),
                        color: typeColor,
                    }"
                    class="my-auto select-none gap-0.5 self-stretch rounded px-2 py-1 tracking-wide"
                >
                    {{ typeLabel }}
                </div>
                <save-icon
                    :style="{
                        color: chroma(typeColor).alpha(0.66),
                        fill: insight.saved_at ? typeColor : 'transparent',
                    }"
                    class="my-auto w-5 shrink-0 cursor-pointer"
                    @click="emit('update:save-insight')"
                />
            </div>
            <div
                v-if="
                    [
                        'market_comp_insight',
                        'brainstorming_insight',
                        'common_tropes',
                        'development_tip',
                        'common_cliches',
                    ].includes(insight.type)
                "
                class="flex w-full flex-col border-b border-solid py-2"
                :style="{
                    borderColor: getBorderColor,
                }"
            >
                <h3
                    :class="{
                        'font-fjalla font-normal': [
                            'market_comp_insight',
                        ].includes(insight.type),
                        'font-inter font-semibold': [
                            'brainstorming_insight',
                            'development_tip',
                            'common_tropes',
                            'common_cliches',
                        ].includes(insight.type),
                    }"
                    class="text-sm text-parchment-950 opacity-90"
                >
                    {{ insight.title }}
                </h3>
                <div
                    v-if="
                        ['market_comp_insight', 'common_tropes'].includes(
                            insight.type
                        )
                    "
                    :class="{
                        'text-2xs': insight.type == 'market_comp_insight',
                    }"
                    class="self-start text-start font-semibold text-parchment-900 opacity-50"
                >
                    {{ insight.subtitle_2 }}
                </div>
            </div>
        </header>

        <main
            class="flex flex-col gap-1 font-inter text-sm font-normal opacity-80 leading-relaxed"
        >
            <template
                v-for="(content, i) in insight.content"
                :key="i"
            >
                <template v-if="typeof content === 'string'">
                    <p
                        class="insight-string pb-2 last-of-type:pb-0"
                        :class="{
                            'font-decorative font-medium italic': [
                                'relevant_quote',
                                'audience_reaction_insight',
                            ].includes(insight.type),
                        }"
                    >
                        {{ content }}
                    </p>
                </template>
                <template v-else>
                    <ul
                        v-if="content.type === 'bullet_points'"
                        class="insight-bullet-points ml-4 list-outside list-disc"
                    >
                        <li
                            v-for="(bulletPoint, j) in Array.isArray(
                                content.content
                            )
                                ? content.content
                                : [content.content]"
                            :key="j"
                        >
                            {{ bulletPoint }}
                        </li>
                    </ul>
                    <blockquote v-else-if="content.type === 'quote'">
                        <p class="insight-quote font-decorative font-medium italic">
                            "{{ content.content }}"
                        </p>
                    </blockquote>
                    <h6 v-else-if="content.type === 'subtitle'">
                        {{ content.content }}
                    </h6>
                    <p v-else>
                        {{ content.content }}
                    </p>
                </template>
            </template>
        </main>

        <footer
            v-if="
                ['audience_reaction_insight', 'relevant_quote'].includes(
                    insight.type
                )
            "
            class="border-t pt-2 text-2xs font-medium"
            :style="{
                    borderColor: getBorderColor,
                }"
        >
            <p
                v-if="insight.subtitle_1 || insight.title"
                class="leading-snug text-parchment-900 opacity-50"
            >
                {{ insight.subtitle_1 ?? insight.title }}
            </p>
            <p
                v-if="insight.title"
                class="leading-snug text-parchment-900 opacity-50"
            >
                <template v-if="'relevant_quote' === insight.type">
                    from "{{ insight.title }}"
                </template>
            </p>
        </footer>
    </article>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import SaveIcon from '@/shared/components/icons/SaveIcon.vue'
import { DevelopmentInsight } from '@/shared/types/developmentInsight'
import chroma from 'chroma-js'

const emit = defineEmits(['update:save-insight'])

const props = defineProps({
    insight: {
        type: Object as PropType<DevelopmentInsight>,
        required: true,
    },
})

const typeLabel = computed(() => {
    const titles = {
        market_comp_insight: 'Similar Story Insight',
        brainstorming_insight: 'Brainstorming',
        relevant_quote: 'Insightful Quote',
        audience_reaction_insight: 'Audience Reaction',
        development_tip: 'Development Tip',
        common_tropes: 'Common Tropes',
        common_cliches: 'Common Cliches',
    }
    return titles[props.insight.type]
})

const typeColor = computed(() => {
    const colors = {
        brainstorming_insight: '#A855F7',
        market_comp_insight: '#4E58FF',
        audience_reaction_insight: '#FF5F02',
        relevant_quote: '#DD0085',
        common_tropes: '#059666',
        development_tip: '#E11D2A',
        common_cliches: '#E11D2A',
    }
    return colors[props.insight.type]
})

const getBorderColor = computed(() => {
    return chroma(typeColor.value).alpha(0.15)
})
</script>
