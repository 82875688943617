<!-- ImagePopup.vue -->
<template>
    <modal-backdrop>
        <modal-layout class="w-full max-w-3xl md:h-fit md:rounded-lg">
            <modal-header>
                <h2 class="text-2xl font-bold">Image Settings</h2>
                <Button
                    v-if="props.model_plural && user?.edit_enabled"
                    @click="
                        modal.show(NodeTree, {
                            nodeType: 'image-type',
                            nodeId: props.model_plural,
                        })
                    "
                >
                    Open node tree
                </Button>
                <button
                    class="text-2xl text-gray-500 hover:text-gray-700"
                    @click="$emit('close', changedDefaultImage)"
                >
                    &times;
                </button>
            </modal-header>

            <modal-content>
                <div
                    v-if="currentImage && images?.length"
                    :class="{
                        'rounded-lg bg-parchment-100 p-2': images?.length > 1,
                    }"
                >
                    <img
                        :src="
                            currentImage?.large ??
                            currentImage?.medium ??
                            currentImage?.thumbnail
                        "
                        alt="Current project image"
                        class="mx-auto h-[60vh] w-full object-contain"
                    />
                    <div
                        v-if="images.length > 1"
                        class="mt-3 flex w-full items-center justify-between"
                    >
                        <button
                            :class="
                                currentImage.id === tempDefaultImageId
                                    ? 'cursor-default'
                                    : 'cursor-pointer'
                            "
                            class="flex items-center gap-2"
                            @click="setTempDefaultImage(currentImage.id)"
                        >
                            <checkbox-item
                                :id="'default-' + currentImage.id"
                                :status="
                                    (currentImage.is_default &&
                                        !tempDefaultImageId) ||
                                    currentImage.id === tempDefaultImageId
                                "
                            />
                            <p class="text-sm text-parchment-600">
                                Set as default image
                            </p>
                        </button>
                        <div
                            v-if="isDefaultChanged"
                            class="flex items-center gap-2"
                        >
                            <button
                                class="rounded border border-slate-700 px-3 py-1 text-xs text-slate-700"
                                @click="cancelChanges"
                            >
                                Cancel
                            </button>
                            <button
                                class="rounded bg-slate-700 px-3 py-1 text-xs text-white"
                                @click="saveChanges"
                            >
                                Save
                            </button>
                        </div>
                        <div
                            v-if="showSavedMessage"
                            class="text-sm text-green-600"
                        >
                            Saved
                        </div>
                    </div>
                </div>

                <div
                    v-if="currentImage && images && images.length > 1"
                    class="flex flex-col gap-2"
                >
                    <h3 class="text-lg font-semibold">Image Gallery</h3>
                    <div
                        class="flex w-full max-w-full flex-nowrap items-center gap-2 overflow-x-scroll"
                    >
                        <div
                            v-for="image in images
                                .slice()
                                .sort((a, b) => b.id - a.id)"
                            :key="image.id"
                            class="shrink-0 cursor-pointer rounded-lg transition-opacity"
                            @click="setCurrentImage(image)"
                        >
                            <img
                                :alt="'Thumbnail ' + image.id"
                                :class="{
                                    'opacity-80': image.id === currentImage.id,
                                }"
                                :src="image.thumbnail"
                                class="h-20 w-20 rounded-lg object-cover"
                            />
                        </div>
                    </div>
                </div>

                <div
                    v-if="!images?.length"
                    class="mx-auto flex aspect-square h-full max-h-96 w-96 items-center justify-center rounded-xl bg-parchment-100"
                >
                    <no-image class="h-20 text-parchment-300" />
                </div>
            </modal-content>

            <modal-footer class="flex-col items-start">
                <div class="flex w-full flex-row justify-between">
                    <label
                        class="flex cursor-pointer flex-row items-center gap-0 rounded bg-slate-700 px-4 py-2 text-white hover:bg-slate-900"
                    >
                        <file-icon class="mr-2 h-4 w-4 shrink-0" />
                        Upload Image
                        <input
                            accept=".jpg,.jpeg,.png,.gif"
                            class="hidden"
                            type="file"
                            @change="handleFileUpload"
                        />
                    </label>
                    <button
                        class="rounded bg-slate-300 px-4 py-2 text-white"
                        @click="$emit('generate')"
                    >
                        Generate Image (coming soon)
                    </button>
                </div>
                <div
                    v-if="uploadStatus"
                    :class="statusClass"
                    class="mt-4 w-full rounded p-4 text-xs"
                >
                    {{ uploadStatus }}
                </div>
            </modal-footer>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref } from 'vue'
import FileIcon from '@/shared/components/icons/FileIcon.vue'
import CheckboxItem from '@/shared/components/CheckboxItem.vue'
import api from '@/shared/utils/api'
import NoImage from '@/shared/components/icons/NoImage.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import ModalFooter from '@/shared/components/modals/layouts/ModalFooter.vue'
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import { Image } from '@/shared/types/image'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { uploadElementImage } from '@/shared/utils/endpoints'
import NodeTree from '@/shared/components/modals/admin/NodeTree.vue'
import { Button } from '@/shared/components/ui/button'
import useModal from '@/shared/composables/useModal'
import { useUser } from '@/shared/composables/query/user'

const emit = defineEmits([
    'close',
    'upload',
    'generate',
    'setDefaultImage',
    'erase',
])

const props = defineProps({
    projectSlug: {
        type: String,
        required: true,
    },
    elementSlug: {
        type: String,
        required: true,
    },
    image: {
        type: Object as PropType<Image | null>,
        required: true,
    },
    model_plural: {
        type: String,
        required: false,
    },
})

const { data: user } = useUser()

const uploadStatus = ref('')
const isProcessing = ref(false)
const showSavedMessage = ref(false)
const changedDefaultImage = ref(false)

const currentImage = ref<Image | null>(props.image || null)
const tempDefaultImageId = ref<number | null>(null)

const modal = useModal()

const queryClient = useQueryClient()
const { data: images } = useQuery<Image[]>({
    queryKey: [
        'projects',
        props.projectSlug,
        'element',
        props.elementSlug,
        'images',
    ],
    queryFn: async () => {
        const { data } = await api.get(
            `/api/v1/projects/${props.projectSlug}/elements/${props.elementSlug}/images`
        )
        return data as Image[]
    },
})

const isDefaultChanged = computed(() => {
    return !!tempDefaultImageId.value
})

const statusClass = computed(() => {
    if (isProcessing.value) return 'bg-yellow-50 text-yellow-800'
    if (uploadStatus.value.includes('Error')) return 'bg-red-100 text-red-800'
    return 'bg-green-100 text-green-800'
})

const handleFileUpload = async (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0]
    if (!file) return

    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif']
    if (!allowedTypes.includes(file.type)) {
        uploadStatus.value = 'Error: Please upload a JPG, PNG, or GIF file.'
        return
    }

    try {
        isProcessing.value = true
        uploadStatus.value = 'Processing...'

        await uploadImage(file)

        // Wait for the upload to complete (you might want to implement a way to cancel this if needed)
        await new Promise((resolve) => {
            const checkStatus = setInterval(() => {
                if (!isProcessing.value) {
                    clearInterval(checkStatus)
                    resolve(null)
                }
            }, 100)
        })
    } catch (error) {
        console.error('Error uploading file:', error)
        uploadStatus.value = `Error: ${error.message || 'An error occurred during upload.'}`
    } finally {
        isProcessing.value = false
        event.target.value = ''
    }
}

async function uploadImage(file: File) {
    try {
        const formData = new FormData()
        formData.append('image', file)

        const response = await uploadElementImage(
            props.projectSlug,
            props.elementSlug,
            formData
        )

        emit('close')
    } catch (error) {
        console.error('Error uploading image:', error)
        throw error
    }
}

const setUploadStatus = (status: string, error: boolean = false) => {
    uploadStatus.value = status
    isProcessing.value = false
}

const handleBackgroundClick = () => {
    emit('close', changedDefaultImage.value)
}

const setCurrentImage = (image) => {
    currentImage.value = image
}

const setTempDefaultImage = (imageId) => {
    tempDefaultImageId.value = imageId
}

const cancelChanges = () => {
    tempDefaultImageId.value = null
}

const saveChanges = async () => {
    try {
        emit('setDefaultImage', tempDefaultImageId.value)
        showSavedMessage.value = true
        queryClient.setQueryData(
            [
                'projects',
                props.projectSlug,
                'element',
                props.elementSlug,
                'images',
            ],
            images.value?.slice().map((img) => {
                return JSON.parse(
                    JSON.stringify({
                        ...img,
                        is_default: img.id === tempDefaultImageId.value,
                    })
                )
            })
        )
        if (tempDefaultImageId.value) {
            changedDefaultImage.value = true
            void api.post(
                `/api/v1/images/${tempDefaultImageId.value}/set-default`
            )
        }
        tempDefaultImageId.value = null
        currentImage.value = images.value?.find((img) => img.is_default) ?? null

        setTimeout(() => {
            showSavedMessage.value = false
        }, 2000)
    } catch (error) {
        console.error('Error saving default image:', error)
    }
}

defineExpose({ setUploadStatus })

onMounted(() => {
    if (currentImage.value)
        currentImage.value = {
            ...currentImage.value,
            is_default: true,
        }
})
</script>
