<template>
    <modal-backdrop v-slot="{ close }">
        <modal-layout class="min-h-80 w-full max-w-prose">
            <modal-header>Library</modal-header>
            <modal-content class="grid grid-cols-4 gap-4">
                <div
                    v-for="image in images"
                    :key="image.id"
                    :class="{
                        'border-blue-400': selected?.id === image.id,
                    }"
                    :style="{
                        backgroundImage: `url(${image.large ?? image.medium ?? image.thumbnail})`,
                    }"
                    class="aspect-square w-full rounded-lg border bg-contain bg-center bg-no-repeat"
                    @click="selected = image"
                />
            </modal-content>
            <modal-footer class="flex justify-end gap-4">
                <Button
                    variant="outline"
                    @click="() => close()"
                >
                    Cancel
                </Button>
                <Button @click="emit('select', selected)">Select</Button>
            </modal-footer>
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import { useQuery } from '@tanstack/vue-query'
import api from '@/shared/utils/api'
import { Image } from '@/shared/types/image'
import ModalFooter from '@/shared/components/modals/layouts/ModalFooter.vue'
import { ref } from 'vue'
import { Button } from '@/shared/components/ui/button'

const emit = defineEmits(['select'])

const props = defineProps({
    filters: {
        type: Object,
        default: () => ({}),
    },
})

const selected = ref<Image | null>(null)

const { data: images } = useQuery({
    queryKey: ['images', props.filters],
    queryFn: async () => {
        const { data } = await api.get<Image[]>('/api/admin/v2/images', {
            params: props.filters,
        })
        return data
    },
})
</script>

<style scoped></style>
