<template>
    <template
        v-for="(parsedSummary, i) in parsedSummaryWithMentions"
        :key="i"
    >
        <template v-if="parsedSummary.type === 'text'">
            {{ parsedSummary.content }}
        </template>
        <HoverCard
            v-else-if="parsedSummary.type === 'mention'"
            v-model:open="parsedSummary.isOpen"
            :open-delay="0"
        >
            <HoverCardTrigger as-child>
                <span
                    :class="[
                        dark
                            ? {
                                  'text-blue-200 hover:bg-blue-400 hover:text-white': true,
                                  'bg-blue-400 text-white':
                                      parsedSummary.isOpen,
                              }
                            : {
                                  'text-blue-900 hover:bg-blue-400 hover:text-white': true,
                                  'bg-blue-400 text-white':
                                      parsedSummary.isOpen,
                              },
                    ]"
                    class="duration-75"
                >
                    {{ parsedSummary.content }}
                </span>
            </HoverCardTrigger>
            <HoverCardContent
                as-child
                class="w-auto max-w-prose"
            >
                <router-link
                    :to="parsedSummary.mention.path"
                    class="group flex flex-col"
                >
                    <span class="text-xs font-bold text-blue-500">
                        {{ parsedSummary.mention.tag }}
                    </span>
                    <p
                        class="text-sm font-semibold text-parchment-900 group-hover:underline"
                    >
                        {{ parsedSummary.mention.name }}
                    </p>
                </router-link>
            </HoverCardContent>
        </HoverCard>
    </template>
</template>

<script lang="ts" setup>
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from '@/shared/components/ui/hover-card'
import { computed } from 'vue'

const props = defineProps({
    dark: {
        type: Boolean,
        default: false,
    },
    userSummaryContent: {
        type: String,
        required: true,
    },
    mentions: {
        type: Array,
        default: () => [],
    },
    contentWithSlugs: {
        type: String,
        default: null,
    },
})

const parsedSummaryWithMentions = computed(() => {
    if (
        !props.mentions?.length ||
        !props.userSummaryContent ||
        !props.contentWithSlugs
    ) {
        return [
            {
                type: 'text',
                content: props.userSummaryContent,
            },
        ]
    }

    const result = []
    let currentText = ''

    props.contentWithSlugs.split(' ').forEach((word) => {
        const mention = props.mentions.find(
            (mention) =>
                word.includes(`@{${mention.slug}}`) ||
                word.includes(`{{${mention.slug}}}`) ||
                word.includes(mention.slug)
        )
        if (mention) {
            if (currentText) {
                result.push({
                    type: 'text',
                    content: currentText,
                })
                currentText = ''
            }
            result.push({
                type: 'mention',
                content:
                    word
                        .replace(`@{${mention.slug}}`, mention.display_name)
                        .replace(`{{${mention.slug}}}`, mention.display_name)
                        .replace(mention.slug, mention.display_name) + ' ',
                mention,
            })
        } else {
            currentText += word + ' '
        }
    })

    if (currentText) {
        result.push({
            type: 'text',
            content: currentText,
        })
    }

    return result
})
</script>

<style scoped></style>
