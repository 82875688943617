<!-- /resources/app/components/ConversationContent.vue -->
<template>
    <section
        class="conversationEngineContent flex flex-col self-center justify-center w-full pt-2 md:pt-6 lg:pt-12 xl:pt-20 max-w-prose gap-4 px-2 md:px-4"
    >
        <div class="relative flex grow flex-col gap-2 text-left">
            <staggered-text-animation
                v-if="
                    freeTrialEnded ||
                    (chatMessage && (chatMessage.title || chatMessage.question))
                "
                v-memo="[
                    freeTrialEnded,
                    chatMessage?.title,
                    chatMessage?.question,
                ]"
                :texts="questionDisplays"
            />
        </div>

        <!-- New flex row for tip and skip buttons -->
        <div class="flex flex-row gap-4 items-center md:mt-2">
            <div class="inline-block" v-if="chatMessage?.tooltip && chatMessage.tooltip !== ''">
                <Popover>
                    <PopoverTrigger as-child>
                        <button
                            class="flex items-center gap-1 rounded-lg bg-parchment-100 px-2 py-2 text-sm md:text-base text-parchment-600 duration-75 hover:bg-parchment-200 hover:text-parchment-700"
                        >
                            <tip-icon class="chat-tip-button h-4 w-4" />
                            Tip
                        </button>
                    </PopoverTrigger>
                    <PopoverContent align="start">
                        <p class="max-w-72">
                            {{ chatMessage?.tooltip }}
                        </p>
                    </PopoverContent>
                </Popover>
            </div>

            <button
                v-if="!isProcessing && actions.skip"
                class="flex items-center gap-1 rounded-lg bg-parchment-100 px-2 py-2 text-sm md:text-base text-parchment-600 duration-75 hover:bg-parchment-200 hover:text-parchment-700"
                @click="emit('skip')"
            >
                <skip-icon class="chat-skip-button h-3 w-3" />
                Skip
            </button>
        </div>
        <main class="items-left flex h-full w-full flex-col gap-4">
            <div
                v-if="chatMessage?.answer"
                class="flex w-full flex-col gap-2 rounded-md bg-parchment-100 p-3 text-left"
            >
                <div
                    class="select-none text-2xs font-semibold uppercase text-parchment-500"
                >
                    Your answer
                </div>
                <div class="w-full">
                    <div class="whitespace-pre-wrap text-sm text-parchment-600">
                        {{ chatMessage.answer }}
                    </div>
                </div>
            </div>
            <div
                v-if="transcription"
                class="w-full"
            >
                <p class="mb-2 text-base text-parchment-500">Transcription:</p>
                <div class="w-full rounded-md bg-parchment-100 p-3">
                    <p class="whitespace-pre-wrap text-base text-parchment-500">
                        {{ transcription }}
                    </p>
                </div>
            </div>
            <template v-if="shouldShowTextInput && !isSpeakingMode">
                <div
                    class="my-6 flex w-full grow flex-col items-start gap-4 !border-0"
                >
                    <dynamic-textarea
                        :max-length="6000"
                        :model-value="textInput"
                        enter-key-hint="done"
                        input-mode="text"
                        placeholder="Enter your answer"
                        @update:model-value="updateTextInput"
                        @extract-data="extractData"
                    />
                </div>
            </template>
        </main>
    </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import DynamicTextarea from '@/shared/components/forms/DynamicTextarea.vue'
import StaggeredTextAnimation from '@/shared/components/StaggeredTextAnimation.vue'
import { ChatMessage } from '@/shared/types/chatMessage'
import TipIcon from '@/shared/components/icons/TipIcon.vue'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/shared/components/ui/popover'
import SkipIcon from "@/shared/components/icons/SkipIcon.vue";

const props = defineProps({
    chatMessage: {
        type: Object as () => ChatMessage | null | undefined,
        default: null,
    },
    waitingForNewQuestion: {
        type: Boolean,
        required: true,
    },
    shouldShowTextInput: {
        type: Boolean,
        required: true,
    },
    textInput: {
        type: String,
        required: true,
    },
    transcription: {
        type: String,
        default: '',
    },
    isProcessing: {
        type: Boolean,
        required: true,
    },
    isSpeakingMode: {
        type: Boolean,
        required: true,
    },
    freeTrialEnded: {
        type: Boolean,
        required: true,
    },
    actions: {
        type: Object,
        default: () => ({}),
    },
})

const emit = defineEmits(['update:textInput', 'extract-data', 'skip'])

const questionDisplays = computed(() => {
    const titleSpeed = (props.isProcessing || props.chatMessage?.answer) ? 0 : 5;
    const questionSpeed = (props.isProcessing || props.chatMessage?.answer) ? 0 : 9;

    return [
        {
            modelValue: props.freeTrialEnded
                ? 'Free trial ended'
                : props.chatMessage?.title,
            class: `chatQuestionPreamble text-parchment-950 opacity-60 text-xs md:text-sm font-normal
                    ${props.freeTrialEnded ? 'block' : 'hidden md:block'}`,
            is: 'h3',
            speed: titleSpeed,
        },
        {
            modelValue: props.freeTrialEnded
                ? 'Your free trial has ended. Please upgrade to continue.'
                : props.chatMessage?.question,
            class: `chatQuestion text-parchment-900 text-lg sm:text-xl font-medium`,
            is: 'h2',
            speed: questionSpeed,
        },
        {
            template: 'tip',
            modelValue: props.chatMessage?.tooltip,
        },
    ].filter((item) => item.modelValue);
});

function updateTextInput(value: string) {
    emit('update:textInput', value)
}

function extractData(input: string) {
    emit('extract-data', input)
}
</script>
