<template>
    <modal-backdrop v-slot="{ close }">
        <modal-layout class="w-full max-w-prose">
            <modal-header>Summary</modal-header>
            <modal-content>
                <summary-form
                    :summary-id="summaryId"
                    :prefill="prefill"
                    @close="close"
                    @create="emit('create', $event)"
                    @saved="emit('saved', $event)"
                />
            </modal-content>
            <!--            <modal-footer class="justify-end gap-2">-->
            <!--                <v-button @click="close">Cancel</v-button>-->
            <!--                <v-button-->
            <!--                    variant="light"-->
            <!--                    @click="-->
            <!--                        () => handleSubmit().then((valid) => valid && close())-->
            <!--                    "-->
            <!--                >-->
            <!--                    Save-->
            <!--                </v-button>-->
            <!--            </modal-footer>-->
        </modal-layout>
    </modal-backdrop>
</template>

<script lang="ts" setup>
import ModalBackdrop from '@/shared/components/modals/layouts/ModalBackdrop.vue'
import ModalLayout from '@/shared/components/modals/layouts/ModalLayout.vue'
import ModalHeader from '@/shared/components/modals/layouts/ModalHeader.vue'
import ModalContent from '@/shared/components/modals/layouts/ModalContent.vue'
import SummaryForm from '@/shared/components/forms/SummaryForm.vue'

const emit = defineEmits(['create', 'saved'])

const props = defineProps({
    summaryId: {
        type: Number,
        default: null,
    },
    prefill: {
        type: Object,
        default: () => undefined,
    },
})
</script>

<style scoped></style>
